import './Login.css';
import { IonContent, IonHeader, IonButton, IonIcon, IonItem, IonLabel, IonInput, IonPage, IonTitle, IonToolbar, IonLoading, useIonViewWillEnter, useIonViewDidEnter, useIonViewWillLeave, useIonAlert } from '@ionic/react';
import { lockClosed, person } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Config from '../../config/settings';
import UserService from '../../services/user';
import { ItemResult } from '../../models/itemResult';
import { AppVersion } from '@awesome-cordova-plugins/app-version';
import { Subscription } from 'rxjs';

const Login: React.FC<{ userService?: UserService, setUserLoggedIn?: any }> = ({ userService, setUserLoggedIn }) => {
  
  const history = useHistory();

  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [isLoadingLogin = false, setIsLoading] = useState<boolean>();
  const [appVersion, setAppVersion] = useState<string>();
  const [presentAlert] = useIonAlert();
  // const [isAndroidDevice, setIsAndroidDevice] = useState<boolean>(true);

  let userServiceSubscribe: Subscription;

  useState(async () => { });

  useEffect(() => {
    return () => { setIsLoading(false) };
  }, []);   

  useIonViewWillEnter(async () => {
  });

  useIonViewDidEnter(async () => {
    
    setAppVersion(await AppVersion?.getVersionNumber().catch(() => {
      // setIsAndroidDevice(false);
      return Config.version;
    }));

    // Attente service dispo
    userServiceSubscribe = userService.storageInitSubject.subscribe((result) => {
      if (result === true) {
        login(true);
      }
    });
  });

  useIonViewWillLeave(() => {
    if (userServiceSubscribe) {
      userServiceSubscribe.unsubscribe();
    }
  });

  /**
   * Login de l'utilisateur
   */
  const loginUser = async () => {

    let ready = false;

    if (!email || email.length === 0 || email.indexOf('@') === -1) {
      presentAlert({
        header: 'Message',
        subHeader: '',
        message: 'Please enter an email.',
        buttons: ['OK'],
      });
      let ready = false;

      if (!email || email.length === 0 || email.indexOf('@') === -1) {
        presentAlert({
          header: 'Message',
          subHeader: '',
          message: 'Please enter an email.',
          buttons: ['OK'],
        });
        ready = false;
      } else {
        ready = true;
      }
  
      if (!password || password.length === 0) {
        presentAlert({
          header: 'Message',
          subHeader: '',
          message: 'Please enter a password.',
          buttons: ['OK'],
        });
        ready = false;
      } else {
        ready = true;
      }
  
      // Données compte utilisateur
      if (ready && userService) {
        login();
      }
      ready = true;
    }

    if (!password || password.length === 0) {
      presentAlert({
        header: 'Message',
        subHeader: '',
        message: 'Please enter a password.',
        buttons: ['OK'],
      });
      ready = false;
    } else {
      ready = true;
    }

    // Données compte utilisateur
    if (ready && userService) {
      login();
    }
  };

  function setLoggedUser() {
    setUserLoggedIn();

    // Aller à la HomePage suite au login
    gotoHomePage();
  }
  
  function gotoHomePage() {
    const pagePlatform = 'platform';
    const platformTab: HTMLElement | null = document.querySelector('#tab-button-' + pagePlatform);
    platformTab?.click();
  }

  async function login(autologin: boolean = false) {
    
    const baseUrl = (Config.debugMode === false) ? Config.url : Config.urlDebug;
    const url = baseUrl + 'users?email=' + email + '&pass=' + password + '&v=' + Config.version;

    // Est déjà loggué
    if (autologin) {
      if (userService.security) {
        setLoggedUser();
      }
    // Test de login
  } else {
    fetch(url).then(
      async (res) => {
          setIsLoading(true);

          const result: ItemResult = await res.json();

          if (result && (result.msg.indexOf('Invalid') > -1)) {
            presentAlert({
              header: 'Message',
              subHeader: '',
              message: 'Account or password incorrect. Please reset your password or contact support on Telegram.',
              buttons: ['OK'],
            });
          } else if (result.msg.indexOf('Nothing') > -1) {
            presentAlert({
              header: 'Message',
              subHeader: '',
              message: 'Server under maintenance, please retry later.',
              buttons: ['OK'],
            });
          } else if (result && result.msg.indexOf('account') > -1) {

            userService?.setEmail(email);
            userService?.setUsername((email && email.indexOf('@') > -1) ? email.split('@')[0] : 'unknown');
            userService?.setSecurity((result && result.security) ? result.security : null);
            userService?.setPubkey((result && result.pubkey) ? result.pubkey : null);
            userService?.setSeedkey((result && result.seedkey) ? result.seedkey : null);
            userService?.setDonator((result && result.donator) ? result.donator : 0);
            userService?.setTester((result && result.access) ? result.access : 0);

            setLoggedUser();
          } else if (result && (result.msg.indexOf('deprecated') > -1)) {
            presentAlert({
              header: 'Message',
              subHeader: '',
              message: 'Your client application is deprecated. Please upgrade your client application from PlayStore.',
              buttons: ['OK'],
            });
          } else {
            presentAlert({
              header: 'Message',
              subHeader: '',
              message: 'An error occured, please contact support on Telegram.',
              buttons: ['OK'],
            });
          }
          setIsLoading(false);
        }
        ).catch((_e) => {
          presentAlert({
                header: 'Message',
                subHeader: '',
                message: 'Please connect your device to Internet. If you are already connected, servers may be temporarily down or application need to be updated.' + _e,
                buttons: ['OK'],
              });
  
        });
    }
  }

  function showPrivacyPolicy() {
    window.open(`https://nebula.spl-token.com/privacy_policy.html`);
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle color="primary">Login</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>

        <div className="header-login">
          <h1>NEBULA</h1>
          <h2>Blockchained Discussions in total Freedom</h2>
          <h3>version: {appVersion}</h3>
        </div>

        <div className="container-login">
          <h1>Login</h1>

          <IonButton color="secondary" className="official-news" onClick={() => { window.open('https://t.me/nebula_android_application') }}>Goto Official news</IonButton>

          {
            isLoadingLogin &&
            <IonLoading
              cssClass='my-custom-class'
              isOpen={isLoadingLogin}
              message={'Loading'}
              duration={0}
              spinner={'dots'}
            />
          }

          <IonItem>
            <IonLabel color="primary">Email</IonLabel>
            <IonInput placeholder="Enter email here" type="email" value={email} maxlength={50} onIonChange={e => setEmail(e.detail.value!)}></IonInput>
            <IonIcon color="primary" icon={person} />
          </IonItem>

          <IonItem>
            <IonLabel color="primary">Password</IonLabel>
            <IonInput placeholder="Enter password here" type="password" value={password} maxlength={20} onIonChange={e => setPassword(e.detail.value!)}></IonInput>
            <IonIcon color="primary" icon={lockClosed} />
          </IonItem>

          <p className="forgot-password">I have <a target="_self" rel="noopener noreferrer" href="/forgot">Lost my password ?</a> or <a target="_self" rel="noopener noreferrer" className="help-link" href="/login" onClick={() => { window.open('https://t.me/nsa_project_support_channel') }}> Help ?</a></p>

          <IonButton color="secondary" className="login-button" onClick={() => { loginUser() }}>Login</IonButton>

          <p className="register-login">I don't have an account ? <a target="_self" rel="noopener noreferrer" onClick={() => { history.push("/register") }} href="/register">Register</a></p>

          <div className="privacy-bloc">
            <a color="secondary" onClick={() => { showPrivacyPolicy() }}>Privacy Policy</a>
          </div>
        </div>

      </IonContent>
    </IonPage>
  );
};

export default Login;

