/**
 * Enum pour les modales du Market
 */
export enum MarketEnum {
    // Modale achat
    BUY = 'buy',
    // Modale achaten cours
    BUYING = 'buying',
    // Modale vente
    SELL = 'sell',
}