
const Config = {
    debugMode: false,
    url: 'https://nebula.spl-token.com:3011/',
    urlDebug: 'https://127.0.0.1:3011/',
    version: '2.467',
    tokenAddress: '4CuBdvKbwy1wo8o9sDs7mUQZTL1weKitpBA6X2D5a1nX',
    tokenNSANAddress: '6J8zQqphXpyM19vKKnAdWn9VWgyEHwuW9q1xzm3wY91W',
    accountPubkey: '5RtCVaju5kRB9UghFPLPCRuMGpnEG2fvY74s6YxNmdmi',
    mainnetSolana: 'https://api.mainnet-beta.solana.com',
    mainnetQuicknode: 'https://hidden-fluent-sound.solana-mainnet.discover.quiknode.pro/62912303377f5c34ef1af716f0b36eaefaf0855f',
    marketIdMsolUsdt: '9Lyhks5bQQxb9EyyX55NtgKQzpM4WK7JCmeaWuQ5MoXD',
    marketIdSolUsdt: '8BnEgHoWFysVcuFFX7QztDmzuH8r5ZFvyP3sYwn1XTh6',
    marketIdNsaUsdt: '9Hfa9aARXtSYKqVxdpvVWZB3gGW4sHffasm8QTptSoZn',
};

export default Config;