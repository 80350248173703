import { useCallback, useEffect, useRef, useState } from 'react';
import { IonContent, IonSpinner, IonPage, IonTitle, IonToolbar, IonText, IonItem, IonButton, IonImg, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonLoading, IonAlert, IonGrid, IonCol, IonRow, IonIcon, useIonViewWillEnter, useIonViewWillLeave, IonRefresher, IonRefresherContent, IonButtons, IonLabel, IonList, IonModal, useIonAlert, IonInput } from '@ionic/react';
import './Market.css';
import UserService from '../../services/user';
import { bagAddOutline, bagOutline, bagRemoveOutline, copy, desktopOutline, duplicateOutline, filter, walletOutline, warning } from 'ionicons/icons';
import { ItemNft } from '../../models/itemNft';
import { Subscription } from 'rxjs';
import Config from '../../config/settings';
import { VirtuosoGrid } from 'react-virtuoso';
import styled from '@emotion/styled';
import { WalletService } from '../Wallet/Wallet.svc';
import { Collections } from './Collections.enum';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Shared } from '../../shared/shared';
import { TOKEN_PROGRAM_ID, Token } from '@solana/spl-token';
import { 
  ComputeBudgetProgram, 
  Connection, 
  Keypair, 
  PublicKey, 
  Transaction, 
  sendAndConfirmTransaction
} from '@solana/web3.js';
import * as bip39 from "bip39";
import Accounts from '../../config/wallets';
import { MarketEnum } from './Market.enum';
import { sign } from 'crypto';

const Market: React.FC<{ shared?: Shared, userService?: UserService, isAndroidDevice: boolean }> = ({ shared, userService, isAndroidDevice }) => {
  const [searchText, setSearchText] = useState('');
  const [marketData, setMarketData] = useState<ItemNft[]>([]);
  const [itemToBuy, setItemToBuy] = useState<ItemNft>();
  const [itemToSell, setItemToSell] = useState<ItemNft>();
  const [isTester = false, setIsTester] = useState<boolean>();
  const [filterOpened = false, setFilterOpened] = useState<boolean>();
  const [buyModaleOpened = false, setBuyModaleOpened] = useState<boolean>();
  const [isBuyingNFT, setIsBuyingNFT] = useState<boolean>(false);
  const [sellModaleOpened = false, setSellModaleOpened] = useState<boolean>();
  const [isLoadingNftData = false, setIsLoadingNftdata] = useState<boolean>();
  const [showAlertConnection, setShowAlertConnexion] = useState(false);
  const [showOutdatedVersion, setShowOutdatedVersion] = useState(false);
  const [versionTested = false, setVersionTested] = useState<boolean>();
  const [endOfItems = false, setEndOfItems] = useState<boolean>();
  const [showOwnedNFT = false, setShowNFT] = useState<boolean>();
  const [walletBalanceSOL, setWalletBalanceSOL] = useState<number>(0.00);
  const [walletBalanceNSA, setWalletBalanceNSA] = useState<number>(0.00);
  const [logBuying, setLogBuying] = useState<string>();
  const [transactionUrl, setTransactionUrl] = useState<string>('');
  const [salePrice, setSalePrice] = useState<number>(0);
  const [presentAlert] = useIonAlert();

  let userServiceSubscribe: Subscription;

  const walletService: WalletService = new WalletService();
  const connectionMainnet = new Connection(
    Config.mainnetQuicknode,
    'confirmed',
  );
  const decimals = 1000000000;

  let hasEnoughNSA: {value: boolean, total: number} = null;
  let hasEnoughSOL: {value: boolean, total: number} = null;

  useEffect(() => {
    
    const timeout = loadMore();
    
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line
  }, []);

  // Action lors du bouton BACK
  var backButton = function() {
    var handler = function(ev: any) {
      ev.preventDefault();
      ev.stopPropagation();
      ev.stopImmediatePropagation();
    };
    return handler;
  };
  
  // Dans le useState pour qu'ils soient appellés une seule fois
  useIonViewWillEnter(async () => {

    document.addEventListener('ionBackButton', backButton, false);

    // Attente du chargement du user service
    userServiceSubscribe = userService.storageInitSubject.subscribe((result) => {
      if (result === true) {

        // Si les données sont déjà présentes on les sauvegardes
        if (userService.marketCalled === true) {
          // Récupération des données sauvegardées
          setMarketData(userService.getMarketData());
        } else {
          // Si premier affichage, on charge les données
          if (userService.email && userService.email.length > 0 && userService.security && userService.security.length > 0) {
            // Vérification de la version
            checkVersion(userService.email).then(async (isOutDated) => {

              if (!isOutDated && isOutDated === false) {
                // Récupération des données pour le premier affichage
                const collection = await userService.getCollection();
                getNftData(false, collection);
              } else if (isOutDated === null) {
                // Si internet ne fonctionne pas
                setShowAlertConnexion(true);
              } else if (isOutDated === true) {
                // Si la version est trop vieille
                setShowOutdatedVersion(true);
              }
            });
          }
        }
      }
    });
  });

  useIonViewWillLeave(() => {
    // Vidage des nébuleuses
    setMarketData([]);
    // Suppression de la souscription
    if (userServiceSubscribe) {
      userServiceSubscribe.unsubscribe();
    }
  });

  const loadNextPage = (ev: any) => {

    if (!endOfItems) {
      setTimeout(async () => {

        userService.resetMarketCalled();

        const collection = await userService.getCollection();

        getNftData(false, collection);
        
        if (ev) ev.target.complete();

        // Si 1000 contenus chargés on bloque l'infinite scroll
        // if (marketData.length === 1000) {
        //   setInfiniteNftDisabled(true);
        // }
      }, 500);
    }
  }

  function handleRefresh(event?: CustomEvent<any>) {

    setEndOfItems(false);
    
    setTimeout(async () => {
      
      userService.resetIsMarketPage();
      
      // Reset des données chargées
      userService.clearMarketData();
      userService.resetMarketCalled();

      // Rechargement des données
      const collection = await userService.getCollection();
      getNftData(true, collection);

      if (event) {
        event.detail.complete();
      }
    }, 2000);
  }

  const loadMore = useCallback(() => {
    return setTimeout(() => {
      loadNextPage(null);
    }, 200)
    // eslint-disable-next-line
  }, [setMarketData]);

  /**
   * Vérification de la version de l'application
   * @param email 
   */
  async function checkVersion(email: any) {

    if (email && !versionTested) {
      setVersionTested(true);

      return userService.checkVersion(email)
        .then((result: boolean) => {
          setShowOutdatedVersion(result);
          return result;
        }
      );
    }
  }

  /**
   * Sauvegarde état walllet ayant assez de NSA pour conclure une transaction
   * @param value boolean du resultat si le wallet contient assez de NSA 
   */
  function setHasEnoughNSA(value: boolean, total: number) {
    hasEnoughNSA = {value: value, total: total};
  }

  /**
   * Sauvegarde état walllet ayant assez de SOL pour conclure une transaction
   * @param value boolean du resultat si le wallet contient assez de SOL 
   */
  function setHasEnoughSOL(value: boolean, total: number) {
    hasEnoughSOL = {value: value, total: total};
  }
  
  /**
   * Récupérer les données des NFT
   */
  async function getNftData(refresh: boolean = false, collection: number, reload: boolean = false) {

    if (refresh) {
      // On vide la liste des NFT
      userService.clearMarketData();
      userService.resetIsMarketPage();
      setEndOfItems(false);
      // On cache la page mes NFT
      setShowNFT(false);
    }

    if (userService && userService.email && userService.security && (refresh) ? refresh : !userService.marketCalled && !endOfItems) {
      
      setIsLoadingNftdata(true);

      userService.setMarketCalled();
      
      const baseUrl = (Config.debugMode === false) ? Config.url : Config.urlDebug;
      const url = baseUrl + 'market?email=' + userService.email + '&security=' + userService.security + '&page=' + userService.isMarketPage + '&collection=' + collection + '&v=' + Config.version;

      await fetch(url).then(
        async (res) => {
          
          const result = await res.json();
          
          const newData: ItemNft[] = [];
          const max = marketData.length + 50;
          const min = max - 10;

          console.log('market:', result);
          
          if (result && result.msg && result.msg.indexOf('email') > 0) {
            // Blocage need to be tester
            
            userService.setTester(false);
            setIsTester(false);
              
            for (let i = min; i < max; i++) {
              newData.push({
                id: i,
                img: '',
                title: '',
                collection: null,
                description: '',
                date: new Date(),
                contract: '',
                price: ''
              });
            }

            // Ajout des données à l'infinite scroll
            userService.setMarketData(
              newData
            );
            
            setMarketData(userService.getMarketData());

          } else if (result && result.msg && result.msg.indexOf('Tester') > 0) {
            // Blocage need to be tester
            
            userService.setTester(false);
            setIsTester(false);
              
            for (let i = min; i < max; i++) {
              newData.push({
                id: i,
                img: '',
                title: '',
                collection: null,
                description: '',
                date: new Date(),
                contract: '',
                price: ''
              });
            }

            // Ajout des données à l'infinite scroll
            userService.setMarketData(
              newData
            );
            
            setMarketData(userService.getMarketData());

          } else {
            // Actif + de 50 NSA et Tester
            
            userService.setTester(true);
            setIsTester(true);
            
            // On vide la liste si c'est le 1er chargement
            if (userService.isMarketPage === 0) {
              userService.clearMarketData();
              setMarketData(userService.marketData);
            }
            
            // On incrémente la page
            userService.incrementMarketPage();
            
            if (result.length === 0 && !reload) {
              setEndOfItems(true);
            } else {
              // const img_path_prefix = 'https://nebula-webapp.spl-token.com/assets/nft/';

              for (const element of result) {

                const collectionName: Collections = shared.getCollectionName(element.collection);

                newData.push({
                  id: (element.id) ? element.id : null,
                  img: (element.img_url) ? element.img_url : null,
                  title: (element.title) ? element.title : null,
                  collection: collectionName,
                  description: (element.description) ? element.description : null,
                  date: (element.date) ? element.date : null,
                  contract: (element.contract) ? element.contract : null,
                  price: (element.price) ? element.price : null,
                });
              }

              // Ajout des données à l'infinite scroll
              userService.setMarketData([
                ...userService.getMarketData(),
                ...newData
              ]);
              
              setMarketData(userService.getMarketData());
            }
          }
        }
      ).catch((_e) => {
        console.log('error getNftData', _e);
      }).finally(() => {
        setIsLoadingNftdata(false);
      });
    }
  }

  /**
   * Ouvre la page de mining
   */
  function gotoMining() {
    const page = 'mining';
    const miningTab: HTMLElement | null = document.querySelector('#tab-button-' + page);
    miningTab?.click();
  }

  /**
   * Ouvre la page de presale
   */
  function gotoWallet() {
    const page = 'wallet';
    const presaleTab: HTMLElement | null = document.querySelector('#tab-button-' + page);
    presaleTab?.click();
  }

  /**
   * Fermeture modale connexion introuvable
   */
  function closeConnexionAlert() {
    setShowAlertConnexion(false);
    userService.forceCloseApp();
  }

  /**
   * Ferme l'application dépréciée
   */
  function closeOutdatedVersion() {
    setShowOutdatedVersion(false);
    userService.forceCloseApp();
  }

  /**
   * Ouverture de la fenêtre de filtres
   * @param searchedText Texte recherché
   */
  function openFilters(openValue: boolean) {
    if (!isTester) {
      presentAlert({
        header: 'Message',
        subHeader: '',
        message: 'You need to be a Tester to interact with the Nft market.',
        buttons: ['OK'],
      });
    } else {
      console.log('Open filter box', openValue);
      setFilterOpened(openValue);
    }
  }

  /**
   * Explorer le contrat
   * @param contract le numéro de contrat
   */
  function exploreContract(contract: string) {
    const contract_prefix = 'https://solscan.io/token/';
    window.open(contract_prefix + contract, '_blank');
  }

  /**
   * Ouvrir la page d'achat du contrat
   * @param contract numéro de contrat
   */
  function openBuyContract(item: ItemNft) {
    setItemToBuy(item);
    setBuyModaleOpened(true);
  }

  /**
   * Ouvrir la page d'achat du contrat
   * @param contract numéro de contrat
   */
  function openSellContract(item: ItemNft) {
    setItemToSell(item);
    setSellModaleOpened(true);
  }

  /**
   * Acheter le NFT
   * @param item données du NFT
   */
  async function buyNFT(item: ItemNft) {

    setIsBuyingNFT(true);
    setLogBuying('Buying NFT ' + item.title + ', please be patient it can take some time.');

    const baseUrl = (Config.debugMode === false) ? Config.url : Config.urlDebug;
    const url = baseUrl + 'market_open?email=' + userService.email + '&security=' + userService.security + '&v=' + Config.version;

    fetch(url).then(
      async (res) => {
        const result = await res.json();

        console.log('res', result);

        if (result?.msg !== null) {

          setLogBuying('Checking if the market place is open');

          if (result?.msg === true) {
            // Montant du NFT
            const amountRequiredToByNFT = Number(item.price);
            
            // Vérifier si le wallet contient assez de NSA
            await getNsaTokenAccountsByOwner(amountRequiredToByNFT).then(() => {

              setLogBuying('Check if your account has enough NSA for this item');

              if (!hasEnoughNSA.value) {
                setIsBuyingNFT(false);
                presentAlert({
                  header: 'Message',
                  subHeader: '',
                  message: `You have not enough NSA in your wallet (${hasEnoughNSA.total}), you need a minimum of ${item.price} to buy the NFT #${item.title} of ${item.collection} collection.`,
                  buttons: ['OK'],
                });
              }
            });
            
            // Vérifier si le wallet contient assez de SOL
            if (hasEnoughNSA.value) {
              const seedkey = await userService.getSeedkey();
              const seedBip39 = bip39.mnemonicToSeedSync(seedkey, ""); // (mnemonic, password)
              const keypair = Keypair.fromSeed(seedBip39.slice(0, 32));

              const blockchainFees = await shared.calculateFeeTransfer(keypair);

              console.log('blockchainFees', blockchainFees);

              // TODO: calculer le prix en SOL du NFT et calculer le fees du service
              const serviceFees = 0.002;
              const priorityFees = 0.00005;
              const totalFees = blockchainFees + serviceFees + priorityFees;

              console.log('totalFees', totalFees);

              setLogBuying('Checking if your account has enough SOL for fees');

              // vérifie si assez de sol pour payer les fee
              await getSolanaBalance(totalFees);

              console.log('next sol balance', hasEnoughSOL);

              if (!hasEnoughSOL.value) {
                setIsBuyingNFT(false);
                presentAlert({
                  header: 'Message',
                  subHeader: '',
                  message: `You have not enough SOL in your wallet SOL ${hasEnoughSOL.total}, to pay transaction and service fees of SOL ${totalFees}.`,
                  buttons: ['OK'],
                });
              } else {

                setLogBuying('Checking if item is not locked by another user');

                const baseUrl = (Config.debugMode === false) ? Config.url : Config.urlDebug;
                const url = baseUrl + 'market_check_item?email=' + userService.email + '&security=' + userService.security + '&contract=' + item.contract + '&v=' + Config.version;
                
                fetch(url).then(
                  async (itemIsLock) => {
                    const itemIsLockResult = await itemIsLock.json();
                    if (itemIsLockResult?.msg?.locked === false) {

                      console.log('locked', itemIsLockResult);

                      const baseUrl = (Config.debugMode === false) ? Config.url : Config.urlDebug;
                      const url = baseUrl + 'market_lock_item?email=' + userService.email + '&security=' + userService.security + '&contract=' + item.contract + '&lock=1&v=' + Config.version;
                      
                      fetch(url).then(
                        async (itemLocking) => {
                          const itemLockingResult = await itemLocking.json();
                          
                          if (itemLockingResult?.msg?.locked === true) {
                      
                            const seedkey = await userService.getSeedkey();
                            const pubkey = await userService.getPubkey();
                            const seedBip39 = bip39.mnemonicToSeedSync(seedkey, ""); // (mnemonic, password)
                            const keypair = Keypair.fromSeed(seedBip39.slice(0, 32));
                            
                            // // receiver est le wallet du projet qui a tous les NFT en vente
                            const receiver = new PublicKey(Accounts.nsa);
            
                            // Transférer les nsa tokens au wallet du projet
                            // https://stackoverflow.com/questions/68236211/how-to-transfer-custom-token-by-solana-web3-js
                            
                            setLogBuying('Sending your NSA transaction');

                            (async () => {
            
                              const totalPrice = item.price;
            
                              const nsaToken = new PublicKey(Config.tokenAddress);
                              console.log('nsaToken', nsaToken);
            
                              const tokenToTransfer = new Token(
                                connectionMainnet,
                                nsaToken,
                                TOKEN_PROGRAM_ID,
                                keypair
                              );
                              console.log('myToken', tokenToTransfer);
            
                              // Create associated token accounts for my token if they don't exist yet
                              const fromTokenAccount = await tokenToTransfer.getOrCreateAssociatedAccountInfo(
                                keypair.publicKey
                              );
                              
                              const toTokenAccount = await tokenToTransfer.getOrCreateAssociatedAccountInfo(
                                receiver
                              );
            
                              const modifyComputeUnits = ComputeBudgetProgram.setComputeUnitLimit({
                                units: 2200000
                              });
                
                              const addPriorityFee = ComputeBudgetProgram.setComputeUnitPrice({
                                microLamports: 130000
                              });

                              const recentBlockhash = await connectionMainnet.getLatestBlockhash();

                              // Add token transfer instructions to transaction
                              const transaction = new Transaction({
                                recentBlockhash: recentBlockhash.blockhash,
                              })
                              .add(modifyComputeUnits)
                              .add(addPriorityFee)
                              .add(
                                Token.createTransferInstruction(
                                  TOKEN_PROGRAM_ID,
                                  fromTokenAccount.address,
                                  toTokenAccount.address,
                                  keypair.publicKey,
                                  [],
                                  Number(totalPrice) * decimals
                                )
                              );
                              transaction.feePayer = keypair.publicKey;

                              console.log('transaction', transaction);

                              setLogBuying('Waiting for confirmations, it can take some time...');
            
                              // https://www.quicknode.com/guides/solana-development/spl-tokens/how-to-transfer-spl-tokens-on-solana
                              const latestBlockHash = await connectionMainnet.getLatestBlockhash('confirmed');
                              transaction.recentBlockhash = await latestBlockHash.blockhash; 
                              
                              try {
                                const signature = await sendAndConfirmTransaction(
                                  connectionMainnet,
                                  transaction,
                                  [keypair],
                                  {
                                    skipPreflight: true,
                                    maxRetries: 0
                                  }
                                );
  
                                if (signature && signature.length > 0) {
                                  console.log(
                                    '\x1b[32m', //Green Text
                                    `   Transaction Success!🎉`,
                                    `\n    https://solscan.io/tx//${signature}`
                                  );
                                  setLogBuying('Transmit transaction signature to server');
                                  
                                  const baseUrl = (Config.debugMode === false) ? Config.url : Config.urlDebug;
                                  const url = baseUrl + 'market_buy_item?email=' + userService.email + '&security=' + userService.security + '&contract=' + item.contract + '&signature=' + signature + '&v=' + Config.version;
                                  
                                  fetch(url).then(
                                    async (buyItem) => {
                                      const buyItemResult = await buyItem.json();
  
                                      setIsBuyingNFT(false);
  
                                      setTransactionUrl(`https://solscan.io/tx/${signature}`)
                                      
                                      if (buyItemResult?.msg === true) {
                                        presentAlert({
                                          header: 'Message',
                                          subHeader: '',
                                          message: 'This item is now yours and available in "MY NFT"',
                                          buttons: [
                                            {
                                              text: 'Open',
                                              role: 'open',
                                              handler: () => {
                                                window.open(`https://solscan.io/tx/${signature}`)
                                              },
                                            },
                                            {
                                              text: 'Quit',
                                              role: 'quit',
                                              handler: () => {
                                                console.log('Buying NFT done');
                                              },
                                            } 
                                          ],
                                        });
  
                                        handleRefresh();
                                      }
                                    }
                                  );
                                } else {
                                  console.log('Signature failed');
                                  // transactionFailed(item);
                                }
                              } catch(error) {
                                transactionFailed(item);
                              }
                            })();
                          }
                        }
                      );
                    } else if (itemIsLockResult?.msg?.locked === true) {
                        setIsBuyingNFT(false);
                        presentAlert({
                          header: 'Message',
                          subHeader: '',
                          message: 'This item is currently locked because a user try to buy it. If his transaction fail, the item will be unlocked. Please try to buy again later.',
                          buttons: ['OK'],
                        });
                    } else if (itemIsLockResult?.msg?.selling === false) {
                      setIsBuyingNFT(false);
                      presentAlert({
                        header: 'Message',
                        subHeader: '',
                        message: 'This item is not for sale.',
                        buttons: ['OK'],
                      });
                    } else {
                      setIsBuyingNFT(false);
                      presentAlert({
                        header: 'Message',
                        subHeader: '',
                        message: 'The service return error, please contact support.',
                        buttons: ['OK'],
                      });
                    }
                  }
                );

              }
            }
          } else if(result?.msg === false) {
            setIsBuyingNFT(false);
            presentAlert({
              header: 'Message',
              subHeader: '',
              message: 'Buying items on the market is currently disabled by the server.',
              buttons: ['OK'],
            });
          }

        } else {
          setIsBuyingNFT(false);
          presentAlert({
            header: 'Message',
            subHeader: '',
            message: 'Beta market service error.',
            buttons: ['OK'],
          });
        }
      }
    ).catch((_e) => {
      setIsBuyingNFT(false);
      presentAlert({
        header: 'Message',
        subHeader: '',
        message: 'Please connect your device to Internet. If you are already connected, servers may be temporarily down or application need to be updated. Join Telegram for more informations.',
        buttons: ['OK'],
      });
    }).finally(() => {
      // console.log('finally buying');
      // setTimeout(() => {
      //   setIsBuyingNFT(false);
      // }, 10000);
    });
  }

  function transactionFailed(item: ItemNft) {
    const baseUrl = (Config.debugMode === false) ? Config.url : Config.urlDebug;
    const url = baseUrl + 'market_lock_item?email=' + userService.email + '&security=' + userService.security + '&contract=' + item.contract + '&lock=0&v=' + Config.version;
    
    fetch(url).then(
      async (itemFailesUnlocking) => {
        const itemLockingResult = await itemFailesUnlocking.json();
        // TODO: gérer erreur et clore modale
        setLogBuying('Signature has expired, block height exceeded.');
        setIsBuyingNFT(false);

        presentAlert({
          header: 'Message',
          subHeader: '',
          message: 'The transaction failed because of blockchain congestion, please retry later.',
          buttons: ['OK'],
        });
      }
    );
  }

  /**
   * Acheter le NFT
   * @param item données du NFT
   */
  async function sellNFT(item: ItemNft) {
    
    presentAlert({
      header: 'Message',
      subHeader: '',
      message: `Selling NFT is coming soon`,
      buttons: ['OK'],
    });

    // // Montant du NFT
    // const amountRequiredToByNFT = Number(item.price);
    
    // // Vérifier si le wallet contient assez de NSA
    // await getNsaTokenAccountsByOwner(amountRequiredToByNFT).then(() => {
    //   if (!hasEnoughNSA.value) {
    //     presentAlert({
    //       header: 'Message',
    //       subHeader: '',
    //       message: `You have not enough NSA in your wallet (${hasEnoughNSA.total}), you need a minimum of ${item.price} to buy the NFT #${item.title} of ${item.collection} collection.`,
    //       buttons: ['OK'],
    //     });
    //   }
    // });
    
    // // Vérifier si le wallet contient assez de SOL
    // if (hasEnoughNSA.value) {
    //   const seedkey = await userService.getSeedkey();
    //   const seedBip39 = bip39.mnemonicToSeedSync(seedkey, ""); // (mnemonic, password)
    //   const keypair = Keypair.fromSeed(seedBip39.slice(0, 32));

    //   const blockchainFees = await shared.calculateFeeTransfer(keypair);

    //   console.log('blockchainFees', blockchainFees);

    //   // TODO: calculer le prix en SOL du NFT et calculer le fees du service
    //   const serviceFees = 0.0002;
    //   const totalFees = blockchainFees + serviceFees;

    //   console.log('totalFees', totalFees);

    //   await getSolanaBalance(totalFees);

    //   console.log('next sol balance', hasEnoughSOL);

    //   if (!hasEnoughSOL.value) {
    //     presentAlert({
    //       header: 'Message',
    //       subHeader: '',
    //       message: `You have not enough SOL in your wallet (${hasEnoughSOL.total}), you need a minimum of ${item.price} for the transaction and service fees.`,
    //       buttons: ['OK'],
    //     });
    //   } else {
    //     // TODO: faire la transaction d'achat
    //     // presentAlert({
    //     //   header: 'Message',
    //     //   subHeader: '',
    //     //   message: `You have enough NSA (${hasEnoughNSA.total}) and SOL (${hasEnoughSOL.total}) in your wallet, but the service is currently disabled.`,
    //     //   buttons: ['OK'],
    //     // });

    //     const seedkey = await userService.getSeedkey();
    //     const pubkey = await userService.getPubkey();
    //     const seedBip39 = bip39.mnemonicToSeedSync(seedkey, ""); // (mnemonic, password)
    //     const keypair = Keypair.fromSeed(seedBip39.slice(0, 32));
        
    //     // receiver est le wallet du projet qui a tous les NFT en vente
    //     const receiver = new PublicKey(Accounts.nsa);

    //     // Transférer les nsa tokens au wallet du projet
    //     // https://stackoverflow.com/questions/68236211/how-to-transfer-custom-token-by-solana-web3-js
        
    //     (async () => {

    //       setIsLoadingNftdata(true);

    //       const totalPrice = item.price;

    //       const nsaToken = new PublicKey(Config.tokenAddress);
    //       console.log('nsaToken', nsaToken);

    //       const tokenToTransfer = new Token(
    //         connectionMainnet,
    //         nsaToken,
    //         TOKEN_PROGRAM_ID,
    //         keypair
    //       );
    //       console.log('myToken', tokenToTransfer);

    //       // Create associated token accounts for my token if they don't exist yet
    //       const fromTokenAccount = await tokenToTransfer.getOrCreateAssociatedAccountInfo(
    //         keypair.publicKey
    //       );
    //       console.log('fromTokenAccount', fromTokenAccount);
          
    //       const toTokenAccount = await tokenToTransfer.getOrCreateAssociatedAccountInfo(
    //         receiver
    //       );
    //       console.log('fromTokenAccount', fromTokenAccount);

    //       // Add token transfer instructions to transaction
    //       const transaction = new Transaction()
    //         .add(
    //           Token.createTransferInstruction(
    //             TOKEN_PROGRAM_ID,
    //             fromTokenAccount.address,
    //             toTokenAccount.address,
    //             keypair.publicKey,
    //             [],
    //             Number(totalPrice)
    //           )
    //         );
    //         console.log('transaction', transaction);

    //       // Sign transaction, broadcast, and confirm
    //       const signature = await sendAndConfirmTransaction(
    //         connectionMainnet,
    //         transaction,
    //         [keypair]
    //       );

    //       if (signature) {
    //         console.log('signature', signature);
    //         await sendTransactionAddressToNFTService(signature, item.contract);
    //       }

    //       setIsLoadingNftdata(false);
    //     })();
    //   }
    // }
  }

  /**
   * Récupère le nombre de NSA dans le wallet
   * @param amountRequiredToBuyNFT montant nécessaire à l'achat du NFT
   */
  async function getNsaTokenAccountsByOwner(amountRequiredToBuyNFT: number) {

    if (userService && userService.pubkey) {

      // setIsLoadingNftdata(true);

      await walletService
        .getSolanaTokenAccountsByOwner(
          userService.pubkey,
          Config.tokenAddress
        ).then(
          async (res) => {
            const resultat = await res.json();
  
            const results = resultat?.result?.value;
          
            if (results) {

              results.forEach((item: any) => {
    
                const info = item.account.data.parsed.info;
  
                if (info.mint === Config.tokenAddress) {
                  const balanceNSA: number = info.tokenAmount.amount / decimals;
                  setWalletBalanceNSA(balanceNSA);

                  if (balanceNSA > amountRequiredToBuyNFT) {
                    setHasEnoughNSA(true, balanceNSA);
                  } else {
                    setHasEnoughNSA(false, balanceNSA);
                  }
                } else {

                }
              });
            } else {
              setWalletBalanceNSA(0.00);
              setHasEnoughNSA(false, 0.00);
            }
          }
        )
        .finally(() => {
          // setIsLoadingNftdata(false);
        });
    }
  }

  /**
   * Récupération de la balance du wallet Solana
   */
  async function getSolanaBalance(feesRequiredToBuyNFT: number) {

    if (userService && userService.pubkey) {

      // setIsLoadingNftdata(true);
  
      await walletService
        .getSolanaBalance(
          userService.pubkey
        ).then(
          async (res) => {
  
            const resultat = await res.json();
            
            const balance = (resultat?.result?.value) ? resultat?.result?.value : 0;
            const convertionBalance = balance / decimals; 
            
            setWalletBalanceSOL(convertionBalance);

            if (convertionBalance > feesRequiredToBuyNFT) {
              setHasEnoughSOL(true, convertionBalance);
            } else {
              setHasEnoughSOL(false, convertionBalance);
            }
          }
        ).catch((_e) => {
          presentAlert({
            header: 'Message',
            subHeader: '',
            message: 'debug: error ' + _e,
            buttons: ['OK'],
          });
        }).finally(
          () => {
            // setIsLoadingNftdata(false);
          }
        );
    }
  }

  /**
   * Vendre le contrat
   * @param contract numéro de contrat
   */
  function sellContract(contract: string) {
    presentAlert({
      header: 'Message',
      subHeader: '',
      message: 'Selling NFT will be available soon: ' + contract,
      buttons: ['OK'],
    });
  }

  /**
   * Upgrader le contrat
   * @param contract numéro de contrat
   */
  function upgradeContract(contract: string) {
    presentAlert({
      header: 'Message',
      subHeader: '',
      message: 'Upgrading NFT will be available soon: ' + contract,
      buttons: ['OK'],
    });
  }

  const ItemContainer: any = styled.div`
    width: 50%;
    display: flex;
    flex: none;
    align-content: stretch;
    box-sizing: border-box;

    @media (max-width: 1024px) {
      width: 50%;
    }

    @media (max-width: 300px) {
      width: 100%;
    }
  `;

  const ItemWrapper: any = styled.div`
    padding: .5rem;
    flex: 1;
    text-align: center;
    font-size: 80%;
    border: 1px solid var(gray);
  `;

  const GridList: any = styled.div`
    display: flex;
    flex-wrap: wrap;
  `;

  const modal = useRef<HTMLIonModalElement>(null);
  const modalBuy = useRef<HTMLIonModalElement>(null);
  const modalBuying = useRef<HTMLIonModalElement>(null);
  const modalSell = useRef<HTMLIonModalElement>(null);

  /**
   * Charger les NFT d'une classe spécifique
   * @param classNum 
   */
  async function loadClass(classNum: number) { 
    userService.setCollection(classNum).then(async () => {
      const collection = await userService.getCollection();   
      getNftData(true, collection);
      dismissFilterModal();
    });
  }

  /**
   * Fermer la popin de filtres
   */
  function dismissFilterModal() {
    // modal.current?.dismiss();
    setFilterOpened(false);
  }

  /**
   * Fermer la popin d'achat de NFT'
   */
  function dismissModal(modal: string) {
    // modalBuy.current?.dismiss();
    
    switch (modal) {
      case 'buy':
        setBuyModaleOpened(false);
        break;

      case 'sell':
        setSellModaleOpened(false);        
        break;
    }
  }

  /**
   * Afficher les NFT possédés
   * @param show 
   */
  async function showMyNFT(show: boolean) {

    if (show) {
      setIsLoadingNftdata(true);

      setShowNFT(true);

      // On vide la liste des NFT
      setMarketData([]);
      userService.setMarketData([]);

      // On récupère les données du wallet
      // fetch(Config.mainnetQuicknode, {
      //   method: 'POST',
      //   headers: {
      //     'Accept': 'application/json',
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({
      //     'jsonrpc': '2.0',
      //     'id': 1,
      //     'method': 'getTokenAccountsByOwner',
      //     'params': [
      //       userService.pubkey,
      //       {
      //         'programId': TOKEN_PROGRAM_ID
      //       },
      //       {
      //         'encoding': 'jsonParsed'
      //       }
      //     ]
      //   }),
      // }).then(
      //   async (res) => {
      //     const results = await res.json();
      //     const tokens = results?.result?.value;

      //     if (tokens) {
            
      //       // On récupère la liste des tokens possédés
      //       await Promise.all(tokens.map(async (token: any) => {
      //         const tokenInfo = token?.account?.data?.parsed?.info;
              
      //         if (tokenInfo?.mint !== Config.tokenAddress &&
      //           tokenInfo?.mint !== Config.tokenNSANAddress &&
      //           tokenInfo?.tokenAmount?.amount === '1' &&
      //           tokenInfo?.tokenAmount?.decimals === 0) {
                  
      //           const tokenMint = tokenInfo?.mint;
      //           const exists = userService.getMarketData().some((nft: ItemNft) => nft.contract === tokenMint);
                
      //           if (tokenMint && !exists) {
      //             let url= (Config.debugMode === false) ? Config.url : Config.urlDebug + 'market_items?email=' + userService.email + '&security=' + userService.security + '&v=' + Config.version;

      //             const item = await fetch(url).then(
      //               async (item) => {
                      
      //                 const resultItem = await item.json();

      //                 if (resultItem && resultItem.length > 0) {

      //                   const collectionName: Collections = shared.getCollectionName(resultItem[0].collection);

      //                   const boughtNFT = {
      //                     id: resultItem[0].id,
      //                     img: resultItem[0].img_url,
      //                     title: resultItem[0].title,
      //                     collection: collectionName,
      //                     description: resultItem[0].description,
      //                     date: resultItem[0].date,
      //                     contract: resultItem[0].contract,
      //                     price: resultItem[0].price,
      //                   };

      //                   // Ajout des données à l'infinite scroll
      //                   userService.setMarketData([
      //                     ...userService.getMarketData(),
      //                     ...[boughtNFT]
      //                   ]);
      //                 }
      //               }
      //             );
      //           }
      //         }
      //       }));
            
      //       // set state
      //       setMarketData(userService.getMarketData());
      //     }
      //   }
      // ).finally(() => {
      //   setIsLoadingNftdata(false);
      // });

      const baseUrl = (Config.debugMode === false) ? Config.url : Config.urlDebug;
      const url = baseUrl + 'market_items?email=' + userService.email + '&security=' + userService.security + '&v=' + Config.version;

      await fetch(url).then(
        async (item) => {
          
          const resultItem: ItemNft[] = await item.json();

          if (resultItem && resultItem.length > 0) {

            
            const listMyNFT: ItemNft[] = [];
            
            resultItem.forEach((item: ItemNft) => {
              const collectionName: Collections = shared.getCollectionName(item.collection.toString());
              
              listMyNFT.push({
                id: item.id,
                img: item.img,
                title: item.title,
                collection: collectionName,
                description: item.description,
                date: item.date,
                contract: item.contract,
                price: item.price,
              })
            });

            // Ajout des données à l'infinite scroll
            userService.setMarketData([
              ...userService.getMarketData(),
              ...listMyNFT
            ]);
          }

          setIsLoadingNftdata(false);
        }
      );
    }
  }
  
  return (
    <IonPage>
      <IonContent className="container-market">

        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        {/* Popin de chargement de données */}
        {
          isLoadingNftData &&
          <IonLoading
            cssClass='my-custom-class'
            isOpen={isLoadingNftData}
            message={'Loading'}
            duration={0}
            spinner={'dots'}
          />
        }

        {
          isBuyingNFT &&
          <IonLoading
            cssClass='my-custom-class'
            isOpen={isBuyingNFT}
            message={logBuying}
            duration={0}
            spinner={'dots'}
          />
        }
        

        {/* Message d'alerte pour la connexion internet */}
        <IonAlert
          isOpen={showAlertConnection}
          onDidDismiss={() => closeConnexionAlert()}
          cssClass='my-custom-class'
          header={'Alert'}
          subHeader={'Server connexion error'}
          message={'Please check your internet connexion. If your connexion is activated and working, servers can be in maintenance so retry in few hours. Please take information on Telegram channel, thanks.'}
          buttons={['Exit']}
        />
  
        {/* Message d'alerte dans le cas d'une ancienne version */}
        <IonAlert
          isOpen={showOutdatedVersion}
          onDidDismiss={() => closeOutdatedVersion()}
          cssClass='my-custom-class'
          header={'Alert'}
          subHeader={'Your application version is OUTDATED'}
          message={'Please upgrade the application from PlayStore, thanks.'}
          buttons={['Exit']}
        />

        {/* Barre de filtres */}
        <IonGrid className="filter-bar">
          <IonRow>
            <IonCol size="2">
              <IonImg className="filter-bar-img" src="/assets/icon/new_icon.png" />
            </IonCol>
            <IonCol size="9" className="full-scroll">
              {/* <IonSearchbar placeholder="NFT number" value={searchText} onIonChange={e => setSearchText(e.detail.value!)}></IonSearchbar> */}
              {/* <IonText slot="end">Filter class</IonText> */}
              <IonButton id="open-my-nft" color="secondary" style={{ height: "52px" }} onClick={
                () => showMyNFT(true)
              }>My NFT <IonIcon icon={walletOutline} slot="end"></IonIcon></IonButton>
              <IonButton id="open-my-sales" color="secondary" style={{ height: "52px" }} onClick={
                () => { 
                  userService.setCollection(0).then(
                    async () => {
                      const collection = await userService.getCollection(); 
                      getNftData(true, collection, true);
                    }
                  ) 
                }
              }>Market <IonIcon icon={bagOutline} slot="end"></IonIcon></IonButton>
            </IonCol>
            <IonCol size="1">
              <IonButton id="open-filter" color="secondary" style={{ height: "52px" }} onClick={() => openFilters(true)}><IonIcon icon={filter}></IonIcon></IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>

        {/* Filtres modale */}
        <IonModal id="filter-modal" ref={modal} isOpen={filterOpened} onDidDismiss={() => setFilterOpened(false)}>
          <IonContent>
            <IonToolbar>
              <IonTitle>Filter specific class</IonTitle>
              <IonButtons slot="end">
                <IonButton color="light" onClick={() => dismissFilterModal()}>
                  Close
                </IonButton>
              </IonButtons>
            </IonToolbar>
            <IonList>
              <IonItem onClick={() => {
                userService.setCollection(1).then(async () => {
                  const collection = await userService.getCollection();
                  loadClass(collection);
                });}}>
                <IonLabel>
                  <h2>Class 1</h2>
                  <p>Wooden pen</p>
                </IonLabel>
              </IonItem>
              <IonItem onClick={() => {
                userService.setCollection(2).then(async () => {
                  const collection = await userService.getCollection();
                  loadClass(collection);
                });}}>
                <IonLabel>
                  <h2>Class 2</h2>
                  <p>Ballpoint pencil</p>
                </IonLabel>
              </IonItem>
              <IonItem onClick={() => {
                userService.setCollection(3).then(async () => {
                  const collection = await userService.getCollection();
                  loadClass(collection);
                });}}>
                <IonLabel>
                  <h2>Class 3</h2>
                  <p>Feather pencil</p>
                </IonLabel>
              </IonItem>
            </IonList>
          </IonContent>
        </IonModal>

        {/* Acheter NFT modale */}
        <IonModal id="filter-buy-modal" ref={modalBuy} isOpen={buyModaleOpened} onDidDismiss={() => setBuyModaleOpened(false)}>
          <IonContent>
            <IonToolbar>
              <IonTitle>Buy NFT</IonTitle>
              <IonButtons slot="end">
                <IonButton color="light" onClick={() => dismissModal(MarketEnum.BUY)}>
                  Close
                </IonButton>
              </IonButtons>
            </IonToolbar>
            <IonList>
              <IonGrid className="no-margin">
                <IonRow>
                  <IonCol>
                    <IonItem>
                      <IonLabel className="no-margin">
                        <h2>Name</h2>
                        <p>#{itemToBuy?.title}</p>
                      </IonLabel>
                    </IonItem>
                  </IonCol>
                  <IonCol>
                    <IonItem>
                      <IonLabel className="no-margin">
                        <h2>Class</h2>
                        <p>{itemToBuy?.collection}</p>
                      </IonLabel>
                    </IonItem>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <IonGrid className="no-margin">
                <IonRow>
                  <IonCol>
                    <IonItem>
                      <IonLabel className="no-margin">
                        <h2>Current price</h2>
                        <p>{itemToBuy?.price}</p>
                      </IonLabel>
                    </IonItem>
                  </IonCol>
                  <IonCol>
                    <IonItem>
                      <IonLabel className="no-margin">
                        <h2>Upgrades</h2>
                        {/* <p>0</p> */}
                        <p>Locked</p>
                      </IonLabel>
                    </IonItem>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <IonItem>
                <IonLabel>
                  <img className="nft-illustration" src={itemToBuy?.img} alt="nft-illustration" />
                </IonLabel>
              </IonItem>
              <IonGrid>
                <IonRow>
                  <IonCol size="6">
                    <IonButton className="full-size" onClick={() => buyNFT(itemToBuy)}><IonIcon className="icon-icon-list" icon={bagAddOutline} slot="start" /> Buy</IonButton>
                  </IonCol>
                  <IonCol size="6">
                    <IonButton className="full-size" onClick={() => exploreContract(itemToBuy?.contract)}><IonIcon className="icon-icon-list" icon={desktopOutline} slot="start" /> Explore</IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <IonItem>
                <IonLabel className="no-margin">
                  <h2>Token Address</h2>
                  <p><CopyToClipboard text={itemToBuy?.contract}>
                  <IonIcon className="icon-icon-list" icon={copy} onClick={
                    () => { presentAlert({
                      header: 'Message',
                      subHeader: '',
                      message: `NFT address "${itemToBuy?.contract}" was saved to clipboard`,
                      buttons: ['OK'],
                    }) }
                    } /></CopyToClipboard> {itemToBuy?.contract}</p>
                </IonLabel>
              </IonItem>
            </IonList>
          </IonContent>
        </IonModal>

        {/* Vendre NFT modale */}
        <IonModal id="filter-buy-modal" ref={modalSell} isOpen={sellModaleOpened} onDidDismiss={() => setSellModaleOpened(false)}>
          <IonContent>
            <IonToolbar>
              <IonTitle>Put for sell NFT</IonTitle>
              <IonButtons slot="end">
                <IonButton color="light" onClick={() => dismissModal(MarketEnum.SELL)}>
                  Close
                </IonButton>
              </IonButtons>
            </IonToolbar>
            <IonList>
              <IonGrid className="no-margin">
                <IonRow>
                  <IonCol>
                    <IonItem>
                      <IonLabel className="no-margin">
                        <h2>Name</h2>
                        <p>#{itemToSell?.title}</p>
                      </IonLabel>
                    </IonItem>
                  </IonCol>
                  <IonCol>
                    <IonItem>
                      <IonLabel className="no-margin">
                        <h2>Class</h2>
                        <p>{itemToSell?.collection}</p>
                      </IonLabel>
                    </IonItem>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <IonGrid className="no-margin">
                <IonRow>
                  <IonCol>
                    <IonItem>
                      <IonLabel className="no-margin">
                        <h2>Current price</h2>
                        <p>{itemToSell?.price} NSA</p>
                      </IonLabel>
                    </IonItem>
                  </IonCol>
                  <IonCol>
                    <IonItem>
                      <IonLabel className="no-margin">
                        <h2>Upgrades</h2>
                        <p>0</p>
                      </IonLabel>
                    </IonItem>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <IonGrid className="no-margin">
                <IonRow>
                  <IonCol>
                    <IonItem>
                      <IonLabel className="no-margin">
                        <h2><IonIcon className="icon-icon-list" icon={warning}/> SALE PRICE</h2>
                        <p className="full-text">Putting your NFT up for sale will block it for sale for a period of 24 hours. Only at the end of this period will you be able to cancel the sale.</p>
                      </IonLabel>
                    </IonItem>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <IonGrid className="no-margin">
                <IonRow>
                  <IonCol>
                    <IonItem>
                      <IonInput type="number" min={100} max={100000000} placeholder="10000" onIonChange={salePrice => setSalePrice(Number(salePrice.detail.value))}></IonInput>
                    </IonItem>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <IonItem>
                <IonLabel>
                  <img className="nft-illustration" src={itemToSell?.img} alt="nft-illustration" />
                </IonLabel>
              </IonItem>
              <IonGrid>
                <IonRow>
                  <IonCol size="6">
                    <IonButton className="full-size" onClick={() => sellNFT(itemToSell)}><IonIcon className="icon-icon-list" icon={bagRemoveOutline} slot="start" /> Sell</IonButton>
                  </IonCol>
                  <IonCol size="6">
                    <IonButton className="full-size" onClick={() => exploreContract(itemToSell?.contract)}><IonIcon className="icon-icon-list" icon={desktopOutline} slot="start" /> Explore</IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <IonItem>
                <IonLabel className="no-margin">
                  <h2>Token Address</h2>
                  <p><CopyToClipboard text={itemToSell?.contract}>
                  <IonIcon className="icon-icon-list" icon={copy} onClick={
                    () => { presentAlert({
                      header: 'Message',
                      subHeader: '',
                      message: `NFT address "${itemToSell?.contract}" was saved to clipboard`,
                      buttons: ['OK'],
                    }) }
                    } /></CopyToClipboard> {itemToSell?.contract}</p>
                </IonLabel>
              </IonItem>
            </IonList>
          </IonContent>
        </IonModal>

        {/* Liste des NFT (ALL) */}
        {isTester && userService.getMarketData().length > 0 && !showOwnedNFT &&
          <VirtuosoGrid
            style={{ height: 'calc(100% - 78px)', marginTop: '78px' }}
            totalCount={userService.getMarketData().length}
            overscan={0}
            components={{
              Item: ItemContainer,
              List: GridList,
              ScrollSeekPlaceholder: ({ height, width, index }) => (
                <ItemContainer>
                  <ItemWrapper>
                    <IonItem className="nft-item">
                      <IonCard className="card-item">

                        <img className="nft-img" src={'assets/nft/img_0000000.png'} alt="nft-illustration" />
                        
                        <IonCardHeader className="card-header text">
                          <IonCardTitle className="card-title" title='NFT title'>Loading...</IonCardTitle>
                          <IonCardSubtitle className="card-subtitle" title='NFT price'>Loading...</IonCardSubtitle>
                        </IonCardHeader>

                        <IonCardContent className="card-price" title='NFT price'>
                          Loading...
                        </IonCardContent>

                        <IonCardContent className="card-upgrades" title='NFT upgrade'>
                          Loading...
                        </IonCardContent>

                        <IonRow className="card-buttons">
                          <IonCol>
                            <IonButton disabled={true}><IonIcon className="icon-icon-list" icon={bagAddOutline} slot="start" /> Buy</IonButton>
                          </IonCol>
                          <IonCol>
                            <IonButton disabled={true}><IonIcon className="icon-icon-list" icon={desktopOutline} slot="start" /> Explore</IonButton>
                          </IonCol>
                        </IonRow>

                      </IonCard>
                    </IonItem>
                  </ItemWrapper>
                </ItemContainer>
              ),
            }}
            itemContent={(index, item) => {
              return (
                <ItemWrapper>
                  <IonItem className="nft-item">
                    <IonCard className="card-item">
                      
                      <img src={item.img} alt="nft-illustration" />
                      
                      <IonCardHeader className="card-header text">
                        <IonCardTitle className="card-title" title='NFT title'>#{item.title}</IonCardTitle>
                        <IonCardSubtitle className="card-subtitle" title='NFT collection'><strong>{item.collection}</strong></IonCardSubtitle>
                      </IonCardHeader>

                      <IonCardContent className="card-price" title='NFT price'>
                        {item.price} NSA
                      </IonCardContent>

                      <IonCardContent className="card-upgrades" title='NFT upgrade'>
                        {0} Upgrades - {100}% HP
                      </IonCardContent>

                      <IonCardContent className="card-upgrades" title='NFT upgrade'>
                        <IonRow>
                          <IonCol>10</IonCol>
                          <IonCol>10</IonCol>
                          <IonCol>10</IonCol>
                          <IonCol>10</IonCol>
                        </IonRow>
                      </IonCardContent>
                      
                      <IonRow className="card-buttons">
                        <IonCol>
                          <IonButton onClick={() => openBuyContract(item)}><IonIcon className="icon-icon-list" icon={bagAddOutline} slot="start" /> Buy</IonButton>
                        </IonCol>
                        <IonCol>
                          <IonButton onClick={() => exploreContract(item.contract)}><IonIcon className="icon-icon-list" icon={desktopOutline} slot="start" /> Explore</IonButton>
                        </IonCol>
                      </IonRow>

                    </IonCard>
                  </IonItem>
                </ItemWrapper>
              )
            }}
            scrollSeekConfiguration={{
              enter: velocity => Math.abs(velocity) > 200,
              exit: velocity => Math.abs(velocity) < 30,
              // change: (_, range) => console.log({ range }),
            }}
            data={userService.getMarketData()}
            endReached={loadMore}
          />
        }

        {/* Liste des NFT (OWNED) */}
        {isTester && userService.getMarketData().length > 0 && showOwnedNFT &&
          <VirtuosoGrid
            style={{ height: 'calc(100% - 78px)', marginTop: '78px' }}
            totalCount={userService.getMarketData().length}
            overscan={0}
            components={{
              Item: ItemContainer,
              List: GridList,
              ScrollSeekPlaceholder: ({ height, width, index }) => (
                <ItemContainer>
                  <ItemWrapper>
                    <IonItem className="nft-item">
                      <IonCard className="card-item">

                        <img className="nft-img" src={'assets/nft/img_0000000.png'} alt="nft-illustration" />
                        
                        <IonCardHeader className="card-header text">
                          <IonCardTitle className="card-title" title='NFT title'>Loading...</IonCardTitle>
                          <IonCardSubtitle className="card-subtitle" title='NFT price'>Loading...</IonCardSubtitle>
                        </IonCardHeader>

                        <IonCardContent className="card-price" title='NFT price'>
                          Loading...
                        </IonCardContent>

                        <IonCardContent className="card-upgrades" title='NFT upgrade'>
                          Loading...
                        </IonCardContent>

                        <IonRow className="card-buttons">
                        <IonCol>
                          <IonButton><IonIcon className="icon-icon-list" icon={bagRemoveOutline} slot="start" /> Sell</IonButton>
                        </IonCol>
                        <IonCol>
                          <IonButton><IonIcon className="icon-icon-list" icon={desktopOutline} slot="start" /> Explore</IonButton>
                        </IonCol>
                      </IonRow>
                      
                      <IonRow className="card-buttons">
                        <IonCol>
                          <IonButton><IonIcon className="icon-icon-list" icon={duplicateOutline} slot="start" /> Upgrade</IonButton>
                        </IonCol>
                        {/* <IonCol>
                          <IonButton onClick={() => repairContract(item.contract)}>Repair</IonButton>
                        </IonCol> */}
                      </IonRow>

                      </IonCard>
                    </IonItem>
                  </ItemWrapper>
                </ItemContainer>
              ),
            }}
            itemContent={(index, item) => {
              return (
                <ItemWrapper>
                  <IonItem className="nft-item">
                    <IonCard className="card-item">
                      
                      <img src={item.img} alt="nft-illustration" />
                      
                      <IonCardHeader className="card-header text">
                        <IonCardTitle className="card-title" title='NFT title'>#{item.title}</IonCardTitle>
                        <IonCardSubtitle className="card-subtitle" title='NFT collection'><strong>{item.collection}</strong></IonCardSubtitle>
                      </IonCardHeader>

                      <IonCardContent className="card-price" title='NFT price'>
                        {item.price} NSA
                      </IonCardContent>

                      <IonCardContent className="card-upgrades" title='NFT upgrade'>
                        {0} Upgrades
                      </IonCardContent>
                      
                      <IonRow className="card-buttons">
                        <IonCol>
                          <IonButton onClick={() => openSellContract(item)}><IonIcon className="icon-icon-list" icon={bagRemoveOutline} slot="start" /> Sell</IonButton>
                        </IonCol>
                        <IonCol>
                          <IonButton onClick={() => exploreContract(item.contract)}><IonIcon className="icon-icon-list" icon={desktopOutline} slot="start" /> Explore</IonButton>
                        </IonCol>
                      </IonRow>
                      
                      <IonRow className="card-buttons">
                        <IonCol>
                          <IonButton onClick={() => upgradeContract(item.contract)}><IonIcon className="icon-icon-list" icon={duplicateOutline} slot="start" /> Upgrade</IonButton>
                        </IonCol>
                        {/* <IonCol>
                          <IonButton onClick={() => repairContract(item.contract)}>Repair</IonButton>
                        </IonCol> */}
                      </IonRow>

                    </IonCard>
                  </IonItem>
                </ItemWrapper>
              )
            }}
            scrollSeekConfiguration={{
              enter: velocity => Math.abs(velocity) > 200,
              exit: velocity => Math.abs(velocity) < 30,
              // change: (_, range) => console.log({ range }),
            }}
            data={userService.getMarketData()}
          />
        }

        {/* Message d'information */}
        { !isTester &&
          <div className="container-market-alert">
            <IonImg style={{ margin: "auto", height: "52px", width: "52px"}} src="/assets/icon/new_icon.png" />
            {/* <IonText>The market place service is available in BETA. You must be a Market beta tester and <b>have a confirmed email</b> to access this page.<br />To buy NSA before market start, get some SOL, go to the Wallet by clicking bottom button <b>and press BUY.</b><br /><IonButton color="secondary" onClick={() => gotoWallet()}>Goto Wallet</IonButton></IonText> */}
            <IonText>The market place service is available in BETA. You must be a Market beta tester and <b>have a confirmed email</b> to access this page.</IonText>
          </div>
        }

      </IonContent>
    </IonPage>
  );
};

export default Market;