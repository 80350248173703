import { IonButton, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonLoading, IonPage, IonTitle, IonToolbar, useIonAlert } from '@ionic/react';
import { person } from 'ionicons/icons';
import { useState } from 'react';
// import { useHistory } from 'react-router';
import Config from '../../config/settings';
import UserService from '../../services/user';
import './Forgot.css';

const Forgot: React.FC<{ userService?: UserService }> = ({ userService }) => {

  let [email, setEmail] = useState<string>();
  const [isLoadingForgot = false, setIsLoadingForgot] = useState<boolean>();
  const [isDisabled = false, setIsDisabled] = useState<boolean>();
  const [presentAlert] = useIonAlert();

  // const history = useHistory();

  const recoverUser = async () => {
    let ready = false;
    console.log('click 1', userService);

    // alert(client_config[1] + 'email: ' + email + ', password: ' + password + ', url: ' + client_config[0]);
    if (!email || email.length === 0 || email.indexOf('@') === -1) {
      presentAlert({
        header: 'Message',
        subHeader: '',
        message: 'Please enter an email.',
        buttons: ['OK'],
      });
      ready = false;
    } else {
      ready = true;
    }

    // Données compte utilisateur
    if (ready && userService) {
      console.log('click 2');
      recoverMail();
    }
  };

  async function recoverMail() {
    setIsLoadingForgot(true);
    setIsDisabled(true);

    const baseUrl = (Config.debugMode === false) ? Config.url : Config.urlDebug;
    const url = baseUrl + 'recover?email=' + email + '&v=' + Config.version;

    fetch(url).then(
      async (res) => {
        const result: any = await res.json();

        if (result && (result.msg.indexOf('found') > -1)) {
          presentAlert({
            header: 'Message',
            subHeader: '',
            message: 'Email not found.',
            buttons: ['OK'],
          });
        } else if (result.msg.indexOf('Nothing') > -1) {
          presentAlert({
            header: 'Message',
            subHeader: '',
            message: 'Server under maintenance, please retry later.',
            buttons: ['OK'],
          });
        } else if (result && result.msg.indexOf('done') > -1) {
          presentAlert({
            header: 'Message',
            subHeader: '',
            message: 'An email was sent to : ' + email + '\nPlease go to your email account and follow instructions.',
            buttons: ['OK'],
          });
        }
      }
    ).catch((_e) => {
      presentAlert({
        header: 'Message',
        subHeader: '',
        message: 'Please connect your device to Internet. If you are already connected, servers may be temporarily down or application need to be updated.' + _e,
        buttons: ['OK'],
      });
    }).finally(() => setIsLoadingForgot(false));

  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle color="primary">Forgot</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>

        <div className="header-forgot">
          <h1>NEBULA</h1>
          <h2>Blockchained Discussions in total Freedom</h2>
        </div>

        <div className="container-forgot">
          <h1>Password lost</h1>
          <p className="recover-email">If you have lost your password, please enter your email below and press the next button. An email will be sent to your address.</p>

          {
            isLoadingForgot &&
            <IonLoading
              cssClass='my-custom-class'
              isOpen={isLoadingForgot}
              message={'Loading'}
              duration={0}
              spinner={'dots'}
            />
          }

          <IonItem>
            <IonLabel color="primary">Email</IonLabel>
            <IonInput placeholder="Enter email here" type="email" value={email} max="50" onIonChange={e => setEmail(e.detail.value!)}></IonInput>
            <IonIcon color="primary" icon={person} />
          </IonItem>

          <IonButton color="secondary" disabled={isDisabled} className="register-button" onClick={() => { recoverUser() }}>Recover password</IonButton>
          
          <p className="telegram-support">For extra support, please go to Telegram channel with the button below. Support is individual and can take some times, please be patient.</p>

          <button className="telegram-button" onClick={() => { window.open('https://t.me/nebula_android_application') }}>Nebula Support</button>

          <a className="login-link" target="_self" rel="noopener noreferrer" href="/login">Go back to Login</a>

        </div>

      </IonContent>
    </IonPage>
  );
};

export default Forgot;
