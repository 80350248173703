import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { Keypair, PublicKey } from "@solana/web3.js";
import Config from "../../config/settings";
import GetOurTokenAccountsByOwnerBridgePlugin from '../../bridges/APIbridges/GetOurTokenAccountsByOwnerBridgePlugin';
import * as bip39 from "bip39";
import * as ed25519_hd_key from "ed25519-hd-key";

export class WalletService {

    getSolanaTokenAccountsByOwner(pubkey: string, splKey: string): Promise<Response> {
      return fetch(Config.mainnetQuicknode, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            'jsonrpc': '2.0',
            'id': 1,
            'method': 'getTokenAccountsByOwner',
            'params': [
              pubkey,
              {
                'mint': splKey
              },
              {
                'encoding': 'jsonParsed'
              }
            ]
          }),
        }
      );
    }

    getSolanaBalance(pubkey: string): Promise<Response> {
      return fetch(Config.mainnetQuicknode, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            'jsonrpc': '2.0',
            'id': 1,
            'method': 'getBalance',
            'params': [
              pubkey,
            ]
          }),
        }
      );
    }

    async getOurTokenAccountsByOwner(accountPubkeyString: string) {
      const { url, body } = await GetOurTokenAccountsByOwnerBridgePlugin.request(
        {
          url: Config.mainnetQuicknode,
          body: JSON.stringify({
            "method":"getTokenAccountsByOwner",
            "jsonrpc":"2.0",
            "params": [
              new PublicKey(accountPubkeyString),
              {
                "programId": TOKEN_PROGRAM_ID
              },
              {
                "encoding":"jsonParsed",
                "commitment":"confirmed"
              }
            ],
            "id":1
          })
        }
      );

      console.log('getOurTokenAccountsByOwner', url,body);
  
      return body;
    }

    getSolUsdPrice() {
      return fetch('https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd').then(
        async (result) => {
          const solResult = await result.json();
          return solResult['solana']['usd'];
        }
      );
    }

    /**
     * Génération du wallet
     * @param mnemonic suite de mots clés 
     * @returns 
     */
    generateWallet(mnemonic: string) {
      let wallet: any = {};
      let master_seed = bip39.mnemonicToSeedSync(mnemonic);
      let index = 0;
      let derived_path = "m/44'/501'/" + index + "'/0'";
      let derived_seed = ed25519_hd_key.derivePath(derived_path, master_seed.toString('hex')).key;
      wallet.keypair = Keypair.fromSeed(derived_seed);
      wallet.mnemonic = mnemonic;
      wallet.publicAddress = wallet.keypair.publicKey.toBase58();
      return wallet;
    }

    /**
     * Génération de mots clés de wallet
     * @returns mnemonic
     */
    generateMnemonic(): string {
      return bip39.generateMnemonic();
    }
}