import { IonAlert, IonAvatar, IonButton, IonButtons, IonCard, IonCardContent, IonChip, IonCol, IonContent, IonFab, IonFabButton, IonFabList, IonFooter, IonGrid, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonLoading, IonModal, IonPage, IonRange, IonRefresher, IonRefresherContent, IonRow, IonSearchbar, IonText, IonTextarea, IonTitle, IonToolbar, useIonAlert, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import { add, alertCircle, thumbsUp, thumbsDown, shareSocialSharp, settings, copy, backspace, enter, folderOpen, checkmarkDone, checkbox, chatboxEllipsesOutline, expandOutline } from 'ionicons/icons';
import { useCallback, useEffect, useRef, useState } from 'react';
import UserService from '../../services/user';
import './Platform.css';
import Config from '../../config/settings';
import CopyToClipboard from 'react-copy-to-clipboard';
import * as ClipboardAndroid from '@capacitor/clipboard';
import { ItemTxt } from '../../models/itemTxt';
import { CommentTxt } from '../../models/commentTxt';
import { Subscription } from 'rxjs';
import { Virtuoso } from 'react-virtuoso'
import { ItemSub } from '../../models/itemSub';
import AvatarEditor from 'react-avatar-editor'
import { IonRangeCustomEvent, RangeChangeEventDetail, RangeValue } from '@ionic/core';
import { Shared } from '../../shared/shared';
// import ModaleSelectedItem from '../../shared/Modale-selected-item/Modale-selected-item';

const Platform: React.FC<{ shared?: Shared, userService?: UserService, isAndroidDevice: boolean }> = ({ shared, userService, isAndroidDevice }) => {
  const [searchText, setSearchText] = useState('');
  const [data, setData] = useState<ItemTxt[]>([]);
  const [commentData, setCommentData] = useState<CommentTxt[]>([]);
  // const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  const [versionTested = false, setVersionTested] = useState<boolean>();
  const [showAlertConnection, setShowAlertConnexion] = useState(false);
  const [showOutdatedVersion, setShowOutdatedVersion] = useState(false);
  const [isTester = false, setIsTester] = useState<boolean>();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ItemTxt>(null);
  const [comment, setComment] = useState<string>('');
  const [isLoadingUserData = false, setIsLoadingUserdata] = useState<boolean>();
  const [webBalance = false, setWebBalance] = useState<boolean>();
  const [nebulaReady = false, setNebulaReady] = useState<boolean>();
  const [showContentAdding = false, setShowContentAdding] = useState<boolean>();
  // const [showThumbPreview = false, setShowThumbPreview] = useState<boolean>();
  const [showThumbEditor = false, setShowThumbEditor] = useState<boolean>();
  const [thumbScale = 1, setThumbScale] = useState<RangeValue>();
  const [thumbPreview, setThumbPreview] = useState<string>();
  const [description, setDescription] = useState<string>('');
  const [urlPath, setUrlPath] = useState<string>();
  const [tags, setTags] = useState<string>();
  const [countDescr, setCountDescr] = useState(0);
  const [countComment, setCountComment] = useState(0);
  const [imageSourceCustom = false, setImageSourceCustom] = useState<boolean>();
  const [changeButtonToUnsubscribe = false, setChangeButtonToUnsubscribe] = useState<boolean>();
  const [endOfItems = false, setEndOfItems] = useState<boolean>();
  const [imageFileCustom, setImageFileCustom] = useState<File | string>();
  const [cropWidth = 400, setCropWidth] = useState<number>();
  const [cropHeight = 300, setCropHeight] = useState<number>();
  const [presentAlert] = useIonAlert();
  const editor = useRef(null);
  
  // eslint-disable-next-line
  const httpRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:,%_\+.~#?&\/=]*)$/;
  const emptyThumb = '/assets/thumb/empty.png';

  // Resize de l'éditeur crop
  window.onresize = function() {
    const body = document.getElementsByTagName('body');
    if (body && body[0]) {
      setCropWidth(body[0].clientWidth - 100);
      setCropHeight((body[0].clientWidth - 100) * 0.75);
    }
  };

  let userServiceSubscribe: Subscription;
  
  useEffect(() => {
    
    const timeout = loadMore();
    
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line
  }, []);
  
  useEffect(() => {

    setCountDescr(description.length);
    setCountComment(comment.length);

    return () => {
      setCountDescr(0);
      setCountComment(0);
    };
  }, [description, comment]);

  useEffect(() => {
    setNebulaCheckIfReady();
  }, [urlPath, description, tags, thumbPreview]);

  // Action lors du bouton BACK
  var backButton = function() {
    var handler = function(ev: any) {
      ev.preventDefault();
      ev.stopPropagation();
      ev.stopImmediatePropagation();
    };
    return handler;
  };

  useIonViewWillEnter(async () => {

    // Action lors du bouton BACK
    document.addEventListener('ionBackButton', backButton, false);

    // Border autour du bouton add nebula
    const fabButtons = document.getElementById('fab-border-add');
    const fabButton = fabButtons.shadowRoot.children[0];
    fabButton.setAttribute('style', 'border: 3px solid rgb(133, 231, 133');

    // Attente du chargement du user service
    userServiceSubscribe = userService.storageInitSubject.subscribe((result) => {
      if (result === true) {

        // Si les données sont déjà présentes on les sauvegardes
        if (userService.platformCalled === true) {
          // Récupération des données sauvegardées
          setData(userService.getPlatformData());
        } else {
          // Si premier affichage, on charge les données
          if (userService.email && userService.email.length > 0 && userService.security && userService.security.length > 0) {
            // Vérification de la version
            checkVersion(userService.email).then((isOutDated) => {

              if (!isOutDated && isOutDated === false) {
                // Si la version est ok et que internet fonctionne
                getPlatformData();
                if (webBalance === false) getBalance(userService.email, userService.security, false);
              } else if (isOutDated === null) {
                // Si internet ne fonctionne pas
                setShowAlertConnexion(true);
              } else if (isOutDated === true) {
                // Si la version est trop vieille
                setShowOutdatedVersion(true);
              }
            });
          }
        }
      }
    });
  });

  useIonViewWillLeave(() => {
    // Vidage des nébuleuses
    setData([]);
    // Vidage des commentaires
    setCommentData([]);
    // Suppression de la souscription
    if (userServiceSubscribe) {
      userServiceSubscribe.unsubscribe();
    }

    document.removeEventListener('ionBackButton', backButton);
  });
  
  /**
   * Ouvre ou ferme une nébuleuse au clic sur un item
   * ou au clic sur le retour
   * @param status Statut ouvert/fermé de la modale
   * @param item Item sélectionné
   */
  function openOrCloseItemModal(status: boolean, item: ItemTxt, id: number) {
    
    if (!isTester) {
      presentAlert({
        header: 'Message',
        subHeader: '',
        message: 'You need to be a Tester to interact with the Platform service.',
        buttons: ['OK'],
      });
    } else {
      setIsOpen(status);
      setChangeButtonToUnsubscribe(false);
      setSelectedItem(item);
      
      if (status === true) {
        // console.log('open modal', status, item.id, id, userService.getPlatformData());
        
        userService.getSubsList().then(
          (subList) => {
            if (item && item.email) {
              const newSub: ItemSub = {
                avatar: '',
                channel: item.email.split('%40')[0]
              };
      
              // On recherche si le nom du channel est déjà dans la sub list
              const isInSubList = subList && subList.length > 0 ? subList.find(item => item.channel === newSub.channel) : null;
              
              // Si le sub est présent dans la liste on change le bouton en unsubscribe
              if (isInSubList) {
                setChangeButtonToUnsubscribe(true)
              }
            }
          }
        );

        // Récupération des commentaires associés à l'item choisi
        getCommentsOfItem(item);
      }
    }
  }

  /**
   * Envoie d'un nouveau commentaire
   * @param newComment Contenu du commentaire
   * @param item Item sélectionné
   */
  function sendComment(newComment: string, item: ItemTxt) {
    console.log('newComment', newComment, item);
    
    if (newComment && item) {
      console.log('length', newComment.length);
      if (newComment.length < 3 || newComment.length > 350) {
        presentAlert({
          header: 'Message',
          subHeader: '',
          message: 'Your comment length must be between 3 and 350.',
          buttons: ['OK'],
        });
      } else {
        setIsLoadingUserdata(true);
        
        const baseUrl = (Config.debugMode === false) ? Config.url : Config.urlDebug;
        const url = baseUrl + 'post_comment?email=' + userService.email + '&security=' + userService.security + '&v=' + Config.version;
  
        fetch(url, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(
            {
              'comment': newComment,
              'url_id': item.id
            }
          )
        }).then(
          async (res) => {
            const result = await res.json();
            
            //refreshCommentList();
          }
        ).catch((_e) => {
          if(_e.status === 403) {
            presentAlert({
              header: 'Message',
              subHeader: '',
              message: '403',
              buttons: ['OK'],
            });
          } else {
            console.log('Error', _e);
          }
        }).finally(() => {
          setComment('');
          getCommentsOfItem(item);
          setIsLoadingUserdata(false);
        });
      }

    } else {
      presentAlert({
        header: 'Message',
        subHeader: '',
        message: 'Please fill in the field !',
        buttons: ['OK'],
      });
      // Stop du loader
      setIsLoadingUserdata(false);
    }
  }

  /**
   * Filtrer les caractères spéciaux de la description
   */
  function filterDescription(e: CustomEvent<any>) {
    // eslint-disable-next-line
    e.detail.value! = e.detail.value!.replace(/[&\/\\#'"<>]/g,' ');

    setDescription(e.detail.value!);
  }

  /**
   * Récupération de l'url dans le clipboard
   */
  async function pasteClipboardUrl() {
    // Lecture clipboard pour android
    if (isAndroidDevice) {
      const { type, value } = await ClipboardAndroid.Clipboard.read();
  
      if (httpRegex.test(value) === true) {
        // sauvegarder l'url valide
        setUrlPath(value);
      } else {
        presentAlert({
          header: 'Message',
          subHeader: '',
          message: 'URL format invalid',
          buttons: ['OK'],
        });
      }
    } else {
    // Lecture clipboard pour pc
    // const text = await navigator.clipboard.readText();
      try {
        navigator.clipboard.readText().then((value) => {
          if (httpRegex.test(value) === true) {
            // sauvegarder l'url valide
            setUrlPath(value);
          } else {
            presentAlert({
              header: 'Message',
              subHeader: '',
              message: 'URL format invalid',
              buttons: ['OK'],
            });
          }
        });
      } catch (error) {
        presentAlert({
          header: 'Message',
          subHeader: '',
          message: 'Clipboard error, web browser not compatible. You need to set "dom.events.asyncClipboard.readText" to "true" in your config.',
          buttons: ['OK'],
        });
        console.log('Clipboard error', error);
      }
    }
  }

  /**
   * Chargement de la page suivante
   * @param ev 
   */
  const loadNextPage = (ev: any) => {

    if (!endOfItems) {
      setTimeout(() => {

        userService.resetPlatformCalled();

        getPlatformData();
        
        if (ev) ev.target.complete();

        // Si 1000 contenus chargés on bloque l'infinite scroll
        // if (data.length === 1000) {
        //   setInfiniteDisabled(true);
        // }
      }, 500);
    }
  }

  /**
   * Rafraichir la liste des posts
   * @param event 
   */
  function handleRefresh(event?: CustomEvent<any>) {
    
    setEndOfItems(false);
    
    setTimeout(() => {
    
      userService.resetIsPlatformPage();

      // Reset des données chargées
      userService.clearPlatformData();
      userService.resetPlatformCalled();

      // Rechargement des données
      getPlatformData();

      if (event && event.detail) {
        event.detail.complete();
      }
    }, 2000);
  }

  /**
   * Générer l'image dpuis l'url
   * @param urlValue 
   */
  async function generateThumb(urlValue: string) {

    if (urlValue) {

      setNebulaCheckIfReady();
      setIsLoadingUserdata(true);
      setImageSourceCustom(false);
  
      // Validate URL
      const posturl = encodeURIComponent(urlValue);
  
      const baseUrl = (Config.debugMode === false) ? Config.url : Config.urlDebug;
      const url = baseUrl + 'post_get_thumb?email=' + userService.email + '&security=' + userService.security + '&posturl=' + posturl + '&v=' + Config.version;
  
      const modale = document.getElementById('modale-nebula');
  
      fetch(url).then(
        async (res) => {
          const result = await res.json();
          console.log('result post_get_thumb', result);
          
          // Set preview url et affichage thumbnail
          const random = Math.random() * 100000000000000000;
          const image = result.msg + '?v=' + random;

          setThumbPreview(image);
  
          // setShowThumbPreview(true);
  
          // Stop du loader
          setIsLoadingUserdata(false);

          // setThumbPreview(reader.result.toString());
          setImageSourceCustom(true);
          setImageFileCustom(image);
          setShowThumbEditor(true);

          // Stop du loader
          setIsLoadingUserdata(false);

          // modale.scrollTop.scroll();
        }
      ).catch((_e) => {
        if(_e.status === 403) {
          presentAlert({
            header: 'Message',
            subHeader: '',
            message: '403',
            buttons: ['OK'],
          });
        } else {
          console.log('Error', _e);
        }
      });
    } else {
      presentAlert({
        header: 'Message',
        subHeader: '',
        message: 'Use a valid URL',
        buttons: ['OK'],
      });
    }
  }

  /**
   * Envoie du post au back
   */
  function sendNebula() {

    setIsLoadingUserdata(true);

    if (description && urlPath && tags) {
      const baseUrl = (Config.debugMode === false) ? Config.url : Config.urlDebug;
      const url = baseUrl + 'post_nebula?email=' + userService.email + '&security=' + userService.security + '&v=' + Config.version;
      
      let formData = new FormData();
      formData.append('description', description);
      formData.append('urlPath', urlPath);
      formData.append('tags', tags);

      // Upload de thumbnail custom
      if (imageSourceCustom === true) {
        const BASE64_MARKER = ';base64,';
        const parts = thumbPreview.split(BASE64_MARKER);
        const contentType = parts[0].split(':')[1];
        const raw = window.atob(parts[1]);
        const rawLength = raw.length;
        const uInt8Array = new Uint8Array(rawLength);
    
        for (let i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }
    
        const blobThumb = new Blob([uInt8Array], { type: contentType });

        formData.append('photo', blobThumb, 'thumbnail');
      }

      fetch(url, {
        method: 'POST',
        body: formData
      }).then(
        async (res) => {
          // TODO: traiter le retour et ajouter la nébuleuse à la liste
          // au lieu de faire un refresh
          handleRefresh();
        }
      ).catch((_e) => {
        if(_e.status === 403) {
          presentAlert({
            header: 'Message',
            subHeader: '',
            message: '403',
            buttons: ['OK'],
          });
        } else {
          console.log('Error', _e);
        }
      }).finally(() => {
        setUrlPath('');
        setDescription('');
        setTags('');
        setThumbPreview(emptyThumb);

        openOrCloseItemModal(false, null, 0);
        setShowContentAdding(false);
        setIsLoadingUserdata(false);
      });

    } else {
      presentAlert({
        header: 'Message',
        subHeader: '',
        message: 'Please fill in all the fields (description, url and tags) !',
        buttons: ['OK'],
      });
      // Stop du loader
      setIsLoadingUserdata(false);
    }
  }

  function searchArguments(argument: string) {
    setSearchText(argument);
  }

  /**
   * Ajouter une nébuleuse, afficher le formulaire
   */
  function addContent() {
    if(!isTester) {
      presentAlert({
        header: 'Message',
        subHeader: '',
        message: 'You need to be a Tester to interact with the Platform service.',
        buttons: ['OK'],
      });
    } else {
      setThumbPreview(emptyThumb);
      setShowContentAdding(true);
    }
  }
  
  /**
   * Manager les nébuleuses déjà crées
   */
  function manageContent() {
    if (!isTester) {
      presentAlert({
        header: 'Message',
        subHeader: '',
        message: 'You need to be a Tester to interact with the Platform service.',
        buttons: ['OK'],
      });
    } else {
      presentAlert({
        header: 'Message',
        subHeader: '',
        message: 'Manage content is coming soon',
        buttons: ['OK'],
      });
    }
  }

  /**
   * Ouverture de l'url de l'item sélectionné
   * @param item 
   */
  function openUrlContent(url: string) {
    if (!isTester) {
      presentAlert({
        header: 'Message',
        subHeader: '',
        message: 'You need to be a Tester to interact with the Platform service.',
        buttons: ['OK'],
      });
    } else {
      window.open(url, '_blank')
    }
  }

  /**
   * Recherche de nébuleuse à partir d'un texte
   * @param searchedText Texte recherché
   */
  function searchItem(searchedText: string) {
    if (!isTester) {
      presentAlert({
        header: 'Message',
        subHeader: '',
        message: 'You need to be a Tester to interact with the Platform service.',
        buttons: ['OK'],
      });
    } else {
      presentAlert({
        header: 'Message',
        subHeader: '',
        message: 'Search content text is currently not allowed: ' + searchedText,
        buttons: ['OK'],
      });
    }
  }

  /**
   * Récupération des données du compte utilisateur
   * @param email
   * @param security 
   * @param alerte 
   */
  async function getBalance(email: any, security: any, alerte: boolean) {
    setWebBalance(true);

    if (userService) {
      setIsLoadingUserdata(true);

      const baseUrl = (Config.debugMode === false) ? Config.url : Config.urlDebug;
      const url = baseUrl + 'select?email=' + email + '&security=' + security + '&v=' + Config.version;

      fetch(url).then(
        async (res) => {
          const result = await res.json();
          const balance = (result && result.balance) ? result.balance : 0.00000;
          const access = (result && result.access) ? result.access : 0;
          const donator = (result && result.donator) ? result.donator : 0;
          const country = (result && result.country) ? result.country : null;
          const emailConfirmed = (result && result.email) ? result.email : 0;

          userService.setBalance(balance);
          userService.setAccess(access);
          userService.setDonator(donator);
          userService.setCountry(country);
          userService.setEmailConfirmed(emailConfirmed);
          userService.setTester(access);

          // setIncrementedBalance(balance);
        }
      ).catch((_e) => {
        if(_e.status === 403) {
          presentAlert({
            header: 'Message',
            subHeader: '',
            message: 'Because of more than 6 months of inactivity, your account was disabled. Please contact support on Telegram to get more informations.',
            buttons: ['OK'],
          });
        } else {
          setShowAlertConnexion(true);
        }
        setIsLoadingUserdata(false)
      }).finally(() =>
        setIsLoadingUserdata(false)
      );
    }
  }

  /**
   * Récupérer les données de la plateforme
   */
  async function getPlatformData() {
     
    if (userService && userService.email && userService.security && !userService.platformCalled && !endOfItems) {

      setIsLoadingUserdata(true);

      userService.setPlatformCalled();
      
      const baseUrl = (Config.debugMode === false) ? Config.url : Config.urlDebug;
      const url = baseUrl + 'platform?email=' + userService.email + '&security=' + userService.security + '&page=' + userService.isPlatformPage + '&v=' + Config.version;

      fetch(url).then(
        async (res) => {

          const result = await res.json();
          
          const newData: ItemTxt[] = [];
          const max = data.length + 50;
          const min = max - 10;

          if (result && result.msg && (result.msg.indexOf('Tester') > 0 || result.msg.indexOf('wallet') > 0)) {
            // Blocage need to be a Tester

            userService.setTester(false);
            setIsTester(false);
              
            for (let i = min; i < max; i++) {
              newData.push({
                id: i,
                url: '',
                vote_dn: 0,
                vote_up: 0,
                tags: '',
                creation: '',
                texte: '',
                ad_path: '/assets/ghost/ads1.jpg',
                path: '/assets/ghost/ghost_thumb.jpg',
                email: '',
                out_url: '',
                stars: 0,
              });
            }

            // Ajout des données à l'infinite scroll
            userService.setPlatformData(
              newData
            );

            setData(userService.getPlatformData());

          } else if (result?.total?.nb_id > 0 && result?.rows?.length > 0) {
            // Actif + de 50 NSA et Tester

            userService.setTester(true);
            setIsTester(true);

            // On vide la liste si c'est le 1er chargement
            if (userService.isPlatformPage === 0) {
              userService.clearPlatformData();
              setData(userService.platformData);
            }
  
            // On incrémente la page
            userService.incrementPlatformPage();

            const ads_path_prefix = 'https://nebula-webapp.spl-token.com/assets/ads/';
            const thumb_path_prefix = 'https://nebula-webapp.spl-token.com/assets/thumb/';

            for (let i = 0; i < result.rows.length; i++) {
              if (
                result.rows[i] &&
                (result.rows[i].url && result.rows[i].url !== "'null'" &&
                result.rows[i].tags && result.rows[i].tags !== "'null'" &&
                result.rows[i].email && result.rows[i].email !== "'null'" &&
                result.rows[i].texte && result.rows[i].texte !== "'null'")
              ) {
                newData.push({
                  id: (result.rows[i].id) ? result.rows[i].id : null,
                  url: (result.rows[i].url) ? result.rows[i].url.slice(1, -1) : null,
                  vote_dn: (result.rows[i].vote_dn) ? result.rows[i].vote_dn : 0,
                  vote_up: (result.rows[i].vote_up) ? result.rows[i].vote_up : 0,
                  tags: (result.rows[i].tags) ? result.rows[i].tags.slice(1, -1) : null,
                  creation: (result.rows[i].creation) ? result.rows[i].creation : null,
                  texte: (result.rows[i].texte) ? result.rows[i].texte.slice(1, -1) : null,
                  ad_path: (result.rows[i].ad_path) ? ads_path_prefix + result.rows[i].ad_path : null,
                  path: (result.rows[i].path) ? ((Config.debugMode === false) ? thumb_path_prefix + result.rows[i].path : '/assets/ghost/ghost_thumb.jpg') : null,
                  email: (result.rows[i].email) ? result.rows[i].email : null,
                  out_url: (result.rows[i].out_url) ? result.rows[i].out_url : null,
                  stars: (result.rows[i].stars) ? Number(result.rows[i].stars) : 0
                });
              }
            }
            
            // Ajout des données à l'infinite scroll
            userService.setPlatformData([
              ...userService.getPlatformData(),
              ...newData
            ]);
            
            setData(userService.getPlatformData());
          } else {
            setEndOfItems(true);
          }
        }
      ).catch((_e) => {
        console.log('error getPlatformData', _e);
      }).finally(() =>{
        setIsLoadingUserdata(false);
      });
    }
  }

  /**
   * Récupération des données de commentaire d'une nébuleuse
   * @param item
   */
  async function getCommentsOfItem(item: ItemTxt) {

    if (userService) {
      setIsLoadingUserdata(true);

      const baseUrl = (Config.debugMode === false) ? Config.url : Config.urlDebug;
      const url = baseUrl + 'comment?email=' + userService.email + '&security=' + userService.security + '&id=' + item.id + '&v=' + Config.version;

      fetch(url).then(
        async (res) => {
          const result = await res.json();
          const comments: CommentTxt[] = (result) ? result : [];

          for (let index = 0; index < comments.length; index++) {
            const comment: CommentTxt = comments[index];
            comment.email = comment.email.split('%')[0];
            comment.creation = comment.creation.split('T')[0];
            comment.comment = decodeURIComponent(comment.comment);
          }

          setCommentData(comments);
          updateCommentValueParent(item, comments.length);
        }
      ).catch((_e) => {
        console.log('debug: error comment loading');
      }).finally(() =>
        setIsLoadingUserdata(false)
      );
    }
  }

  /**
   * Vérification de la version de l'application
   * @param email 
   */
  async function checkVersion(email: any) {

    if (email && !versionTested) {
      setVersionTested(true);

      return userService.checkVersion(email)
        .then((result: boolean) => {
          setShowOutdatedVersion(result);
          return result;
        }
      );
    }
  }

  /**
   * Fermeture de l'alerte de connexion
   */
  function closeConnexionAlert() {
    setShowAlertConnexion(false);
    userService.forceCloseApp();
  }

  /**
   * Ferme l'application dépréciée
   */
  function closeOutdatedVersion() {
    setShowOutdatedVersion(false);
    userService.forceCloseApp();
  }

  /**
   * Ouvre la page de mining
   */
  function gotoMining() {
    const page = 'mining';
    const miningTab: HTMLElement | null = document.querySelector('#tab-button-' + page);
    miningTab?.click();
  }

  /**
   * Ouvre la page de presale
   */
  function gotoWallet() {
    const page = 'wallet';
    const presaleTab: HTMLElement | null = document.querySelector('#tab-button-' + page);
    presaleTab?.click();
  }

  /**
   * Ajout d'une photo depuis l'appareil
   * @param fileChangeEvent 
   */
  function browsePhoto(fileChangeEvent: React.ChangeEvent<HTMLInputElement>) {
    // Get a reference to the file that has just been added to the input
    const photo = fileChangeEvent.target.files[0];
    // Create a form data object using the FormData API
    // let formData = new FormData();
    // Add the file that was just added to the form data
    // formData.append('photo', photo, photo.name);
    // POST formData to server using Fetch API
    //console.log('debug: photo', photo);
    
    var reader = new FileReader();
    reader.readAsDataURL(photo);
    reader.onload = function () {
      // console.log('base64 de la photo', reader.result, imageFileCustom);
      // setThumbPreview(reader.result.toString());
      setImageSourceCustom(true);
      setImageFileCustom(photo);
      setShowThumbEditor(true);
    };

    // https://eliteionic.com/tutorials/handling-file-uploads-in-ionic-web/
    // await fetch('http://localhost:3000/upload', {
    //   method: 'POST',
    //   body: formData,
    // });
  }

  const Footer = () => {
    const platformData = userService.getPlatformData();
    
    return endOfItems || (platformData && platformData.length <= 10)
      ? <div
          style={{
            padding: '2rem',
            display: 'flex',
            justifyContent: 'center',
          }}
        ></div>
      : <div
          style={{
            padding: '2rem',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          Loading...
        </div>
  }
  
  const loadMore = useCallback(() => {
    const platformData = userService.getPlatformData();

    if (platformData && platformData.length >= 10 ) {
      return setTimeout(() => {
        loadNextPage(null);
      }, 200)
    } else {
      return null;
    }
    // eslint-disable-next-line
  }, [setData]);
  
  /**
   * Ajouter un sub de la list de subs
   * @param email 
   */
  function addSub(email: string): void {
    userService.getSubsList().then(
      (subList) => {
        const newSub: ItemSub = {
          avatar: '',
          channel: email.split('%40')[0]
        };

        // On recherche si le nom du channel est déjà dans la sub list
        const isInSubList = subList && subList.length > 0 ? subList.find(item => item.channel === newSub.channel) : null;

        // S'il ne l'est pas on l'ajoute
        if (!isInSubList) {
          userService.addSubToList(newSub);
        }
      }
    ).finally(
      () => setChangeButtonToUnsubscribe(true)
    );
  }

  /**
   * Supprimer un sub de la list de subs
   * @param email 
   */
  function removeSub(email: string): void {
    userService.getSubsList().then(
      (subList) => {

        const oldSub: ItemSub = {
          avatar: '',
          channel: email.split('%40')[0]
        };

        // On recherche si le nom du channel est déjà dans la sub list
        const isInSubList = subList && subList.length > 0 ? subList.find(item => item.channel === oldSub.channel) : null;

        // S'il est dans la liste on le supprime
        if (isInSubList) {
          userService.removeSubFromList(oldSub);
        }
      }
    ).finally(
      () => setChangeButtonToUnsubscribe(false)
    );
  }

  /**
   * Formatte la date pour un affichage correct
   * @param date Date en entrée
   * @returns date formattée
   */
  function formatDate(date: string) {
    return new Date(date).toLocaleDateString(
      undefined,
      {
        year: "numeric", 
        month: "long", 
        day: "numeric" 
      }
    );
  }

  /**
   * Met à jour le count des commentaire de la liste parent
   * @param item l'item sélectionné
   * @param length  la taille de la liste de commentaire
   */
  function updateCommentValueParent(item: ItemTxt, length: number) {
    console.log('debug: TODO update comment count parent');
  }

  /**
   * Répondre à un commentaire
   * @param comment lel'index du commentaire commentaire
   * @param index 
   */
  function replyComment(comment: CommentTxt, index: number) {
    const commentNum = index + 1;
    setComment('@' + commentNum + '-' + comment.email + ' ');
    let y = document.getElementById('comment-box').offsetTop;
    let content = document.querySelector('ion-content');
    content.scrollToPoint(0, y);
  }

  /**
   * Vérifie si les données du post sont ok
   */
  function setNebulaCheckIfReady() {
    if (urlPath && 
        urlPath.length > 0 &&
        description &&
        description.length > 0 &&
        tags &&
        tags.length > 0 &&
        (thumbPreview !== emptyThumb)
    ) {
      setNebulaReady(true);
    } else {
      setNebulaReady(false);
    }
  }

  /**
   * Save the cropped thumbnail
   */
  async function saveThumb() {
    if (editor) {
      const dataUrl = editor.current.getImage().toDataURL();
      const result = await fetch(dataUrl);
      const blob = await result.blob();

      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function() {
        setThumbPreview(reader.result.toString());
        
        setShowThumbEditor(false);
      };
    }
  }

  /**
   * Récupération du nom de domaine de l'url
   * @param url 
   * @returns 
   */
  function getShrinkedUrl(url: string) {
    const decodedUrl = decodeURIComponent(url);
    if (decodedUrl && decodedUrl !== null && decodedUrl !== 'null') {
      try {
        const domain = new URL(decodedUrl);
        const hostname = domain.hostname;
        return hostname;
      } catch (error) {
        return decodeURIComponent(url);
      }
    } else {
      return decodeURIComponent(url);
    }
  }

  return (
    <IonPage className="container-platform">
      <IonContent>

        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        {/* Popin de chargement de données */}
        {
          isLoadingUserData &&
          <IonLoading
            cssClass='my-custom-class'
            isOpen={isLoadingUserData}
            message={'Loading'}
            duration={0}
            spinner={'dots'}
          />
        }

        {/* Message d'alerte pour la connexion internet */}
        <IonAlert
          isOpen={showAlertConnection}
          onDidDismiss={() => closeConnexionAlert()}
          cssClass='my-custom-class'
          header={'Alert'}
          subHeader={'Server connexion error'}
          message={'Please check your internet connexion. If your connexion is activated and working, servers can be in maintenance so retry in few hours. Please take information on Telegram channel, thanks.'}
          buttons={['Exit']}
        />
  
        {/* Message d'alerte dans le cas d'une ancienne version */}
        <IonAlert
          isOpen={showOutdatedVersion}
          onDidDismiss={() => closeOutdatedVersion()}
          cssClass='my-custom-class'
          header={'Alert'}
          subHeader={'Your application version is OUTDATED'}
          message={'Please upgrade the application from PlayStore, thanks.'}
          buttons={['Exit']}
        />

        {/* Barre de recherche */}
        <IonGrid className="search-bar">
          <IonRow>
            <IonCol size="2">
              <IonImg className="search-bar-img" src="/assets/icon/new_icon.png" />
            </IonCol>
            <IonCol size="8">
              <IonSearchbar value={searchText} onIonChange={e => searchArguments(e.detail.value!)}></IonSearchbar>
            </IonCol>
            <IonCol size="2">
              <IonButton color="secondary" style={{ height: "52px" }} onClick={() => searchItem(searchText)}><IonIcon icon={enter}></IonIcon></IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>

        {/* Liste des nébuleuses */}
        {isTester && userService.getPlatformData().length > 0 &&
          <Virtuoso
            style={{ height: 'calc(100% - 78px)', marginTop: '78px' }}
            data={userService.getPlatformData()}
            endReached={loadMore}
            overscan={0}
            itemContent={(index, item) => {
              return (
                <IonCard className="item-card" key={'data' + index}>
                  <IonRow className="header">
                    <IonCol size="12">
                      <IonRow className="url" title="Open the url">
                        <IonText className="text" onClick={() => openUrlContent(decodeURIComponent(item.url))}>{getShrinkedUrl(item.url)}</IonText>
                      </IonRow>
                    </IonCol>
                    <IonCol className="picto" size="4" onClick={() => openOrCloseItemModal(true, item, item.id)}>
                      <IonImg className="item-img" src={item.path} />
                    </IonCol>
                    <IonCol size="8">
                      <IonCardContent className="item-content">
                        <IonRow className="description" onClick={() => openOrCloseItemModal(true, item, item.id)}>
                          <IonText className="item-description">{decodeURIComponent(item.texte)}</IonText>
                        </IonRow>
                      </IonCardContent>
                    </IonCol>
                    <IonCol size="12">
                      <IonRow className="options">
                        <IonText className="item-comment" color="primary">
                          <span>
                            <IonChip onClick={() => openOrCloseItemModal(true, item, item.id)}>
                              <IonAvatar>
                                <img src={'https://gravatar.com/avatar/' + (Math.random() * 10000).toFixed(0) + '?d=identicon&f=y'} alt="avatar" />
                              </IonAvatar>
                              <IonLabel color="primary">{(item.email && item.email.length > 0) ? item.email.split('%40')[0] : ''}</IonLabel>
                            </IonChip>
                          </span>
                          <span>
                            <IonChip onClick={() => openOrCloseItemModal(true, item, item.id)}>
                              <IonAvatar className="avatar-number">
                                {item.stars}
                              </IonAvatar>
                              <IonIcon className="icon-dialog" size="large" color="primary" icon={chatboxEllipsesOutline} />
                            </IonChip>
                          </span>
                          <span>
                            <IonChip onClick={() => openOrCloseItemModal(true, item, item.id)}>
                              <IonLabel color="primary">{(item.creation && item.creation.length > 0) ? formatDate(item.creation) : ''}</IonLabel>
                            </IonChip>
                          </span>
                        </IonText>
                      </IonRow>
                    </IonCol>
                  </IonRow>
                </IonCard>
              );
            }}
            components={{ Footer }}
          />
        }
        
        {/* Bouton + en bas à droite */}
        <IonFab horizontal="end" vertical="bottom" slot="fixed">
          <IonFabButton color="primary">
            <IonIcon color="light" icon={add} />
          </IonFabButton>
          <IonFabList side="top">
            <IonFabButton color="light" id="fab-border-add" onClick={() => addContent()}>
              <IonIcon icon={add} />
            </IonFabButton>
            <IonFabButton color="light" onClick={() => manageContent()}>
              <IonIcon icon={settings} />
            </IonFabButton>
          </IonFabList>
        </IonFab>

        {/* Message d'information */}
        { !isTester &&
          <div className="container-platform-common">
            <IonImg style={{ margin: "auto", height: "52px", width: "52px"}} src="/assets/icon/new_icon.png" />
            {/* <IonText>The platform service is available in BETA. You must be a Beta tester with more than 50 NSA to access it. <b>To check your mining, go to Mining page by clicking bottom button.</b><br /><IonButton color="secondary" onClick={() => gotoMining()}>Goto Mining</IonButton><br />To buy NSA before market start, get some SOL, go to the Wallet by clicking bottom button <b>and press BUY.</b><br /><IonButton color="secondary" onClick={() => gotoWallet()}>Goto Wallet</IonButton></IonText> */}
            <IonText>The platform service is available in BETA. You must be a Beta tester with more than 50 NSA to access it. <b>To check your mining, go to Mining page by clicking bottom button.</b><br /><IonButton color="secondary" onClick={() => gotoMining()}>Goto Mining</IonButton></IonText>
          </div>
        }

        {/* Modale de la nébuleuse sélectionnée */}
        <IonModal id="modale" isOpen={isOpen}>
          <IonHeader className="modale-header">
            <IonToolbar>
              <IonTitle>Selected Nebula</IonTitle>
              <IonButtons color="primary" slot="start">
                <IonButton onClick={() => openOrCloseItemModal(false, null, 0)}>
                  <IonIcon color="primary" icon={backspace} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonItem className="platform-modale-content modale-nebula">

            { selectedItem !== null &&
              <IonCard className="platform-modale-card" key={'data selected-item'}>
                <IonRow className="header">
                  <IonCol className="picto" size="12">
                    <IonImg className="item-img" src={selectedItem.path}></IonImg>
                  </IonCol>
                  <IonCol className="picto-text">
                    <IonRow>
                      <IonCol size="12">
                        <IonText className="item-description">{decodeURIComponent(selectedItem.texte)}</IonText>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonButton onClick={() => changeButtonToUnsubscribe ? removeSub(selectedItem.email) : addSub(selectedItem.email)}>{changeButtonToUnsubscribe ? 'Unsubscribe' : 'Subscribe'}</IonButton>
                      <IonText className="item-comment align-comment-number" color="primary">
                        <h3 className="nospace-comments">
                          {commentData && commentData.length > 0 ? commentData.length : 0} <IonIcon icon={chatboxEllipsesOutline} />
                        </h3>
                      </IonText>
                      <h3 className="nospace-comments">
                        <CopyToClipboard text={decodeURIComponent(selectedItem.url)}>
                          <IonButton color="secondary" onClick={
                              () => presentAlert({
                                  header: 'Message',
                                  subHeader: '',
                                  message: 'URL saved in clipboard.',
                                  buttons: ['OK'],
                                })
                              }>
                            <IonIcon icon={shareSocialSharp} />
                          </IonButton>
                        </CopyToClipboard>
                      </h3>
                      <h3 className="nospace-comments">
                        <IonButton color="secondary" onClick={
                          () => presentAlert({
                              header: 'Message',
                              subHeader: '',
                              message: 'Reporting is coming soon',
                              buttons: ['OK'],
                            })
                          }>
                          <IonIcon icon={alertCircle} />
                        </IonButton>
                      </h3>
                      <h3 className="nospace-comments"><IonButton color="secondary" onClick={
                        () => presentAlert({
                          header: 'Message',
                          subHeader: '',
                          message: 'Vote Up is coming soon',
                          buttons: ['OK'],
                        })
                        }>{selectedItem.vote_up} <IonIcon icon={thumbsUp} /></IonButton></h3>
                      <h3 className="nospace-comments"><IonButton color="secondary" onClick={
                        () => presentAlert({
                          header: 'Message',
                          subHeader: '',
                          message: 'Vote Down is coming soon',
                          buttons: ['OK'],
                        })
                        }>{selectedItem.vote_dn} <IonIcon icon={thumbsDown} /></IonButton></h3>
                    </IonRow>
                    <IonRow onClick={() => openUrlContent(decodeURIComponent(selectedItem.url))}>
                      <IonCol size="12">
                        <IonText className="item-comment" color="primary">{decodeURIComponent(selectedItem.url)}</IonText>
                      </IonCol>
                    </IonRow>
                    <IonRow >
                      <IonCol size="12">
                        <IonText className="item-comment" color="primary">Date: {selectedItem.creation.replace('T', ' at ').split('.')[0]}</IonText>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>

                <IonCardContent id="comment-box">
                  <IonRow>
                    <IonCol>
                      <IonImg className="ad-img blurred" src={selectedItem.ad_path}></IonImg>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="12">
                      <IonText>Adding stars to the content stack the token</IonText>
                    </IonCol>
                  </IonRow>
                  <IonRow className="comment-block">
                    <IonRow className="comment-block-input">
                      <IonTextarea minlength={3} maxlength={350} value={comment} placeholder="Enter your comment" onIonChange={e => setComment(e.detail.value!)}></IonTextarea>
                    </IonRow>
                    {countComment} / 350 (min: 3, max: 350)
                    <IonRow className="comment-block-button">
                      <IonCol size="12" class="ion-text-center">
                        <IonButton onClick={() => sendComment(comment, selectedItem) }><IonIcon icon={checkmarkDone} slot="start" />Stack a star</IonButton>
                      </IonCol>
                    </IonRow>
                  </IonRow>

                  {/* <IonRow>
                    <IonCol>
                      <IonTitle className="modale-comment-title">
                        {selectedItem.email.split('%40')[0]} ad selected
                      </IonTitle>
                    </IonCol>
                  </IonRow> */}
                  <IonRow>
                    <IonCol>
                      {/* <IonTitle className="modale-comment-title">
                        {selectedItem.email.split('%40')[0]} Nebula comments
                      </IonTitle> */}
                      <IonTitle className="modale-comment-title">
                        Comments
                      </IonTitle>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      {
                        commentData &&
                        commentData.length === 0 &&
                        <IonText>There is no stars on this topic.</IonText>
                      }
                      {commentData.map((comment, index) => {
                        return (
                          <div key={'comment-star' + index}>
                            <IonGrid className="star-comment">
                              <IonRow>
                                <IonCol size="auto">
                                  <IonAvatar className="avatar-comment">
                                    <img src={'https://gravatar.com/avatar/' + (Math.random() * 10000).toFixed(0) + '?d=identicon&f=y'} alt="avatar" />
                                  </IonAvatar>
                                </IonCol>                          
                                <IonCol size="10">
                                  <IonRow className="header-comment">
                                    <IonCol size="auto">
                                      <IonText>{comment.creation}</IonText>
                                    </IonCol>
                                    <IonCol>
                                      <IonText>{comment.email}</IonText>
                                    </IonCol>
                                  </IonRow>
                                  <IonRow className="body-comment">
                                    <IonCol>
                                      <IonText className="item-text-stars">{comment.comment}</IonText>
                                    </IonCol>
                                  </IonRow>
                                </IonCol>
                              </IonRow>
                              <IonRow>
                                <IonCol size="12">
                                  <IonButton onClick={() => replyComment(comment, index) }>Reply</IonButton>
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          </div>
                        )
                      })}
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
            }
          </IonItem>
        </IonModal>
      </IonContent>

      {/* <ModaleSelectedItem isOpen={isOpen} userService={userService} isAndroidDevice={isAndroidDevice}></ModaleSelectedItem> */}

      {/* Modale ajout de nébuleuse */}
      <IonModal id="modale-add-content" isOpen={showContentAdding}>
        <IonHeader className="modale-header">
          <IonToolbar>
            <IonTitle>Add a Nebula</IonTitle>
            <IonButtons color="primary" slot="start">
              <IonButton onClick={() => {
                openOrCloseItemModal(false, null, 0);
                setShowContentAdding(false);
              }}>
                <IonIcon color="primary" icon={backspace} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonItem id="modale-nebula" className="platform-modale-content modale-nebula platform-modale-adding">

          <IonCard className="platform-modale-card" key={'data selected-item'}>
            <IonRow className="header">
              <IonCol className="picto title" size="12">
                <h3>Fill in all the fields to create your <b>Nebula</b></h3>
              </IonCol>
              {/* <IonCol size="12">
                <IonText><u><b>Adding content will remunerate you :</b></u></IonText>
              </IonCol>
              <IonCol size="12">
                <IonText>- if users send stars on your content</IonText>
              </IonCol>
              <IonCol size="12">
                <IonText>- if advertiser select your tags</IonText>
              </IonCol>
              <IonCol size="12">
                <IonText>- ... [REMUNERATION IS COMING SOON]</IonText>
              </IonCol> */}
            </IonRow>

            <IonCardContent>

              <IonRow className="comment-block">
                <p className="comment-thumb">1 - Paste your URL</p>
                <IonRow className="comment-block-button">
                  <IonCol size="12" class="ion-text-center">
                    <IonButton onClick={() => pasteClipboardUrl() }><IonIcon icon={copy} slot="start" />Paste URL</IonButton>
                  </IonCol>
                </IonRow>
                <IonRow className="comment-block-input">
                  <IonCol size="12">
                    <IonTextarea value={urlPath} minlength={5} maxlength={300} placeholder="Enter the url you want to post" onIonChange={e => setUrlPath(e.detail.value!)}></IonTextarea>
                  </IonCol>
                </IonRow>
              </IonRow>

              <IonRow className="comment-block">
                <p className="comment-thumb">2 - Add a description</p>
                <IonRow className="comment-block-input">
                  <IonCol size="12">
                    <IonTextarea minlength={50} maxlength={300} value={description} placeholder="Enter the description of the post" onIonChange={e => filterDescription(e)}></IonTextarea>
                  </IonCol>
                </IonRow>
                {countDescr} / 300
              </IonRow>

              <IonRow className="comment-block">
                <p className="comment-thumb">3 - Add tags</p>
                <IonRow className="comment-block-input">
                  <IonCol size="12">
                    <IonTextarea value={tags} minlength={5} maxlength={100} placeholder="Enter the tags about your post" onIonChange={e => setTags(e.detail.value!)}></IonTextarea>
                  </IonCol>
                </IonRow>
              </IonRow>

              {/* { showThumbPreview &&  */}
                <IonRow className="comment-block">
                  <p className="comment-thumb">4 - Add a picture</p>
                  <IonRow className="comment-block-button">
                    <IonCol size="6" class="ion-text-center">
                      <IonButton onClick={() => generateThumb(urlPath) }><IonIcon icon={checkbox} slot="start" />Generate</IonButton>
                    </IonCol>
                    <IonCol size="6" class="ion-text-center">
                      <label className="custom-file-upload">
                        <IonIcon className="custom-file-icon" icon={folderOpen} slot="start" /><span className="custom-file-libelle">Browse</span>
                        <input className="browse-file" type="file" accept=".png,.jpg,.jpeg"
                          onChange={(ev) => {
                            browsePhoto(ev);
                            // Met la valeur à null dans le cas où l'on resélectionne le même fichier
                            ev.target.value = null;
                          }} />
                      </label>
                    </IonCol>
                    <IonCol size="12" class="ion-text-center">
                      { showThumbEditor &&
                        <div>
                          <IonRow>
                            <IonGrid>
                              <IonRange onIonChange={(ev: IonRangeCustomEvent<RangeChangeEventDetail>) => setThumbScale(ev.target.value)}>
                                <IonIcon slot="start" icon={expandOutline}/>
                              </IonRange>
                            </IonGrid>
                            <IonGrid className="align-flex">
                              <IonButton onClick={async () => saveThumb()}>Apply changes</IonButton>
                            </IonGrid>
                          </IonRow>
                          <AvatarEditor
                            ref={editor}
                            image={imageFileCustom}
                            width={cropWidth}
                            height={cropHeight}
                            border={15}
                            scale={1 + (Number(thumbScale) / 100)}
                            crossOrigin="anonymous"
                          />
                        </div>
                      }
                      { !showThumbEditor &&
                        <IonImg cache-view="false" className="thumb" src={thumbPreview}></IonImg>
                      }
                    </IonCol>
                  </IonRow>
                </IonRow>
              {/* } */}

              <IonRow className="comment-block">
                {/* <IonRow className="comment-block-button">
                  <IonCol size="12" class="ion-text-center">
                    <IonButton disabled={!nebulaReady} onClick={() => sendNebula() }><IonIcon icon={checkmarkDone} slot="start" /><b>Add the Nebula</b></IonButton>
                  </IonCol>
                </IonRow> */}
              </IonRow>

            </IonCardContent>
          </IonCard>

        </IonItem>
        <IonFooter className="modale-footer">
          <IonRow className="comment-block">
            <IonRow className="comment-block-button">
              <IonCol size="12" class="ion-text-center">
                <IonButton disabled={!nebulaReady} onClick={() => sendNebula() }><IonIcon icon={checkmarkDone} slot="start" /><b>Add the Nebula</b></IonButton>
              </IonCol>
            </IonRow>
          </IonRow>
        </IonFooter>
      </IonModal>

    </IonPage>
  );
};

export default Platform;