import { IonContent, IonItem, IonLabel, IonPage, IonList, IonButton, IonIcon, IonSelect, IonSelectOption, IonInput, IonLoading, IonAvatar, IonChip, IonRow, IonCol, useIonViewDidEnter, useIonViewWillLeave, IonSegment, IonSegmentButton, useIonAlert } from '@ionic/react';
import { createOutline, home, refresh, star, mail, warning, exit, browsersOutline, bagOutline, personOutline, appsOutline, keyOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import './Account.css';
import User from '../../services/user';
import Config from '../../config/settings';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Accounts from '../../config/wallets';
import { Subscription } from 'rxjs';
import { Shared } from '../../shared/shared';

const Account: React.FC<{ shared?: Shared, userService?: User, setUserLogOut?: any }> = ({ shared, userService, setUserLogOut }) => {

  const [currency, setCurrency] = useState<string>();
  const [coinAPI, setCoinAPI] = useState<string>();
  const [emailConfirmed, setEmailConfirmed] = useState<string>();
  const [text, setText] = useState<string>();
  const [platformCode, setBetaCode] = useState<string>('');
  const [marketCode, setMarketCode] = useState<string>('');
  const [textVerif, setTextVerif] = useState<string>();
  const [isLoadingAccount = false, setIsLoadingAccount] = useState<boolean>();
  const [isTester, setTester] = useState<string>();
  const [waitConfirmMail = false, setWaitConfirmMail] = useState<boolean>();
  const [presentAlert] = useIonAlert();

  let userServiceSubscribe: Subscription;

  useState(() => {});

  useEffect(() => {});

  useIonViewDidEnter(async () => {
    
    // Attente service dispo
    userServiceSubscribe = userService.storageInitSubject.subscribe((result) => {
      if (result === true) {
        setCurrency(userService.currency);
        setTester(userService.tester);
        setEmailConfirmed(userService.emailConfirmed);
        setCoinAPI(userService.coinapi);
      }
    });
  });

  useIonViewWillLeave(() => {
    setCurrency(undefined);
    setTester(undefined);
    setEmailConfirmed(undefined);
    if (userServiceSubscribe) {
      userServiceSubscribe.unsubscribe();
    }
  });

  async function logout(only_close: boolean) {

    setUserLogOut();

    if (!only_close) {
      await userService.clearAllData();
      setUserLogOut();
    }

    window.close();

    userService.forceCloseApp();
  }

  function exploreAddress() {
    window.open(`https://explorer.solana.com/address/${userService.pubkey}?cluster=testnet`);
  }

  function editPassword() {
    if (text && textVerif && text === textVerif) {
      const baseUrl = (Config.debugMode === false) ? Config.url : Config.urlDebug;
      const url = baseUrl + 'majpass?email=' + userService.email + '&security=' + userService.security + '&majpass=' + text + '&v=' + Config.version;

      fetch(url).then(async (res) => {
        const result: any = await res.json();

        if (result && result.msg && (result.msg.indexOf('Invalid') > -1)) {
          presentAlert({
            header: 'Message',
            subHeader: '',
            message: 'Account not valid.',
            buttons: ['OK'],
          });
        } else if (result && result.msg && (result.msg.indexOf('activation') > -1)) {
          presentAlert({
            header: 'Message',
            subHeader: '',
            message: `Password waiting activation is "${text}", please confirm the change by clicking the link send by mail to the address: ${userService.email}, DO NOT MISS TO CLICK THE LINK IN THIS EMAIL !`,
            buttons: ['OK'],
          });
          logout(false);
        }
      })
      .catch((_e) => {
        presentAlert({
        header: 'Message',
        subHeader: '',
        message: 'Please connect your device to Internet. If you are already connected, servers may be temporarily down or application need to be updated.' + _e,
        buttons: ['OK'],
      });
      });

    } else if ((!text && !textVerif )|| (!text && textVerif) || (text && !textVerif)) {
      presentAlert({
        header: 'Message',
        subHeader: '',
        message: 'Password empty, please retry',
        buttons: ['OK'],
      });
    } else {
      presentAlert({
        header: 'Message',
        subHeader: '',
        message: 'Password are different, please retry',
        buttons: ['OK'],
      });
    }
  }

  function confirmEmail() {
    const baseUrl = (Config.debugMode === false) ? Config.url : Config.urlDebug;
    const url= baseUrl + 'confirmemail?email=' + userService.email + '&security=' + userService.security + '&v=' + Config.version;

    fetch(url).then(async (res) => {
      const result: any = await res.json();

      setWaitConfirmMail(true);

      if (result && result.msg && (result.msg.indexOf('Invalid') > -1)) {
        presentAlert({
          header: 'Message',
          subHeader: '',
          message: 'Account not valid.',
          buttons: ['OK'],
        });        
      } else if (result && result.msg && (result.msg.indexOf('send') > -1)) {
        presentAlert({
          header: 'Message',
          subHeader: '',
          message: `An email was sent to the address ${userService.email}, follow email instruction to do the confirmation and RESTART THE APP.\n`,
          buttons: ['OK'],
        });        
      }
    })
    .catch((_e) => {
      presentAlert({
          header: 'Message',
          subHeader: '',
          message: 'Please connect your device to Internet. If you are already connected, servers may be temporarily down or application need to be updated.' + _e,
          buttons: ['OK'],
        });      
    });
  }

  function confirmTester() {
    setIsLoadingAccount(true);
    
    setTimeout(() => {
      presentAlert({
        header: 'Message',
        subHeader: '',
        message: 'Beta platform access code is invalid.',
        buttons: ['OK'],
      });
      setIsLoadingAccount(false);
    }, 500);
  }

  function confirmMarketTester() {

    if (userService && marketCode.length > 0) {
      setIsLoadingAccount(true);

      const baseUrl = (Config.debugMode === false) ? Config.url : Config.urlDebug;
      const url = baseUrl + 'market_code?email=' + userService.email + '&security=' + userService.security + '&code=' + marketCode + '&v=' + Config.version;

      fetch(url).then(
        async (res) => {
          const result = await res.json();

          if (result && result.msg) {

            presentAlert({
              header: 'Message',
              subHeader: '',
              message: result.msg,
              buttons: ['OK'],
            });

            if (result.msg.indexOf('validated') > 0) {
              userService.resetMarketCalled();
            }

          } else {
            presentAlert({
              header: 'Message',
              subHeader: '',
              message: 'Beta market service error.',
              buttons: ['OK'],
            });
          }
        }
      ).catch((_e) => {
        presentAlert({
          header: 'Message',
          subHeader: '',
          message: 'Please connect your device to Internet. If you are already connected, servers may be temporarily down or application need to be updated. Join Telegram for more informations.',
          buttons: ['OK'],
        });
      }).finally(() =>
        setIsLoadingAccount(false)
      );
    }
  }

  async function changeCurrency(currency: any) {
    await userService.setCurrency(currency);
    setCurrency(currency);
  }

  async function changeCoinAPI() {
    await userService.setCoinAPI(coinAPI);
    setCoinAPI(coinAPI);
    presentAlert({
      header: 'Message',
      subHeader: '',
      message: 'API key saved',
      buttons: ['OK'],
    });
  }

  function exploreNSAToken() {
    window.open(`https://explorer.solana.com/address/${Config.tokenAddress}?cluster=mainnet`);
  }

  function exploreNSANToken() {
    window.open(`https://explorer.solana.com/address/${Config.tokenNSANAddress}?cluster=mainnet`);
  }

  function showWhitePaper() {
    window.open(`https://nebula-stars-anthill.gitbook.io/white-paper-en/`);
  }

  function showPrivacyPolicy() {
    window.open(`https://nebula.spl-token.com/privacy_policy.html`);
  }

  function showWebapp() {
    window.open(`https://nebula-webapp.spl-token.com`);
  }

  function refreshAccount() {
    
    if (userService) {
      setIsLoadingAccount(true);

      const baseUrl = (Config.debugMode === false) ? Config.url : Config.urlDebug;
      const url = baseUrl + 'select?email=' + userService.email + '&security=' + userService.security + '&v=' + Config.version;

      fetch(url).then(
        async (res) => {
          const result = await res.json();

          const balance = (result && result.balance) ? result.balance : 0.00000;
          const access = (result && result.access) ? result.access : '0';
          const donator = (result && result.donator) ? result.donator : 0;
          const country = (result && result.country) ? result.country : null;
          const emailConfirmed = (result && result.email) ? result.email : 0;

          await userService.setBalance(balance);
          await userService.setAccess(access);
          await userService.setDonator(donator);
          await userService.setCountry(country);
          await userService.setEmailConfirmed(emailConfirmed);
          await userService.setTester(access);

          // setIncrementedBalance(balance);
        }
      ).catch((_e) => {
        if(_e.status === 403) {
          presentAlert({
            header: 'Message',
            subHeader: '',
            message: 'Because of more than 6 months of inactivity, your account was disabled. Please contact support on Telegram to get more informations.',
            buttons: ['OK'],
          });
        } else {
          presentAlert({
            header: 'Message',
            subHeader: '',
            message: 'Please connect your device to Internet. If you are already connected, servers may be temporarily down or application need to be updated. Join Telegram for more informations.',
            buttons: ['OK'],
          });
        }
      }).finally(() =>
        setIsLoadingAccount(false)
      );
    }
  }

  return (
    <IonPage>
      <IonContent fullscreen className="container-account">

        {
          isLoadingAccount &&
          <IonLoading
            cssClass='my-custom-class'
            isOpen={isLoadingAccount}
            message={'Loading'}
            duration={0}
            spinner={'dots'}
          />
        }

        <IonItem color="secondary">
          <IonLabel>
            Settings - Nebula Stars Anthill - v{Config.version}
          </IonLabel>
        </IonItem>

        <IonList>
          <IonRow>
            <IonCol size="5">
              <IonChip>
                <IonAvatar>
                  <img src={userService.avatar ? userService.avatar : "https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y"} alt="avatar" />
                </IonAvatar>
                <IonLabel>{userService.username}</IonLabel>
                <IonIcon color="primary" onClick={() => alert('Avatar edition is not available.')} icon={createOutline} />
              </IonChip>
            </IonCol>
            <IonCol size="7" className="vertical-align">
              <IonLabel className="ion-text-wrap">
                {/* User email */}
                <p><IonIcon icon={mail} slot="end" /> {userService.email} <IonIcon onClick={() => refreshAccount()} className="icon-icon-list" icon={refresh} /></p>
                {
                  emailConfirmed && emailConfirmed !== '1' && <p>[Mail not confirmed]. Please use the button bellow.</p>
                }
                {
                  emailConfirmed && emailConfirmed !== '1' && <IonButton fill="outline" disabled={waitConfirmMail} onClick={() => confirmEmail()}>Confirm my email</IonButton>
                }
              </IonLabel>
            </IonCol>
          </IonRow>

          <IonItem>
            <IonLabel className="ion-text-wrap security-information">
              Please, do not disclose your security code, email, seed key or any security informations of your account to anyone. Admins will never ask you for security data except your mail by private message from the official support account in Telegram. If a bot or anyone ask for your Seed Key, it's not legit.
            </IonLabel>
          </IonItem>
        </IonList>

        <br />

        <IonItem>
          <IonLabel>
            <h2><IonIcon icon={personOutline} /> User status:</h2>
            <p>{(userService.donator === '0') ? 'Registered - not donator' : 'Registered - i\'m a donator'}</p>
          </IonLabel>
        </IonItem>

        <br />

        <IonItem>
          <IonLabel>
            <h2><IonIcon icon={appsOutline} /> App Wallet status:</h2>
            <p>Connected and working address:</p>
            <IonLabel className="ion-text-wrap"><h3>{userService.pubkey}</h3></IonLabel>
            <p>Associated seedkey:</p>
            <IonLabel className="ion-text-wrap"><h3>{userService.seedkey}</h3></IonLabel>
          </IonLabel>
          <IonButton fill="outline" slot="end" onClick={() => exploreAddress()}>Show</IonButton>
        </IonItem>
        
        <br />

        <IonItem detail={false}>
          <IonLabel>
            {/* Beta tester */}
            <h2>Stacking period:</h2>
            <p className="ion-text-wrap">Please choose a stacking period.</p>
            <p className="ion-text-wrap">
              <IonIcon color="primary" icon={warning} />
              The service is currently not available</p>
            <p className="ion-text-wrap">Your stacking period of {'3'} months has started since {'NOT AVAILABLE'}</p>
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonSegment disabled={true} value="3">
            <IonSegmentButton value="3">
              <IonLabel title="3 months (2%)">3 months</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="6">
              <IonLabel title="6 months (5%)">6 months</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="12">
              <IonLabel title="12 months (11%)">12 months</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonItem>

        <br />

        <IonItem detail={false}>
          <IonLabel>
            {/* Beta platform tester */}
            <h2><IonIcon icon={browsersOutline} /> Beta platform status:</h2>
            <p>{isTester && isTester === '1' ? 'i\'m in Beta platform test' : 'i\'m not in Beta platform test'}</p>
            <p className="ion-text-wrap">
              {
                isTester && isTester === '1' ? <></>
                  : 'Beta platform is available only if you have entered a valid beta platform code'
              }
            </p>
            <p className="ion-text-wrap">Please enter your beta platform code</p>
          </IonLabel>
          <IonItem>
            <IonButton fill="outline" onClick={() => confirmTester()}>Activate code</IonButton>
          </IonItem>
        </IonItem>
        <IonItem>
          <IonLabel position="floating">Click to enter beta platform code</IonLabel>
          <IonInput minlength={4} maxlength={18} type="password" pattern="([a-zA-Z][0-9])" onIonChange={platformCode => setBetaCode(platformCode.detail.value)}></IonInput>
        </IonItem>

        <br />

        <IonItem detail={false}>
          <IonLabel>
            {/* Beta market tester */}
            <h2><IonIcon icon={bagOutline} /> Beta market status:</h2>
            <p className="ion-text-wrap">Please enter your beta market code</p>
          </IonLabel>
          <IonItem>
            <IonButton fill="outline" onClick={() => confirmMarketTester()}>Activate code</IonButton>
          </IonItem>
        </IonItem>
        <IonItem>
          <IonLabel position="floating">Click to enter beta market code</IonLabel>
          <IonInput minlength={4} maxlength={18} type="password" pattern="([a-zA-Z][0-9])" onIonChange={marketCode => setMarketCode(marketCode.detail.value)}></IonInput>
        </IonItem>
        
        <br />

        <IonItem detail={false}>
          <IonLabel>
            {/* Beta tester */}
            <h2><IonIcon icon={keyOutline} />  CoinAPI key:</h2>
            <p className="ion-text-wrap">Please enter your CoinAPI key to enable market charts.</p>
          </IonLabel>
          <IonItem>
            <IonButton fill="outline" onClick={() => changeCoinAPI()}>Save API key</IonButton>
          </IonItem>
        </IonItem>
        <IonItem>
          <IonLabel position="floating">Click to enter your CoinAPI key</IonLabel>
          <IonInput minlength={20} maxlength={40} type="text" pattern="([a-zA-Z][0-9][-])" value={coinAPI} onIonChange={apiKey => setCoinAPI(apiKey.detail.value)}></IonInput>
        </IonItem>

        <br />

        <IonItem detail={false}>
          <IonLabel>
            <h2>Edit password:</h2>
            <p className="ion-text-wrap">[a-Z][0-9] limit 4 to 20 char.</p>
            <p className="ion-text-wrap">Please enter your new password in both cases</p>
          </IonLabel>
          <IonItem>
            <IonButton fill="outline" onClick={() => editPassword()}>Save change</IonButton>
          </IonItem>
        </IonItem>
        <IonItem>
          <IonLabel position="floating">Click to enter new password</IonLabel>
          <IonInput minlength={4} maxlength={20} type="password" pattern="([a-zA-Z][0-9])" onIonChange={eText => setText(eText.detail.value)}></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position="floating">Click to repeat new password</IonLabel>
          <IonInput minlength={4} maxlength={20} type="password" pattern="([a-zA-Z][0-9])" onIonChange={eTextVerif => setTextVerif(eTextVerif.detail.value)}></IonInput>
        </IonItem>

        <br />

        <IonItem>
          <IonLabel position="floating">Actual currency is {currency}</IonLabel>
          <IonSelect defaultValue="usd" onIonChange={(e) => { changeCurrency(e.detail.value) }}>
            <IonSelectOption value="usd">USD</IonSelectOption>
            <IonSelectOption value="eur">EUR</IonSelectOption>
            <IonSelectOption value="eur">CNY</IonSelectOption>
            <IonSelectOption value="eur">INR</IonSelectOption>
          </IonSelect>
        </IonItem>

        <br />

        { (userService.email && userService.email.indexOf('ogle.bot') === -1) &&
          <IonRow>
            <IonItem>
              <IonLabel className="ion-text-wrap">
                If you want to accelerate your mining by X4, help the project development and become a donator, you can donate to the next addresses, minimum donation must be equal to ~10usd with the currency of your choice, thanks a lot. <u>Please contact support after a donation.</u> Your account status will be upgraded to Donator after receiving your transaction id, contact @tofkris in telegram, thanks.
              </IonLabel>
            </IonItem>

            <IonItem>
              <IonLabel className="ion-text-wrap">
                This is the project wallets not yours. If you are looking for your in-app wallet address please go to the wallet page and look on top or press DEPOSIT button.
              </IonLabel>
            </IonItem>
            
            <IonItem className="full-width">
              <IonLabel className="ion-text-wrap">
                <h2>SOL address (Solana network):</h2>
                <p id="sol">{Accounts.sol}</p>
              </IonLabel>
              <CopyToClipboard text={Accounts.sol}>
                <IonButton fill="outline" slot="end" onClick={() => alert(`"${Accounts.sol}" was saved to clipboard.`)}>copy</IonButton>
              </CopyToClipboard>
            </IonItem>
            
            <IonItem className="full-width">
              <IonLabel className="ion-text-wrap">
                <h2>NSA address (Solana network):</h2>
                <p id="nsa">{Accounts.nsa}</p>
              </IonLabel>
              <CopyToClipboard text={Accounts.nsa}>
                <IonButton fill="outline" slot="end" onClick={() => alert(`"${Accounts.nsa}" was saved to clipboard.`)}>copy</IonButton>
              </CopyToClipboard>
            </IonItem>
            
            <IonItem className="full-width">
              <IonLabel className="ion-text-wrap">
                <h2>BTC address (Bitcoin network):</h2>
                <p id="btc">{Accounts.btc}</p>
              </IonLabel>
              <CopyToClipboard text={Accounts.btc}>
                <IonButton fill="outline" slot="end" onClick={() => alert(`"${Accounts.btc}" was saved to clipboard.`)}>copy</IonButton>
              </CopyToClipboard>
            </IonItem>
            
            {/* <IonItem className="full-width">
              <IonLabel className="ion-text-wrap">
                <h2>FTM address (Fantom network):</h2>
                <p id="ftm">{Accounts.ftm}</p>
              </IonLabel>
              <CopyToClipboard text={Accounts.ftm}>
                <IonButton fill="outline" slot="end" onClick={() => alert(`"${Accounts.ftm}" was saved to clipboard.`)}>copy</IonButton>
              </CopyToClipboard>
            </IonItem> */}
          </IonRow>
        }

        <br />

        <IonItem>
          <IonLabel>
            <h2>Project Tokens :</h2>
            <IonButton color="secondary" onClick={() => { exploreNSAToken() }}>NSA</IonButton>
            <IonButton color="secondary" onClick={() => { exploreNSANToken() }}>NSAN</IonButton>
          </IonLabel>
        </IonItem>

        <br />

        <IonItem>
          <IonLabel className="align-middle">
            <h2>Project Documents :</h2>
            <IonButton color="secondary" onClick={() => { showWhitePaper() }}>White Paper</IonButton>
            <IonButton color="secondary" onClick={() => { showPrivacyPolicy() }}>Privacy Policy</IonButton>
          </IonLabel>
        </IonItem>

        <br />

        <IonButton onClick={() => { logout(true); }} className="logout-button" expand="block" color="secondary">Close app<IonIcon icon={exit} slot="end" /></IonButton>

        <br />

        <IonButton onClick={() => { logout(false); }} className="logout-button" expand="block" color="secondary">LOGOUT<IonIcon icon={exit} slot="end" /></IonButton>

        <br />

        <IonItem>
          <IonButton color="secondary" onClick={() => { showWebapp() }} slot="start">
            <IonIcon icon={home} slot="start" />
            Website
          </IonButton>
          <IonLabel>&copy; Nebula Stars Anthill</IonLabel>
          <IonButton color="secondary" onClick={() => window.open('https://nebula-webapp.spl-token.com')} slot="end">
            WebApp
            <IonIcon icon={star} slot="end" />
          </IonButton>
        </IonItem>

      </IonContent>
    </IonPage>
  );
};

export default Account;
