import { IonContent, IonHeader, IonButton, IonIcon, IonItem, IonLabel, IonInput, IonPage, IonTitle, IonToolbar, useIonAlert } from '@ionic/react';
import { lockClosed, person, phonePortrait } from 'ionicons/icons';
import { useState } from 'react';
import { useHistory } from 'react-router';
import Config from '../../config/settings';
import UserService from '../../services/user';
import './Register.css';

const Register: React.FC<{ userService?: UserService }> = ({ userService }) => {

  let [email, setEmail] = useState<string>();
  let [phone, setPhone] = useState<string>();
  let [password, setPassword] = useState<string>();
  const [presentAlert] = useIonAlert();
  // let [code, setCode] = useState<string>();

  const history = useHistory();

  const registerUser = async () => {
    let ready = false;
    console.log('click 1', userService);

    // alert(client_config[1] + 'email: ' + email + ', password: ' + password + ', url: ' + client_config[0]);
    if (!email || email.length === 0 || email.indexOf('@') === -1) {
      presentAlert({
        header: 'Message',
        subHeader: '',
        message: 'Please enter an email.',
        buttons: ['OK'],
      });
      ready = false;
    } else {
      ready = true;
    }

    if (!phone || phone.length === 0) {
      presentAlert({
        header: 'Message',
        subHeader: '',
        message: 'Please enter a phone number.',
        buttons: ['OK'],
      });
      ready = false;
    } else {
      ready = true;
    }

    if (!password || password.length === 0) {
      presentAlert({
        header: 'Message',
        subHeader: '',
        message: 'Please enter a password.',
        buttons: ['OK'],
      });
      ready = false;
    } else {
      ready = true;
    }

    // Données compte utilisateur
    if (ready && userService) {
      register();
    }
  };

  async function register() {
    const baseUrl = (Config.debugMode === false) ? Config.url : Config.urlDebug;
    const url = baseUrl + 'register?email=' + email + '&phone=' + phone + '&pass=' + password + '&v=' + Config.version;

    fetch(url).then(
      async (res) => {
        const result: any = await res.json();

        if (result && (result.msg.indexOf('already') > -1)) {
          presentAlert({
            header: 'Message',
            subHeader: '',
            message: 'Account already exist.',
            buttons: ['OK'],
          });
        } else if (result.msg.indexOf('Nothing') > -1) {
          presentAlert({
            header: 'Message',
            subHeader: '',
            message: 'Server under maintenance, please retry later.',
            buttons: ['OK'],
          });
        } else if (result && result.msg.indexOf('Registered') > -1) {
          alert('Thanks for your registration. Account created with success. Please login with your credentials.');

          // history.push("/login");
          const loginLink = document.getElementById('login-link');
          loginLink.click();
        }
      }
    ).catch((_e) => {
      presentAlert({
        header: 'Message',
        subHeader: '',
        message: 'Please connect your device to Internet. If you are already connected, servers may be temporarily down or application need to be updated.' + _e,
        buttons: ['OK'],
      });
    });

  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle color="primary">Register</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>

        <div className="header-register">
          <h1>NEBULA</h1>
          <h2>Blockchained Discussions in total Freedom</h2>
        </div>

        <div className="container-register">
          <h1>Register</h1>

          <IonItem>
            <IonLabel color="primary">Email</IonLabel>
            <IonInput placeholder="Enter email here" type="email" value={email} maxlength={50} onIonChange={e => setEmail(e.detail.value!)}></IonInput>
            <IonIcon color="primary" icon={person} />
          </IonItem>

          <IonItem>
            <IonLabel color="primary">Phone</IonLabel>
            <IonInput placeholder="Enter phone here" type="text" value={phone} maxlength={20} onIonChange={e => setPhone(e.detail.value!)}></IonInput>
            <IonIcon color="primary" icon={phonePortrait} />
          </IonItem>

          <IonItem>
            <IonLabel color="primary">Password</IonLabel>
            <IonInput placeholder="Enter password here" type="password" value={password} minlength={4} maxlength={20} onIonChange={e => setPassword(e.detail.value!)}></IonInput>
            <IonIcon color="primary" icon={lockClosed} />
          </IonItem>

          {/* <IonItem>
            <IonLabel color="primary">Invitation</IonLabel>
            <IonInput placeholder="Enter code here" type="password" value={code} max="50" onIonChange={e => setCode(e.detail.value!)}></IonInput>
            <IonIcon color="primary" icon={ticket} />
          </IonItem> */}

          <IonButton color="secondary" className="register-button" onClick={() => { registerUser() }}>Register</IonButton>

          <p className="register-login">I already have an account ? <a id="login-link" target="_self" rel="noopener noreferrer" onClick={() => { history.push("/login") }} href="/login">Login</a></p>
        </div>

      </IonContent>
    </IonPage>
  );
};

export default Register;
