import { Connection, Keypair, LAMPORTS_PER_SOL, Transaction } from "@solana/web3.js";
import Config from "../config/settings";
import { Collections } from "../pages/Market/Collections.enum";

export class Shared {

    /**
     * Get enum keys of enum
     * @param obj 
     * @returns 
     */
    public enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
        return Object.keys(obj).filter(k => !Number.isNaN(k)) as K[]
    }

    /**
     * Get collection name
     * @param collection 
     * @returns 
     */
    public getCollectionName(collection: string): Collections {
        let collectionName: Collections;

        for (const tl of this.enumKeys(Collections)) {
            if (collection === tl) {
                collectionName = Collections[tl];
            }
        }

        return collectionName;
    }

    /**
     * Calcul des frais de transfert
     * @param keypair 
     * @returns 
     */
    public async calculateFeeTransfer(keypair: Keypair) {

        const connectionMainnet = new Connection(
            Config.mainnetQuicknode,
            'confirmed',
        );

        let blockhash = null;

        blockhash = (await connectionMainnet.getLatestBlockhash('finalized')).blockhash;
        
        const transaction = new Transaction(/* ... */);
        
        transaction.recentBlockhash = blockhash;
        transaction.feePayer = keypair.publicKey;
        
        let response = null;
        let value: number = null;
        
        response = await connectionMainnet.getFeeForMessage(
        transaction.compileMessage(),
        'confirmed',
        );
        value = response?.value;

        if (!value) {
        value = 5000;
        }

        return value / LAMPORTS_PER_SOL;
    }
}