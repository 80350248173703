import { registerPlugin } from '@capacitor/core';

export interface GetURLBridgePlugin {
    request(options: {
        url: string,
        body: any
    }): Promise<{
        url: string,
        body: any
    }>;
}

const GetURLBridgePlugin = registerPlugin<GetURLBridgePlugin>('GetURLBridgePlugin');

export default GetURLBridgePlugin;