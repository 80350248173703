import { App } from '@capacitor/app';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';
import Config from '../config/settings';
import { ItemNft } from '../models/itemNft';
import { ItemSub } from '../models/itemSub';
import { ItemTxt } from '../models/itemTxt';

class UserService {

    version: string;

    // Platform variables
    platformData: ItemTxt[] = [];
    platformCalled: boolean = false;
    isPlatformPage: number = 0;

    // Subscriptions variables
    subsData: ItemTxt[] = [];
    subsCalled: boolean = false;
    isSubsPage: number = 0;
    itemSubs: ItemSub[] = [];

    // Market variables
    marketData: ItemNft[] = [];
    marketCalled: boolean = false;
    isMarketPage: number = 0;

    // Mining variables
    miningCalled: boolean = false;
    miningGraphData: any[];

    // Storage component
    store: Storage = new Storage();
    storageInitSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
    storageDejaInit: boolean;
    
    avatar: any;
    username: any;
    email: any;
    security: any;
    phone: any;
    pubkey: any;
    seedkey: any;
    balance: any;
    currency: any;
    donator: any;
    tester: any;
    access:any;
    country: any;
    coinapi: any;
    emailConfirmed: any;
    collection: any;

    constructor() {
        this.initStorage();
    }

    async initStorage() {
        if (!this.storageDejaInit) {
            this.storageDejaInit = true;
            await this.store.create().then(
                async (create) => {
                    // TODO: régler le soucis de double loadUserData
                    this.storageDejaInit = true;
                    // Reset du choix de la collection à afficher dans le market NFT
                    await this.setCollection(0);
                    // CHargement des données utilisateur
                    await this.loadUserData();
                }
            );
        }
    }

    async loadUserData() {
        await this.getAvatar();
        await this.getUsername();
        await this.getEmail();
        await this.getSecurity();
        await this.getPhone();
        await this.getPubkey();
        await this.getSeedkey();
        await this.getBalance();
        await this.getCurrency();
        await this.getDonator();
        await this.getTester();
        await this.getAccess();
        await this.getCountry();
        await this.getCoinAPI();
        await this.getEmailConfirmed();
        await this.getCollection();
        await this.getSubsList();

        this.storageInitSubject.next(true);
    }

    async loadKeys(key: any) {
        return this.store?.get(key);
    }

    /**
     * User Data
     */
    async getAvatar() {
        this.avatar = await this.store?.get('avatar');
        return this.avatar;
    }

    async setAvatar(avatar: any) {
        this.avatar = avatar;
        await this.store?.set('avatar', avatar);
    }

    async getUsername() {
        this.username = await this.store?.get('username');
        return this.username;
    }

    async setUsername(username: any) {
        this.username = username;
        await this.store?.set('username', username);
    }

    async getEmail() {
        this.email = await this.store?.get('email');
        return this.email;
    }

    async setEmail(email: any) {
        this.email = email;
        await this.store?.set('email', email);
    }

    async getSecurity() {
        this.security = await this.store?.get('security');
        return this.security;
    }

    async setSecurity(security: any) {
        this.security = security;
        await this.store?.set('security', security);
    }

    async getPhone() {
        this.phone = await this.store?.get('phone');
        return this.phone;
    }

    async setPhone(phone: any) {
        this.phone = phone;
        await this.store?.set('phone', phone);
    }

    async getPubkey() {
        this.pubkey = await this.store?.get('pubkey');
        return this.pubkey;
    }

    async setPubkey(pubkey: any) {
        this.pubkey = pubkey;
        await this.store?.set('pubkey', pubkey);
    }

    async getSeedkey() {
        this.seedkey = await this.store?.get('seedkey');
        return this.seedkey;
    }

    async setSeedkey(seedkey: any) {
        this.seedkey = seedkey;
        await this.store?.set('seedkey', seedkey);
    }

    async getBalance() {
        this.balance = await this.store?.get('balance');
        return this.balance;
    }

    async setBalance(balance: any) {
        this.balance = balance;
        await this.store?.set('balance', balance);
    }

    async getCurrency() {
        this.currency = await this.store?.get('currency');
        return this.currency;
    }

    async setCurrency(currency: any) {
        this.currency = currency;
        await this.store?.set('currency', currency);
    }

    async getDonator() {
        this.donator = await this.store?.get('donator');
        return this.donator;
    }

    async setDonator(donator: any) {
        this.donator = donator;
        await this.store?.set('donator', donator);
    }

    async getTester() {
        this.tester = await this.store?.get('tester');
        return this.tester;
    }

    async setTester(tester: any) {
        this.tester = tester;
        await this.store?.set('tester', tester);
    }

    async getAccess() {
        this.access = await this.store?.get('access');
        return this.access;
    }

    async setAccess(access: any) {
        this.access = access;
        await this.store?.set('access', access);
    }

    async getCountry() {
        this.country = await this.store?.get('country');
        return this.country;
    }

    async setCountry(country: any) {
        this.country = country;
        await this.store?.set('country', country);
    }

    async getCoinAPI() {
        this.coinapi = await this.store?.get('coinapi');
        return this.coinapi;
    }

    async setCoinAPI(coinapi: any) {
        this.coinapi = coinapi;
        await this.store?.set('coinapi', coinapi);
    }

    async getEmailConfirmed() {
        this.emailConfirmed = await this.store?.get('emailConfirmed');
        return this.emailConfirmed;
    }

    async setEmailConfirmed(emailConfirmed: any) {
        this.emailConfirmed = emailConfirmed;
        await this.store?.set('emailConfirmed', emailConfirmed);
    }

    async getCollection() {
        this.collection = await this.store?.get('collection');
        return this.collection;
    }

    async setCollection(collection: any) {
        this.collection = collection;
        await this.store?.set('collection', collection);
    }

    async clearAllData() {
        this.store.clear();
    }

    /**
     * Forçage de la fermeture de l'app
     */
    forceCloseApp() {
        try {
            App.exitApp();
        } catch (error) {
            console.log('Not android device', error);
        }
    }

    /**
     * Plateforme
     */
    setPlatformCalled() {
        this.platformCalled = true;

    }

    resetPlatformCalled() {
        this.platformCalled = false;
    }

    setPlatformData(data: ItemTxt[]) {
        this.platformData = data;
    }

    getPlatformData() {
        return this.platformData;
    }

    clearPlatformData() {
        this.platformData = [];
    }

    incrementPlatformPage() {
        this.isPlatformPage = this.isPlatformPage + 1;
        return this.isPlatformPage;
    }

    resetIsPlatformPage() {
        this.isPlatformPage = 0;
    }

    /**
     * Subscriptions
     */
    setSubsCalled() {
        this.subsCalled = true;

    }

    resetSubsCalled() {
        this.subsCalled = false;
    }

    setSubsData(data: ItemTxt[]) {
        this.subsData = data;
    }

    getSubsData() {
        return this.subsData;
    }

    clearSubsData() {
        this.subsData = [];
    }

    incrementSubsPage() {
        this.isSubsPage = this.isSubsPage + 1;
        return this.isSubsPage;
    }

    resetIsSubsPage() {
        this.isSubsPage = 0;
    }

    async getSubsList() {
        const itemSubsStringified = await this.store?.get('itemSubs');
        this.itemSubs = JSON.parse(itemSubsStringified);
        return this.itemSubs;
    }

    async addSubToList(sub: ItemSub) {
        if (this.itemSubs === null) this.itemSubs = [];

        this.itemSubs.push(sub);
        await this.store?.set('itemSubs', JSON.stringify(this.itemSubs));
    }

    async removeSubFromList(sub: ItemSub) {
        if (this.itemSubs === null) this.itemSubs = [];

        const filteredSubList = this.itemSubs.filter((el) => el.channel !== sub.channel);

        this.itemSubs = filteredSubList;
        await this.store?.set('itemSubs', JSON.stringify(filteredSubList));
    }

    /**
     * Market
     */
    setMarketCalled() {
        this.marketCalled = true;
    }

    resetMarketCalled() {
        this.marketCalled = false;
    }

    setMarketData(data: ItemNft[]) {
        this.marketData = data;
    }

    clearMarketData() {
        this.marketData = [];
    }

    getMarketData() {
        return this.marketData;
    }

    incrementMarketPage() {
        this.isMarketPage = this.isMarketPage + 1;
        return this.isMarketPage;
    }

    resetIsMarketPage() {
        this.isMarketPage = 0;
    }

    /**
     * Mining
     */

    setMiningCalled() {
        this.miningCalled = true;
    }

    setMiningGraphData(data: any[]) {
        this.miningGraphData = data;
    }

    getMiningGraphData() {
        return this.miningGraphData;
    }

    /**
     * Services
     */
    async checkVersion(email: string) {

        if (email) {
            const baseUrl = (Config.debugMode === false) ? Config.url : Config.urlDebug;
            const url = baseUrl + 'version?email=' + email+ '&v=' + Config.version;

            return fetch(url).then(
                async (res) => {
                    
                    const result = await res.json();
                    
                    if (result && (result.msg.indexOf('deprecated') > -1)) {
                        return true;
                    }

                    return false;
                }
            ).catch((_e) => {
                console.log('error:', _e.message);
                return null;
            });
        }
    }
}

export default UserService;