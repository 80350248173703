import { IonLabel, IonCard, IonIcon, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonCardHeader, IonCardSubtitle, IonSpinner, IonLoading, IonProgressBar, IonAlert, useIonViewWillEnter, useIonViewDidLeave, useIonAlert, IonButton, IonCol, IonGrid, IonImg, IonRow, IonText, IonRadio, IonRadioGroup, IonItem, IonList, IonSelect, IonSelectOption } from '@ionic/react';
import { list, newspaper, eye, refresh, paperPlane, planet, cloud, telescope, arrowUp, bagOutline, walletOutline, star, speedometerOutline, gitNetworkOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import Config from '../../config/settings';
import UserService from '../../services/user';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import { IonicSwiper } from '@ionic/react';
import './Mining.css';
import 'swiper/swiper-bundle.min.css';
import '@ionic/react/css/ionic-swiper.css';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import { ItemSlideTxt } from '../../models/itemSlideTxt';
import { ItemSlideImg } from '../../models/itemSlideImg';
import { Subscription } from 'rxjs';
import { Shared } from '../../shared/shared';
import GetURLBridgePlugin from '../../bridges/APIbridges/GetURLBridgePlugin';
import { WalletService } from '../Wallet/Wallet.svc';

const Mining: React.FC<{  shared?: Shared, userService?: UserService, isAndroidDevice: boolean }> = ({ shared, userService, isAndroidDevice }) => {

  SwiperCore.use([IonicSwiper, Autoplay]);

  const maxRemaining = 18327;
  const maxProgressbar = 1;
  const maxTempsIncrement = 33;

  let chart: am4charts.XYChart;

  const [currency, setCurrency] = useState<string>();
  const [incrementedBalance, setIncrementedBalance] = useState<string>();
  const [timeRemaining, setTimeRemaining] = useState<number>(0);
  const [compteur, setCompteur] = useState<number>();
  const [progressBarValue, setProgressBarValue] = useState<number>();
  // const [webBalance = false, setWebBalance] = useState<boolean>();
  const [isLoadingMining = false, setIsLoadingMining] = useState<boolean>();
  const [isCollecting = false, setIsCollecting] = useState<boolean>();
  const [donator, setDonator] = useState<string>('0');
  const [showAlertConnection, setShowAlertConnexion] = useState(false);
  const [showOutdatedVersion, setShowOutdatedVersion] = useState(false);
  // const [coinapi, setCoinAPI] = useState<string>();
  const [presentAlert] = useIonAlert();
  const [abortAlert] = useIonAlert();
  const [balanceAlert] = useIonAlert();
  const [serviceAlert] = useIonAlert();
  const [confirmAlert] = useIonAlert();

  let userServiceSubscribe: Subscription;

  const walletService: WalletService = new WalletService();

  const itemsimg: ItemSlideImg[] = [
    { src: '/assets/img/slide0.png' },
    { src: '/assets/img/slide1.png' },
    { src: '/assets/img/slide2.png' },
    { src: '/assets/img/slide3.png' },
    { src: '/assets/img/slide4.png' },
  ];

  const itemstxt: ItemSlideTxt[] = [
    { text: 'NSA will be available soon on exchanges' },
    { text: 'Get 20% reduction with private sale offer' },
    { text: 'Cloud mining automatic reactivation is enabled' },
    { text: 'Send a donation to accelerate your Mining by x4' },
    { text: 'White Paper is available on GitBook and Telegram' },
    { text: 'Join our Telegram channel to get announces' },
    { text: 'Watch website for latest informations' },
  ];

  useEffect(() => {

    // Boucle toutes les secondes
    if (Number(incrementedBalance) && Number(timeRemaining)) {
      
      setTimeout(() => {
        
        // Incrémentation balance
        if (compteur === 0) {

          if (donator !== '0') {
            setIncrementedBalance((Number(incrementedBalance) + 0.00332).toFixed(5));
          } else {
            setIncrementedBalance((Number(incrementedBalance) + 0.00083).toFixed(5));
          }

          setCompteur(maxTempsIncrement);

          // Incrémentation progressbar
          if (getDays() > -1){
            const remaining = timeRemaining - 1;
            setTimeRemaining(remaining);
            setProgressBarValue(getTimeRemaining(remaining));
          }
        }

        setCompteur(compteur - 1);
      }, 1000);
    }

    // eslint-disable-next-line
  }, [compteur]);
  
  useIonViewWillEnter(() => {

    // Attente du chargement du user service
    userServiceSubscribe = userService.storageInitSubject.subscribe((result) => {
      if (result === true) {

        setCurrency(userService.currency);
        setDonator(userService.donator);
        // setCoinAPI(userService.coinapi);

        if (userService.miningCalled === true) {
          userService.getCoinAPI().then((api: string) => {
            // if (api) {
              const miningGraphData = userService.getMiningGraphData();
              generateGraph(miningGraphData);
            // }
          });
        } else {
          getBalance(false);
          getLastMining(false);
          // getGraphData();
        }
      }
    });
  });

  useIonViewDidLeave(() => {
    chart = null;
    if (userServiceSubscribe) {
      userServiceSubscribe.unsubscribe();
    }
  });

  function closeConnexionAlert() {
    setShowAlertConnexion(false);
    userService.forceCloseApp();
  }

  function closeOutdatedVersion() {
    setShowOutdatedVersion(false);
    userService.forceCloseApp();
  }

  async function getChartData(urlSource: string) {
    const { url, body } = await GetURLBridgePlugin.request({
        url: urlSource,
        body: ''
      }
    );

    return body;
  }

  /**
   * Récupération des données du compte
   * @param email
   * @param security 
   * @param alerte 
   */
  async function getBalance(alerte: boolean) {
    // setWebBalance(true);

    if (userService) {
      setIsLoadingMining(true);

      const baseUrl = (Config.debugMode === false) ? Config.url : Config.urlDebug;
      const url = baseUrl + 'select?email=' + userService.email + '&security=' + userService.security + '&v=' + Config.version;

      fetch(url).then(
        async (res) => {
          const result = await res.json();
          const balance = (result && result.balance) ? result.balance : 0.00000;
          const access = (result && result.access) ? result.access : 0;
          const donator = (result && result.donator) ? result.donator : 0;
          const country = (result && result.country) ? result.country : null;
          const emailConfirmed = (result && result.email) ? result.email : 0;

          userService.setBalance(balance);
          userService.setAccess(access);
          userService.setDonator(donator);
          userService.setCountry(country);
          userService.setEmailConfirmed(emailConfirmed);

          userService.setTester(access);

          setIncrementedBalance(balance);
        }
      ).catch((_e) => {
        if(_e.status === 403) {
          presentAlert({
            header: 'Message',
            subHeader: '',
            message: 'Because of more than 6 months of inactivity, your account was disabled. Please contact support on Telegram to get more informations.',
            buttons: ['OK'],
          });
        } else {
          setShowAlertConnexion(true);
          presentAlert({
            header: 'Message',
            subHeader: '',
            message: 'Please connect your device to Internet. If you are already connected, servers may be temporarily down or application need to be updated. Join Telegram for more informations.',
            buttons: ['OK'],
          });
        }
      }).finally(() =>
        setIsLoadingMining(false)
      );
    }
  }

  /**
   * Solde du compte
   * @param email
   * @param security 
   * @param alerte 
   */
  async function getLastMining(alerte: boolean) {

    if (userService) {
      setIsLoadingMining(true);

      const baseUrl = (Config.debugMode === false) ? Config.url : Config.urlDebug;
      const url = baseUrl + 'userdata?email=' + userService.email + '&security=' + userService.security + '&v=' + Config.version;

      fetch(url).then(
        async (res) => {
          const result = await res.json();

          if (result && result.msg && result.msg === 'deprecated') {
            setShowOutdatedVersion(true);
          } else {
            const resultNumber = Number(result.toString());
  
            setTimeRemaining(resultNumber);
            setProgressBarValue(getTimeRemaining(resultNumber));
          }
        }
      ).catch((_e) => {
        presentAlert({
          header: 'Message',
          subHeader: '',
          message: 'Please connect your device to Internet. If you are already connected, servers may be temporarily down or application need to be updated.',
          buttons: ['OK'],
        });
      }).finally(() =>{
        setIsLoadingMining(false);
        setCompteur(maxTempsIncrement);
      });
    }
  }

  /**
   * Convertion du temps récupéré et valeur de progressbar
   * @param lastMining 
   * @returns 
   */
  function getTimeRemaining(lastMining: number) {
    return maxProgressbar / maxRemaining * lastMining;
  }

  /**
   * Cacher les valeurs
   */
  function hideValue() {
    const compteurValeur = document.getElementById('compteur-valeur');
    if (compteurValeur.classList.contains('compteur-hidden')) {
      compteurValeur.classList.remove('compteur-hidden')
    } else {
      compteurValeur.classList.add('compteur-hidden');
    }
  }

  /**
   * Réactivation du mining
   */
  function reactivateMining() {    
    if (timeRemaining === 0) {
      enableMining();
      getLastMining(false);
      presentAlert({
        header: 'Message',
        subHeader: '',
        message: 'Cloud mining reenabled.',
        buttons: ['OK'],
      });
    } else {
      presentAlert({
        header: 'Message',
        subHeader: '',
        message: 'Cloud mining cannot be reactivated before the end of the period.',
        buttons: ['OK'],
      });
    }
  }

  function getDays() {
    const nbOfDays: number = Number((progressBarValue / (1 / 7)).toFixed(0));
    
    if (timeRemaining === 0) {
      disableMining();
    } else {
      enableMining();
    }

    return nbOfDays;
  }

  function disableMining() {
    const miningButton = document.getElementById('miningButton');
    if (miningButton) {
      miningButton.classList.remove('enabled');
      miningButton.classList.add('disabled');
      miningButton.innerHTML = 'Disabled';
    }

    const miningSpinner = document.getElementById('miningSpinner');
    
    if (miningSpinner) {
      miningSpinner.classList.remove('enabled');
      miningSpinner.classList.add('disabled');
    }
  }

  function enableMining() {
    const miningButton = document.getElementById('miningButton');
    
    if (miningButton) {
      miningButton.classList.remove('disabled');
      miningButton.classList.add('enabled');
      miningButton.innerHTML = 'Enabled';
    }

    const miningSpinner = document.getElementById('miningSpinner');
    
    if (miningSpinner) {
      miningSpinner.classList.remove('disabled');
      miningSpinner.classList.add('enabled');
    }
  }

  /**
   * Génération du graph des courbes
   */
  async function getGraphData(marketId: string = Config.marketIdMsolUsdt, libelle: string = 'mSol') {

    if (!currency) {
      setCurrency('usd');
    }

    // const days = '3';
    // const interval = 'daily';
    const timestamp = new Date().getTime();
    // const marketIdUSDT = Config.marketIdNsaUsdt;
    // const baseURL = encodeURIComponent('https://openserum.io/api/serum/market/' + marketId + '/chart?_=' + timestamp);
    // const openSerumURL = `https://api.allorigins.win/get?url=${baseURL}`;

    const openSerumURL = `https://openserum.io/api/serum/market/${marketId}/chart?_=${timestamp}`;

    let data: any[] = []; 

    if (isAndroidDevice) {
      // Si Android on utilise le bridge java pour récupérer les données sans avoir de soucis de cors
      data = await getChartData(openSerumURL);

      userService.setMiningCalled();
      userService.setMiningGraphData(data);

      generateGraph(data);

    } else {
      fetch(openSerumURL, {
          mode: "cors",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Accept": "application/json",
            "Content-Type": "application/json"
          }
        }
      )
      .then(
        async (res) => {
          data = await res.json();

          userService.setMiningCalled();
          userService.setMiningGraphData(data);
          
          generateGraph(data);
        }
      );
    }

    // fetch(`https://rest.coinapi.io/v1/ohlcv/BITSTAMP_SPOT_SOL_${(currency) ? currency.toUpperCase() : 'USD'}/latest?period_id=${days}DAY&include_empty_items=false`, {
    //       mode: "cors",
    //       headers: {
    //         "Access-Control-Allow-Origin": "*",
    //         "Accept": "application/json",
    //         "Content-Type": "application/json",
    //         "X-CoinAPI-Key": userService.coinapi
    //       }
    //     }
    //   )
    //   .then(
    //     async (res) => {
    //       const prices: any[] = await res.json();

    //       userService.setMiningCalled();
    //       userService.setMiningGraphData(prices);
          
    //       generateGraph(prices);
    //     }
    //   )
    //   .finally(() => ''
    // );

    // fetch(openSerumURL, {
    //       mode: "cors",
    //       headers: {
    //         "Access-Control-Allow-Origin": "*",
    //         "Accept": "application/json",
    //         "Content-Type": "application/json"
    //       }
    //     }
    //   )
    //   .then(
    //     async (res) => {
    //       const prices: any[] = await res.json();

          // userService.setMiningCalled();
          // userService.setMiningGraphData(data);
          
          // generateGraph(data);
    //     }
    // );
  }

  function generateGraph(prices: any[]) {

    am4core.useTheme(am4themes_dark);
    am4core.useTheme(am4themes_animated);

    const noApiKey = document.getElementById('no-api-key');
    if (noApiKey) {
      noApiKey.remove();
    }

    chart = am4core.create(
      "chartdiv",
      am4charts.XYChart,
    );

    chart.paddingRight = 20;
    chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";

    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;

    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.dateX = "date";
    series.dataFields.valueY = "close";
    series.stroke = am4core.color('rgb(133, 231, 133)');
    series.tooltipText = 'Token price';

    let volumeSeries: any = chart.series.push(new am4charts.ColumnSeries());
    volumeSeries.dataFields.dateX = "date";
    volumeSeries.dataFields.valueY = "quantity";
    volumeSeries.legendValueText = "{valueY}";
    volumeSeries.fill = am4core.color('rgb(133, 231, 133)');
    volumeSeries.stroke = am4core.color('rgb(133, 231, 133)');

    chart.cursor = new am4charts.XYCursor();
    // a separate series for scrollbar
    let lineSeries = chart.series.push(new am4charts.LineSeries());
    lineSeries.dataFields.dateX = "date";
    lineSeries.dataFields.valueY = "close";
    lineSeries.defaultState.properties.visible = false;
    lineSeries.hiddenInLegend = true;
    lineSeries.fillOpacity = 0.5;
    lineSeries.strokeOpacity = 0.5;

    let scrollbarX = new am4charts.XYChartScrollbar();
    scrollbarX.series.push(lineSeries);
    chart.scrollbarX = scrollbarX;

    chart.fontSize = 8;

    // Tri par date
    if (prices && prices.length > 0) {
      prices.sort((x, y) => {
        return x['localDateTime'] - y['localDateTime'];
      });
    }

    // Récupération des valeurs max pour calcul de ratio pour le volume
    let valueMax = 0;
    for (let index = 0; index < prices.length; index++) {
      const price = prices[index];
    
      if (price['value'] > valueMax) {
        valueMax = price['value'];
      }
    }

    let volumeMax = 0;
    for (let index = 0; index < prices.length; index++) {
      const volumeValue = prices[index];
      if (volumeValue['total'] > volumeMax) {
        volumeMax = volumeValue['total'];
      }
    }
    let ratio = (volumeMax / valueMax) * 2.5;

    // ajout des données triées
    for (let index = 0; index < prices.length; index++) {
      const price = prices[index];

      const date = new Date(price['localDateTime']);
      let volume = '0';

      for (let index = 0; index < prices.length; index++) {
        const volumeValue = prices[index];
        if (volumeValue['localDateTime'] === price['localDateTime']) {
          // console.log('volume', volumeValue[1]);
          volume = (volumeValue['total'] / ratio).toString();
        }
      }

      let month = date.getMonth() + 1;
      let fullMonth = (month < 10) ? '0' + month : month;
      let day = date.getDate();
      let fullDay = (day < 10) ? '0' + day : day;

      const formattedDate: string = date.getFullYear() + '-' + fullMonth + '-' + fullDay;
      const value: string = price['value'].toFixed(2).toString();

      chart.data.push({
        "date": formattedDate,
        "close": value,
        "quantity": volume
      });
    }
  }
  
  function generateInvite() {
    let length = 8;
    let charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let generatedCode = '';

    for (var i = 0, n = charset.length; i < length; ++i) {
      generatedCode += charset.charAt(Math.floor(Math.random() * n));
    }

    alert('You invite code is : ' + generatedCode);
    alert('Invite code service is currently disabled');
    
    return generatedCode;
  }

  async function collectMining() {
    const currentBalance = await userService.getBalance();

    if (Number(currentBalance) > 1000) {
      presentAlert({
        header: 'Info',
        subHeader: '',
        message: 'You want to collect ' + currentBalance + ' NSA <b>to your wallet</b>, please confirm. It can take some minutes, be patient.',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              console.log('Collect canceled');
            },
          },
          {
            text: 'OK',
            role: 'confirm',
            handler: async () => {
              console.log('Collect confirmed, checking pubkey');

              const pubkey = await userService.getPubkey();
              if (pubkey?.length > 0) {
              console.log('Pubkey exists: ', pubkey);

                const solanaBalance = await getSolanaBalance();
                console.log('Balance: ', solanaBalance, Number(solanaBalance));

                if (Number(solanaBalance) >= 0.0005) {

                  const nsaBalance = await getNsaTokenAccountsByOwner();
                  console.log('NSA Balance: ', nsaBalance, Number(nsaBalance));

                  if (nsaBalance != null && Number(nsaBalance) >= 0) {
                    const baseUrl = (Config.debugMode === false) ? Config.url : Config.urlDebug;
                    const url = baseUrl + 'collect_open?email=' + userService.email + '&v=' + Config.version;
                    
                    fetch(url).then(
                      async (res) => {
                        const result = await res.json();
                        console.log('Collect check result', result);
    
                        if (result && result['msg'] === true) {
                          
                          const baseUrl = (Config.debugMode === false) ? Config.url : Config.urlDebug;
                          const collectURL = baseUrl + 'collect?email=' + userService.email + '&security=' + userService.security + '&v=' + Config.version;
    
                          setIsCollecting(true);
    
                          fetch(collectURL).then(
                            async (res) => {
                              const result = await res.json();
                              if (result.error) {
                                serviceAlert({
                                  header: 'Error',
                                  subHeader: '',
                                  message: result.error,
                                  buttons: ['OK']
                                });
                              }  else {
                                confirmAlert({
                                  header: 'Info',
                                  subHeader: '',
                                  message: result.msg + ' Do you want to open the transaction page ?',
                                  buttons: [
                                    {
                                      text: 'Open',
                                      role: 'open',
                                      handler: () => {
                                        window.open(result.url)
                                      },
                                    },
                                    {
                                      text: 'Quit',
                                      role: 'quit',
                                      handler: () => {
                                        console.log('Collect terminated');
                                      },
                                    } 
                                  ]
                                });
    
                                // Refresh du compteur mining
                                setTimeout(() => {
                                  refreshMiningData();
                                }, 1000);
                              }
                            }
                          ).finally(() => setIsCollecting(false));
                        } else if (result && result['msg'] === false) {
                          console.log('Collect unconfirmed');
                          serviceAlert({
                            header: 'Info',
                            subHeader: '',
                            message: 'Service is currently disabled.',
                            buttons: ['OK']
                          });
                        }
                      }
                    );
                  } else {
                    balanceAlert({
                      header: 'Info',
                      subHeader: '',
                      message: 'You have to create the NSA asset in your wallet. Go to the wallet and click on Create NSA asset.',
                      buttons: ['OK']
                    });
                  }

                } else {
                  balanceAlert({
                    header: 'Info',
                    subHeader: '',
                    message: 'You need a minimum of 0.0005 SOL in your wallet.',
                    buttons: ['OK']
                  });
                }
              } else {
                console.log('Pubkey not exists: ', pubkey);

                abortAlert({
                  header: 'Info',
                  subHeader: '',
                  message: 'You have not created a wallet, create one in the wallet page and create the assets.',
                  buttons: ['OK']
                });
              }
            },
          },
        ]
      });
    } else {
      presentAlert({
        header: 'Info',
        subHeader: '',
        message: 'You need to collect a minimum of 1000 NSA <b>to your wallet</b>',
        buttons: ['OK']
      });
    }
  }

  /**
   * Récupération de la balance du wallet Solana
   */
  async function getSolanaBalance() {

    if (userService && userService.pubkey) {

      return walletService
        .getSolanaBalance(
          userService.pubkey
        ).then(
          async (res) => {
  
            const resultat = await res.json();
            
            let balance = (resultat?.result?.value) ? resultat?.result?.value : 0;
            
            // Parser la balance en valeur réelle
            const balanceString: string = (balance / 1000000000).toString();
            return balanceString;
          }
        ).catch((_e) => {
            console.log('error ' + _e);
        });
    }
  }

    /**
   * Récupération des comptes du wallet Solana
   */
    async function getNsaTokenAccountsByOwner() {

      if (userService && userService.pubkey) {
  
        return walletService
          .getSolanaTokenAccountsByOwner(
            userService.pubkey,
            Config.tokenAddress
          ).then(
            async (res) => {
              const resultat = await res.json();
    
              const results = resultat?.result?.value;
              console.log('results', results);
              
              let convertResult = null;
            
              if (results) {  
                results.forEach((item: any) => {
                  const info = item.account.data.parsed.info;
                  console.log('mint', Config.tokenAddress, info.mint);
                  if (info.mint === Config.tokenAddress) {
                    convertResult = info.tokenAmount.amount === '0' ? 0 : Number(info.tokenAmount.amount) / 1000000000;
                    console.log('mint convert result', convertResult);
                  }
                });
              }
              
              return convertResult;
            }
          ).catch((_e) => {
            console.log('error ' + _e);
          });
      }
    }

  function refreshMiningData() {
    getBalance(false);
    getLastMining(false);
    //getGraphData();
  }

  function changeChartToken(token: CustomEvent) {
    if (token?.detail?.value) {
      const tokenValue = token.detail.value;
      switch (tokenValue) {
        case 'nsa':
          getGraphData(Config.marketIdNsaUsdt, 'Nsa');
        break;
        case 'msol':
          getGraphData(Config.marketIdMsolUsdt, 'mSol');
        break;
        case 'sol':
          getGraphData(Config.marketIdSolUsdt, 'Sol');
        break;
      
        default:
          break;
      }
    }
  }

  function openChest(id: number) {
    alert('This chest slot is empty');
  }

  return (
    <IonPage>
      <IonContent fullscreen>

        {
          isLoadingMining &&
          <IonLoading
            cssClass='my-custom-class'
            isOpen={isLoadingMining}
            message={'Loading'}
            duration={0}
            spinner={'dots'}
          />
        }

        {
          isCollecting &&
          <IonLoading
            cssClass='my-custom-class'
            isOpen={isCollecting}
            message={'Waiting transaction confirmations, please wait'}
            duration={0}
            spinner={'dots'}
          />
        }
        
        <div className="container-mining">

          <IonAlert
            isOpen={showAlertConnection}
            onDidDismiss={() => closeConnexionAlert()}
            cssClass='my-custom-class'
            header={'Alert'}
            subHeader={'Server connexion error'}
            message={'Please check your internet connexion. If your connexion is activated and working, servers can be in maintenance so retry in few hours. Please take information on Telegram channel, thanks.'}
            buttons={['Exit']}
          />

          <IonAlert
            isOpen={showOutdatedVersion}
            onDidDismiss={() => closeOutdatedVersion()}
            cssClass='my-custom-class'
            header={'Alert'}
            subHeader={'Your application version is OUTDATED'}
            message={'Please upgrade the application from PlayStore, thanks.'}
            buttons={['Exit']}
          />

          <div className="container-slides-images">
            <Swiper
              effect="fade"
              spaceBetween={50}
              slidesPerView={1}
              pagination={true}
              loop={true}
              // disableOnInteraction={false}
              autoplay={true}
            // onSlideChange={(event: any) => console.log('slide change', event)}
            // onSwiper={(swiper: any) => console.log(swiper)}
            >

              {itemsimg.map((image, i) => (
                <SwiperSlide key={'img' + i} style={{ backgroundImage: image.src }}>
                  <div>
                    <img src={image.src} alt="slide" />
                  </div>
                </SwiperSlide>
              ))}

            </Swiper>
          </div>

          <div className="container-slides-textes">
            <IonIcon className="icon-icon-inside" icon={newspaper} />

            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              direction={'vertical'}
              loop={true}
              autoplay={true}
            // onSlideChange={(event: any) => console.log('slide change', event)}
            // onSwiper={(swiper: any) => console.log(swiper)}
            >

              {itemstxt.map((txt, i) => (
                <SwiperSlide key={'txt' + i}>
                  <IonLabel color="primary">{txt.text}</IonLabel>
                </SwiperSlide>
              ))}

            </Swiper>

            <IonIcon className="icon-icon-list" icon={list} />
          </div>

          <div className="container-mining-common">

            <p className="compteur-text">Earned: <span id="compteur-valeur" className="compteur-valeur">{incrementedBalance}</span> NSA <IonIcon onClick={() => { hideValue() }} className="icon-icon-list" icon={eye} />  <IonIcon onClick={() => { refreshMiningData() }} className="icon-icon-list" icon={refresh} /></p>

            <div className="common-buttons">
              <IonCard onClick={() => { reactivateMining() }}>
                <IonIcon color="primary" className="icon-icon-list" icon={cloud} />
                <IonSpinner id='miningSpinner'  name="crescent" className="enabled" />
                <IonCardHeader>
                  <IonCardSubtitle id='miningButton' className='enabled'>Enabled</IonCardSubtitle>
                </IonCardHeader>
              </IonCard>

              <IonCard id="present-alert" onClick={() => presentAlert({
                  header: 'Info',
                  subHeader: '',
                  message: (userService.donator && userService.donator === '1')
                    ? 'You are a Donator and your mining is currently at <b>x4 speed</b>.'
                    : 'To speedup your mining you need to be a donator, goto Account page and follow donator process.',
                  buttons: ['OK'],
                })}>
                <IonIcon color="primary" className="icon-icon-list" icon={speedometerOutline} />
                { userService.donator && userService.donator === '1' && <IonSpinner id='speedupSpinner'  name="crescent" />}
                <IonCardHeader>
                  <IonCardSubtitle>Speedup</IonCardSubtitle>
                </IonCardHeader>
              </IonCard>

              <IonCard onClick={() => {  generateInvite() }}>
                <IonIcon color="primary" className="icon-icon-list" icon={gitNetworkOutline} />
                <IonCardHeader>
                  <IonCardSubtitle>Invite</IonCardSubtitle>
                </IonCardHeader>
              </IonCard>

              <IonCard onClick={() => {  collectMining() }}>
                <IonIcon color="primary" className="icon-icon-list" icon={walletOutline} />
                <IonCardHeader>
                  <IonCardSubtitle>Collect</IonCardSubtitle>
                </IonCardHeader>
              </IonCard>

              {/* <IonCard onClick={() => { window.open('https://nebula.spl-token.com') }}>
                <IonIcon color="primary" className="icon-icon-list" icon={paperPlane} />
                <IonCardHeader>
                  <IonCardSubtitle>Website</IonCardSubtitle>
                </IonCardHeader>
              </IonCard> */}
              
              {/* <IonCard onClick={() => { window.open('https://t.me/nebula_android_application') }}>
                <IonIcon color="primary" className="icon-icon-list" icon={telescope} />
                <IonCardHeader>
                  <IonCardSubtitle>Telegram</IonCardSubtitle>
                </IonCardHeader>
              </IonCard> */}
              
              <IonCard onClick={() => { window.open('https://t.me/nebula_android_application') }}>
                <IonIcon color="primary" className="icon-icon-list" icon={paperPlane} />
                <IonCardHeader>
                  <IonCardSubtitle>Telegram</IonCardSubtitle>
                </IonCardHeader>
              </IonCard>
            </div>

            <div className="common-remaining">
                <p>Time remaining {(getDays() > 1 || getDays() === 0) ? getDays() + ' days' : (getDays() === 1 ) ? getDays() + ' day' : '0 days'} ({timeRemaining * 33} sec.)</p>
                <IonProgressBar className="progressbar-theme" value={progressBarValue} buffer={0}></IonProgressBar>
                <p className="tiny-text padding-top">You have to reactivate cloud mining each 7 days by reopening the app.</p>
            </div>

            <div className="container-myst-chest">
              <IonGrid>
                <IonRow>
                  <IonCol size="3">
                    <div className="chest-col">
                      <IonLabel>0d 0h 0mn</IonLabel>
                      <span>No Myst.<br/>Chest</span>
                      <IonButton onClick={() => openChest(1)}>Open</IonButton>
                    </div>
                  </IonCol>
                  <IonCol size="3">
                    <div className="chest-col">
                      <IonLabel>0d 0h 0mn</IonLabel>
                      <span>No Myst.<br/>Chest</span>
                      <IonButton onClick={() => openChest(2)}>Open</IonButton>
                    </div>
                  </IonCol>
                  <IonCol size="3">
                    <div className="chest-col">
                      <IonLabel>0d 0h 0mn</IonLabel>
                      <span>No Myst.<br/>Chest</span>
                      <IonButton onClick={() => openChest(3)}>Open</IonButton>
                    </div>
                  </IonCol>
                  <IonCol size="3">
                    <div className="chest-col">
                      <IonLabel>0d 0h 0mn</IonLabel>
                      <span>No Myst.<br/>Chest</span>
                      <IonButton onClick={() => openChest(4)}>Open</IonButton>
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
              
            </div>

            {/* <div className="container-mining-charts">
              <IonList className="chart-token-select">
                <IonItem color="secondary">
                  <IonSelect placeholder="Select token" defaultValue={'msol'} onIonChange={(token) => changeChartToken(token)}>
                    <IonSelectOption value="nsa">Nsa</IonSelectOption>
                    <IonSelectOption defaultChecked={true} value="msol">mSol</IonSelectOption>
                    <IonSelectOption value="sol">Sol</IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonList>
              <div id="chartdiv" className="chartdiv">
                <p id="no-api-key">Loading Market data...</p>
              </div>
              <div className="mining-charts-hide-logo"></div>
            </div> */}

            <div className="common-currencies">
            
            </div>
          </div>

        </div>
      </IonContent>
    </IonPage >
  );
};

export default Mining;
