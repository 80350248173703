import '@ionic/react/css/core.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import './theme/nebula.css';
import './App.css';

import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonCol,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonRow,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonText,
  setupIonicReact,
  useIonViewWillLeave,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import React, { useState } from 'react';
import UserService from './services/user';
import Mining from './pages/Mining/Mining';
import Wallet from './pages/Wallet/Wallet';
import Platform from './pages/Platform/Platform';
import News from './pages/News/News';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import Account from './pages/Account/Account';
import Forgot from './pages/Forgot/Forgot';
import Market from './pages/Market/Market';
import { AppVersion } from '@awesome-cordova-plugins/app-version';
import { albumsOutline, bagOutline, browsersOutline, downloadOutline, newspaperOutline, settingsOutline, walletOutline } from 'ionicons/icons';
import Subscriptions from './pages/Subscriptions/Subscriptions';
import { Subscription } from 'rxjs';
import { Shared } from './shared/shared';
import Config from './config/settings';

const App: React.FC<{}> = () => {

  const [isAndroidDevice, setIsAndroidDevice] = useState<boolean>(true);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [login, setLogin] = useState(false);

  // Init Services
  const userService: UserService = new UserService();
  const shared: Shared = new Shared();
  
  let userServiceSubscribe: Subscription;
  
  useState(async () => {
    // Init userService et attente ready
    userServiceSubscribe = userService.storageInitSubject.subscribe(async (result) => {
      if (isReady === false && result === true) {
        // Init Version
        await getDeviceVersion().then((version) => {
          userService.version = version
        });

        setIsReady(result);
      };
    });
  });
  
  async function getDeviceVersion() {
    return await AppVersion?.getVersionNumber()
      .then((version) => version)
      .catch(() => {
        setIsAndroidDevice(false);
        return Config.version;
      }
    );
  }

  setupIonicReact({
    swipeBackEnabled: false,// also prevent swiping back on either platform
    hardwareBackButton: false// this is what you need (android only)
  });

  useIonViewWillLeave(() => {
    if (userServiceSubscribe) {
      userServiceSubscribe.unsubscribe();
    }
  })
  
  /**
   * Récupérer le booléen du statut du login
   * @returns boolean du statut
   */
  function isUserAccountLoggedIn() {
    return login;
  }

  /**
   * Récupérer le service utilisateur
   * @returns userService service
   */
  function getUserservice() {
    return userService;
  }

  /**
   * Récupérer le service mutualisé
   * @returns Shared service
   */
  function getShared() {
    return shared;
  }

  /**
   * Set logout utilisateur
   */
  function logOutUser() {
    setLogin(false);
  }

  return isReady
    ? <IonApp style={isAndroidDevice ? {"width":"100%", "maxWidth":"500px", "min-width":"335px"} : {}}>

        {/* MENU */}
        <IonReactRouter>
          <IonTabs>

            {/* ROUTES */}
            <IonRouterOutlet>
              <Route path="/login">
                <Login userService={getUserservice()} setUserLoggedIn={() => { setLogin(true) }} />
              </Route>
              <Route path="/register">
                <Register userService={getUserservice()} />
              </Route>
              <Route path="/forgot">
                <Forgot userService={getUserservice()} />
              </Route>
              <Route exact path="/platform">
                {
                  isUserAccountLoggedIn()
                    ? <Platform shared={getShared()} userService={getUserservice()} isAndroidDevice={isAndroidDevice} />
                    : <Login userService={getUserservice()} setUserLoggedIn={() => { setLogin(true) }} />
                }
              </Route>
              <Route exact path="/subs">
                {
                  isUserAccountLoggedIn()
                    ? <Subscriptions shared={getShared()} userService={getUserservice()} isAndroidDevice={isAndroidDevice} />
                    : <Login userService={getUserservice()} setUserLoggedIn={() => { setLogin(true) }} />
                }
              </Route>
              <Route exact path="/market">
                {
                  isUserAccountLoggedIn()
                    ? <Market shared={getShared()} userService={getUserservice()} isAndroidDevice={isAndroidDevice} />
                    : <Login userService={getUserservice()} setUserLoggedIn={() => { setLogin(true) }} />
                }
              </Route>
              <Route exact path="/mining">
                {
                  isUserAccountLoggedIn()
                    ? <Mining shared={getShared()} userService={getUserservice()} isAndroidDevice={isAndroidDevice} />
                    : <Login userService={getUserservice()} setUserLoggedIn={() => { setLogin(true) }} />
                }
              </Route>
              <Route exact path="/wallet">
                {
                  isUserAccountLoggedIn()
                    ? <Wallet shared={getShared()} userService={getUserservice()} /*network={network}*/ isAndroidDevice={isAndroidDevice} />
                    : <Login userService={getUserservice()} setUserLoggedIn={() => { setLogin(true) }} />
                }
              </Route>
              <Route exact path="/news">
                {
                  isUserAccountLoggedIn()
                    ? <News shared={getShared()} userService={getUserservice()} isAndroidDevice={isAndroidDevice} />
                    : <Login userService={getUserservice()} setUserLoggedIn={() => { setLogin(true) }} />
                }
              </Route>
              <Route exact path="/account">
                {
                  isUserAccountLoggedIn()
                    ? <Account shared={getShared()} userService={getUserservice()} setUserLogOut={() => { logOutUser() }} />
                    : <Login userService={getUserservice()} setUserLoggedIn={() => { setLogin(true) }} />
                }
              </Route>
              <Route exact path="/">
                <Redirect to="/platform" />
              </Route>
            </IonRouterOutlet>

            {/* BOUTTONS MENU */}
            <IonTabBar className="bottom-bar" slot="bottom">
              <IonTabButton disabled={!isUserAccountLoggedIn()} tab="platform" href="/platform">
                <IonIcon icon={browsersOutline} />
                <IonLabel>Platform</IonLabel>
              </IonTabButton>
              <IonTabButton disabled={!isUserAccountLoggedIn()} tab="subs" href="/subs">
                <IonIcon icon={albumsOutline} />
                <IonLabel>Subs</IonLabel>
              </IonTabButton>
              <IonTabButton disabled={!isUserAccountLoggedIn()} tab="market" href="/market">
                <IonIcon icon={bagOutline} />
                <IonLabel>Market</IonLabel>
              </IonTabButton>
              <IonTabButton disabled={!isUserAccountLoggedIn()} tab="mining" href="/mining">
                <IonIcon icon={downloadOutline} />
                <IonLabel>Mining</IonLabel>
              </IonTabButton>
              <IonTabButton disabled={!isUserAccountLoggedIn()} tab="wallet" href="/wallet">
                <IonIcon icon={walletOutline} />
                <IonLabel>Wallet</IonLabel>
              </IonTabButton>
              <IonTabButton disabled={!isUserAccountLoggedIn()} tab="news" href="/news">
                <IonIcon icon={newspaperOutline} />
                <IonLabel>News</IonLabel>
              </IonTabButton>
              <IonTabButton disabled={!isUserAccountLoggedIn()} tab="account" href="/account">
                <IonIcon icon={settingsOutline} />
                <IonLabel>Account</IonLabel>
              </IonTabButton>

            </IonTabBar>
          </IonTabs>
        </IonReactRouter>
      </IonApp>
    : <IonApp>
        <IonRow className="app-loading">
          <IonCol>
            <IonText>
              Application Loading...
            </IonText>
          </IonCol>
        </IonRow>
      </IonApp>;
};

export default App;