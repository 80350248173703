import { registerPlugin } from '@capacitor/core';

export interface GetOurTokenAccountsByOwnerBridgePlugin {
    request(options: {
        url: string,
        body: string
    }): Promise<{
        url: string,
        body: string
    }>;
}

// eslint-disable-next-line
const GetOurTokenAccountsByOwnerBridgePlugin = registerPlugin<GetOurTokenAccountsByOwnerBridgePlugin>('GetOurTokenAccountsByOwnerBridgePlugin');

export default GetOurTokenAccountsByOwnerBridgePlugin;