import { IonLabel, IonContent, IonPage, IonItem, IonList, IonLoading, IonImg, IonButton, IonIcon, IonText, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonSegment, IonSegmentButton, useIonViewWillEnter, useIonViewWillLeave, IonCol, IonGrid, IonRow } from '@ionic/react';
import { useEffect, useState } from 'react';
import UserService from '../../services/user';
import SwiperCore, { Autoplay } from 'swiper';
import { IonicSwiper } from '@ionic/react';
import './News.css';
import 'swiper/swiper-bundle.min.css';
import '@ionic/react/css/ionic-swiper.css';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import { reload } from 'ionicons/icons';
import Config from '../../config/settings';
import { Subscription } from 'rxjs';
import { Shared } from '../../shared/shared';

const News: React.FC<{ shared?: Shared, userService?: UserService, isAndroidDevice: boolean }> = ({ shared, userService, isAndroidDevice }) => {

  SwiperCore.use([IonicSwiper, Autoplay]);

  const [currency, setCurrency] = useState<string>();
  const [selectedToken, setSelectedToken] = useState<string>();
  const [isLoadingMarkets = false, setIsLoadingNews] = useState<boolean>();
  const [isLoadingCommunity = false, setIsLoadingCommunity] = useState<boolean>();
  const [isLoadingBlog = false, setIsLoadingBlog] = useState<boolean>();
  const [refreshed = false, setRefreshed] = useState<boolean>();
  const [listCurrencies = [], setlistCurrencies] = useState<ItemCurrency[]>();
  const [items, setItems] = useState<any>();
  const [itemsBlog, setItemsBlog] = useState<any>();
  const [webNews = 2, setWebNews] = useState<number>();

  let userServiceSubscribe: Subscription;

  let chart: any;
  
  type ItemCurrency = {
    name: string;
    value: string;
    icon: string;
  };

  type ItemBlog = {
    img: string;
    date: string;
    title: string;
    content: string;
  };

  useEffect(() => {});
  
  useIonViewWillEnter(() => {

    // Attente du chargement du user service
    userServiceSubscribe = userService.storageInitSubject.subscribe((result) => {
      if (result === true) {
        if (userService.email && userService.email.length > 0 && userService.security && userService.security.length > 0) {

          //getNews();
          getBlog();
          //setWebNews(1);
          getCryptocurrenciesLive();

          setRefreshed(true);
        }
      }
    });
  });

  useIonViewWillLeave(() => {
    setlistCurrencies([]);
    setItemsBlog(undefined);
    chart = undefined;
    setRefreshed(false);
    // Suppression de la souscription
    if (userServiceSubscribe) {
      userServiceSubscribe.unsubscribe();
    }
  });

  /**
   * Données CoinGecko
   */
  async function getCryptocurrenciesLive() {

    setIsLoadingNews(true);

      const ids = 'solana,'
        + 'serum,'
        + 'stepn,'
        + 'star-atlas,'
        + 'tether,'
        + 'aurory,'
        + 'genopets,'
        + 'chainlink,'
        + 'solcial,'
        + 'ramp,'
        + 'terra-luna,'
        + 'insurace,'
        + 'band-protocol,'
        + 'raydium,'
        + 'orca,'
        + 'starbots,'
        + 'solanium,'
        + 'tulip-protocol,'
        + 'cykura,'
        + 'step-finance,'
        + 'akash-network,'
        + 'republic-protocol,'
        + 'waves,'
        + 'arix,'
        + 'audius,'
        + 'coin98,'
        + 'civic,'
        + 'holyheld,'
        + 'frontier-token,'
        + 'maps,'
        + 'oxygen,'
        + 'bonfida,'
        + 'aleph,'
        + 'parrot-protocol,'
        + 'ninja-protocol,'
        + 'grape-protocol,'
        + 'intersola,'
        + 'shill-token,'
        + 'gst,'
        + 'green-satoshi-token'
        + 'basis-markets,'
        + 'maneki-neko,'
        + 'kitty-coin-solana,'
        + 'uxd-protocol-token,'
        + 'upfi-network,'
        + 'solana-inu,'
        + 'solpad-finance,'
        + 'goosefx,'
        + 'boring-protocol,'
        + 'apexit-finance,'
        + 'solum,'
        + 'oxbull-solana,'
        + 'future,'
        + 'soldate-token,'
        + 'soltato-fries,'
        + 'solabrador,'
        + 'naxar,'
        + 'sola-token,'
        + 'solanasail-governance-token,'
        + 'solex-finance,'
        + 'rope-token,'
        + 'matrixetf,'
        + 'kurobi,'
        + 'sunny-aggregator,'
        + 'sail,'
        + 'solend';
    
      const resultCurrencies = ids.split(',');
      let listCurrencies = Object.keys(resultCurrencies).map((currency: any) => {
        const currencyFiltered = resultCurrencies[currency].replace(',', '')
        return {
          name: currencyFiltered.replace(',', ''),
          value: '',
          icon: `/assets/crypto/${currencyFiltered}.webp`
        };
      });

      setlistCurrencies(listCurrencies);
      setIsLoadingNews(false)
  }

  function refreshNews() {
    setRefreshed(false);
    getNews(true);
  }

  function refreshBlog() {
    const updateButton = document.getElementById('update-button');
    const updateButtonIsDisabled = updateButton.parentElement.attributes.getNamedItem('disabled');
    const updateButtonDefaultInner = updateButton.innerHTML;

    if (!updateButtonIsDisabled || updateButtonIsDisabled?.nodeValue === 'false') {
      updateButton.parentElement.setAttribute('disabled', 'true');
      setRefreshed(false);
      getBlog(true);
    }

    // Décompte du temps de disabled
    let temps = 5;
    const timer = setInterval(() => {
      if (temps === 0) {
        updateButton.innerHTML = updateButtonDefaultInner;
        updateButton.parentElement.setAttribute('disabled', 'false');
        clearInterval(timer);
      } else {
        updateButton.innerHTML = temps.toString();
        temps--;
      }
    }, 1000);
  }

  function getNews(forceRefresh = false) {

    if (!refreshed || forceRefresh) {
      setIsLoadingCommunity(true);
  
      fetch(`https://api.allorigins.win/get?url=${encodeURIComponent('https://news.google.com/rss/search?q=bitcoin%20OR%20altcoin%20OR%20cryptocurrency+when:10h&num=100')}`)
        .then(
          async (res) => {
            const resultat = await res.json();
            const contents = resultat['contents'];
            const xml = new window.DOMParser().parseFromString(contents, "text/xml");
            let itemList: any[] = [];
  
            xml.querySelectorAll("item").forEach(
              (item: any) => {
                itemList.push({ item });
              }
            );
  
            setItems(itemList);
            setRefreshed(true);
          }
        ).finally(() => setIsLoadingCommunity(false));
    }
  }

  function getBlog(forceRefresh = false) {
    if (!refreshed || forceRefresh) {
      setIsLoadingBlog(true);

      fetch(Config.url + 'news?v=' + Config.version)
        .then(
          async (res) => {
            const resultat = await res.json();

            let itemList: ItemBlog[] = [];
            const changelogs = resultat['Changelog'][0].split('\n');
            // itemList.push({ img: '/assets/img/slide0.png', date: new Date().toDateString(), title: 'Update', content: 'Public upgrade of android client application', });

            changelogs.forEach(
              (item: any) => {
                const itemMap: ItemBlog = { img: '', date: '', title: '', content: '', };
                itemMap.img = '/assets/img/slide0.png';
                itemMap.title = item.split('[')[1].split(']')[0];
                itemMap.date = item.split('[')[1].split(']')[1].split(' -')[0].replace(' -', '');
                itemMap.content = item.split(']')[1].split(' -')[1];

                itemList.push(itemMap);
              }
            );

            setItemsBlog(itemList);
            setRefreshed(true);
          }
        ).finally(() => setIsLoadingBlog(false));
    }
  }

  function generateGraphCustom(selectedToken: string) {
    setIsLoadingNews(true);

    if (!currency) {
      setCurrency('usd');
    }

    const days = '60';
    const interval = 'daily';

    fetch(`https://api.coingecko.com/api/v3/coins/${selectedToken}/market_chart?vs_currency=${(currency) ? currency : 'usd'}&days=${days}&interval=${interval}`)
      .then(
        async (res) => {
          const resultat: any = await res.json();
          const prices: any[] = resultat['prices'];
          // const market_caps: any[] = resultat['market_caps'];
          const total_volumes: any[] = resultat['total_volumes'];

          am4core.useTheme(am4themes_dark);
          am4core.useTheme(am4themes_animated);

          chart = am4core.create(
            "chartdivCustom",
            am4charts.XYChart,
          );

          if (prices && prices.length > 0) {

            chart.paddingRight = 20;
            chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";
  
            let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
            dateAxis.renderer.grid.template.location = 0;
  
            let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.tooltip.disabled = true;
  
            let series = chart.series.push(new am4charts.LineSeries());
            series.dataFields.dateX = "date";
            series.dataFields.valueY = "close";
            series.stroke = 'rgb(133, 231, 133)';
            series.tooltipText = 'Index on SOL during Beta';
  
            let volumeSeries = chart.series.push(new am4charts.ColumnSeries());
            volumeSeries.dataFields.dateX = "date";
            volumeSeries.dataFields.valueY = "quantity";
            volumeSeries.legendValueText = "{valueY}";
            volumeSeries.fill = 'rgb(133, 231, 133)';
            volumeSeries.stroke = 'rgb(133, 231, 133)';
  
            chart.cursor = new am4charts.XYCursor();
  
            // a separate series for scrollbar
            let lineSeries = chart.series.push(new am4charts.LineSeries());
            lineSeries.dataFields.dateX = "date";
            lineSeries.dataFields.valueY = "close";
            lineSeries.defaultState.properties.visible = false;
            lineSeries.hiddenInLegend = true;
            lineSeries.fillOpacity = 0.5;
            lineSeries.strokeOpacity = 0.5;
  
            let scrollbarX = new am4charts.XYChartScrollbar();
            scrollbarX.series.push(lineSeries);
            chart.scrollbarX = scrollbarX;
  
            chart.fontSize = 8;
  
            // Tri par date
            prices.sort((x, y) => {
              return x[0] - y[0];
            });
            total_volumes.sort((x, y) => {
              return x[0] - y[0];
            });
  
            // Récuparationd es valeurs max pour calcul de ratio pour le volume
            let valueMax = 0;
            for (let index = 0; index < prices.length; index++) {
              const price = prices[index];
              if (price[1] > valueMax) {
                valueMax = price[1];
              }
            }
            let volumeMax = 0;
            for (let index = 0; index < total_volumes.length; index++) {
              const volumeValue = total_volumes[index];
              if (volumeValue[1] > volumeMax) {
                volumeMax = volumeValue[1];
              }
            }
            let ratio = (volumeMax / valueMax) * 2.5;
  
            // ajout des données triées
            for (let index = 0; index < prices.length; index++) {
              const price = prices[index];
  
              const date = new Date(price[0]);
              let volume = '0';
  
              for (let index = 0; index < total_volumes.length; index++) {
                const volumeValue = total_volumes[index];
                if (volumeValue[0] === price[0]) {
                  // console.log('volume', volumeValue[1]);
                  volume = (volumeValue[1] / ratio).toString();
                }
              }
  
              let month = date.getMonth() + 1;
              let fullMonth = (month < 10) ? '0' + month : month;
              let day = date.getDate();
              let fullDay = (day < 10) ? '0' + day : day;
  
              const formattedDate: string = date.getFullYear() + '-' + fullMonth + '-' + fullDay;
              const value: string = price[1].toFixed(2).toString();
  
              chart.data.push({
                "date": formattedDate,
                "close": value,
                "quantity": volume
              });
            }
          }

        }
      ).finally(() => setIsLoadingNews(false));
  }

  function changeGraph(token: string) {
    setSelectedToken(token);

    if (token && token.length > 0) {
      const noGraph = document.getElementById('chartdivNoGraph');
      if (noGraph) {
        noGraph.remove();
      }

      generateGraphCustom(token);
    }
  }

  return (
    <IonPage>
      <IonContent fullscreen>

        {/* Barre d'entête */}
        <IonGrid className="header-bar">
          <IonRow className="header-bar-block">
            <IonCol size="2" className="header-bar-img-block">
              <IonImg className="header-bar-img" src="/assets/icon/new_icon.png" />
            </IonCol>
            <IonCol size="12" className="header-bar-label-block">
              <IonLabel className="header-bar-label">
                <h1 className="big-title">NEWS</h1>
              </IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>

        {
          isLoadingMarkets &&
          <IonLoading
            cssClass='my-custom-class'
            isOpen={isLoadingMarkets}
            message={'Loading'}
            duration={0}
            spinner={'dots'}
          />
        }
        {
          isLoadingCommunity &&
          <IonLoading
            cssClass='my-custom-class'
            isOpen={isLoadingCommunity}
            message={'Loading'}
            duration={0}
            spinner={'dots'}
          />
        }
        {
          isLoadingBlog &&
          <IonLoading
            cssClass='my-custom-class'
            isOpen={isLoadingBlog}
            message={'Loading'}
            duration={0}
            spinner={'dots'}
          />
        }

        <div className="container-markets">

          <div className="container-markets-common">

            <IonSegment className="bar-buttons" value={(
              webNews === 1
                ? "markets_news"
                : (webNews === 2)
                  ? "blog_news"
                  : "crypto_news"
            )}>
              <IonSegmentButton className="segment-button" id="market-button" onClick={() => {setWebNews(1); changeGraph(selectedToken);}} value="markets_news">
                <IonLabel>Markets</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton className="segment-button" onClick={() => setWebNews(2)} value="blog_news">
                <IonLabel>Project news</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton className="segment-button" onClick={() => setWebNews(3)} value="crypto_news">
                <IonLabel>Crypto news</IonLabel>
              </IonSegmentButton>
            </IonSegment>

            {webNews === 1 &&
              <div className="common-markets">
                <p className="market-text">Selected Token: <span className="token-name">{selectedToken}</span></p>

                <div className="container-markets-charts">
                  <div id="chartdivCustom" className="chartdiv-custom">
                    <p id="chartdivNoGraph">Please choose a token</p>
                  </div>
                  <div className="chartdiv-hide-logo"></div>
                </div>

                <div className="common-currencies">
                  <h1 className="title">Solana ecosystem</h1>
                  <IonList className="list-currencies">

                    {listCurrencies.map((currency, i) => (
                      <IonItem key={'history' + i} className="currency" onClick={() => changeGraph(currency.name)}>
                        <IonLabel>
                          {/* <IonImg color={'primary'} className="currency-logo" src={currency.icon} /> {currency.name} <span className="tiny-link" onClick={() => { }}>{currency.value} USDT</span> */}
                          <IonImg color={'primary'} className="currency-logo" src={currency.icon} /> {currency.name} <span className="tiny-link" onClick={() => { }}></span>
                        </IonLabel>
                      </IonItem>
                    ))}

                  </IonList>
                </div>
              </div>
            }

            {webNews === 2 &&
              <div className="common-blog">
                <h1 className="title">Project news</h1>

                <IonButton className="news-button" onClick={() => refreshBlog()}>
                  <IonIcon slot="start" color="secondary" className="icon-icon-list" icon={reload} />
                  <IonText color="secondary" id="update-button">Update Project news</IonText>
                </IonButton>

                <IonList>

                  {itemsBlog?.map((item: any, i: number) => (

                    <IonItem key={'blog' + i}>
                      <IonCard>
                        <img src={item.img} alt="icon" />
                        <IonCardHeader>
                          <IonCardSubtitle>{item.date}</IonCardSubtitle>
                          <IonCardTitle>{item.title}</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                          {item.content}
                        </IonCardContent>
                      </IonCard>
                    </IonItem>

                  ))}

                </IonList>
              </div>
            }

            {webNews === 3 &&
              
              <div className="common-news">
                <h1 className="title">Crypto News</h1>

                <IonButton className="news-button" onClick={() => refreshNews()}>
                  <IonIcon slot="start" color="secondary" className="icon-icon-list" icon={reload} />
                  <IonText color="secondary">Update Crypto news</IonText>
                </IonButton>

                <IonList>
                  {
                    items?.map((item: any, index: number) => {
                      return <IonItem key={'news-' + index}>
                        <IonCard onClick={() => { window.open(item.item.childNodes[1].textContent) }}>
                          <IonCardHeader>
                            <IonCardSubtitle><p>{item.item.childNodes[5].textContent}</p>{item.item.childNodes[3].textContent}</IonCardSubtitle>
                          </IonCardHeader>
                          <IonCardContent>
                            {item.item.childNodes[0].textContent}
                          </IonCardContent>
                        </IonCard>
                      </IonItem>;
                    })
                  }
                </IonList>
                {
                  (!items || items.length < 1) &&
                    <IonText className="align-item-update">Please click on update</IonText>
                }
              </div>
            }

          </div>

        </div>
      </IonContent>
    </IonPage >
  );
};

export default News;
