import { IonAlert, IonAvatar, IonButton, IonButtons, IonCard, IonCardContent, IonChip, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonLoading, IonModal, IonPage, IonRefresher, IonRefresherContent, IonRow, IonText, IonTextarea, IonTitle, IonToolbar, useIonAlert, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import { alertCircle, thumbsUp, thumbsDown, shareSocialSharp, backspace, checkmarkDone, chatboxEllipsesOutline } from 'ionicons/icons';
import { useCallback, useEffect, useState } from 'react';
import UserService from '../../services/user';
import './Subscriptions.css';
import Config from '../../config/settings';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Subscription } from 'rxjs';
import { CommentTxt } from '../../models/commentTxt';
import { ItemTxt } from '../../models/itemTxt';
import { ItemSub } from '../../models/itemSub';
import { Virtuoso } from 'react-virtuoso'
import { Shared } from '../../shared/shared';

const Subscriptions: React.FC<{ shared?: Shared, userService?: UserService, isAndroidDevice: boolean }> = ({ shared, userService, isAndroidDevice }) => {
  const [subsData, setSubsData] = useState<ItemTxt[]>([]);
  const [subsList, setSubsList] = useState<ItemSub[]>([]);
  const [commentData, setCommentData] = useState<CommentTxt[]>([]);
  // const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  const [versionTested = false, setVersionTested] = useState<boolean>();
  const [showAlertConnection, setShowAlertConnexion] = useState(false);
  const [showOutdatedVersion, setShowOutdatedVersion] = useState(false);
  const [needSubscribeMessage, setNeedSubscribeMessage] = useState(true);
  const [hasNoContent, setHasNoContent] = useState(true);
  const [isTester = false, setIsTester] = useState<boolean>();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ItemTxt>(null);
  const [comment, setComment] = useState<string>();
  const [isLoadingUserData = false, setIsLoadingUserdata] = useState<boolean>();
  const [webBalance = false, setWebBalance] = useState<boolean>();
  const [endOfItems = false, setEndOfItems] = useState<boolean>();
  const [changeButtonToUnsubscribe = false, setChangeButtonToUnsubscribe] = useState<boolean>();
  const [presentAlert] = useIonAlert();
  
  let userServiceSubscribe: Subscription;
  
  // Action lors du bouton BACK
  var backButton = function() {
    var handler = function(ev: any) {
      ev.preventDefault();
      ev.stopPropagation();
      ev.stopImmediatePropagation();
    };
    return handler;
  };
  
  useEffect(() => {
    
    const timeoutSubs = loadMoreSubs();
    
    return () => {
      clearTimeout(timeoutSubs);
    };
    // eslint-disable-next-line
  }, []);
  
  useIonViewWillEnter(() => {

    // Action lors du bouton BACK
    document.addEventListener('ionBackButton', backButton, false);

    // Attente du chargement du user service
    userServiceSubscribe = userService.storageInitSubject.subscribe((result) => {
      if (result === true) {

        // Si les données sont déjà présentes on les sauvegardes
        if (userService.subsCalled === true) {
          // Récupération des données sauvegardées
          getSubsList();
          // setSubsData(userService.getSubsData());
        } else {
          // Si premier affichage, on charge les données
          if (userService.email && userService.email.length > 0 && userService.security && userService.security.length > 0) {
            // Vérification de la version
            checkVersion(userService.email).then((isOutDated) => {

              if (!isOutDated && isOutDated === false) {
                // Si la version est ok et que internet fonctionne
                getSubsList();

                getSubsData();
                if (webBalance === false) getBalance(userService.email, userService.security, false);
              } else if (isOutDated === null) {
                // Si internet ne fonctionne pas
                setShowAlertConnexion(true);
              } else if (isOutDated === true) {
                // Si la version est trop vieille
                setShowOutdatedVersion(true);
              }
            });
          }
        }
      }
    });
  });

  useIonViewWillLeave(() => {
    // Vidage des nébuleuses
    setSubsData([]);
    // Vidage des commentaires
    setCommentData([]);
    // Suppression de la souscription
    if (userServiceSubscribe) {
      userServiceSubscribe.unsubscribe();
    }

    document.removeEventListener('ionBackButton', backButton);
  });

  /**
   * Ouvre ou ferme une nébuleuse au clic sur un item
   * ou au clic sur le retour
   * @param status Statut ouvert/fermé de la modale
   * @param item Item sélectionné
   */
  function openOrCloseItemModal(status: boolean, item: ItemTxt, id: number) {
    
    if (!isTester) {
      presentAlert({
        header: 'Message',
        subHeader: '',
        message: 'You need to be a Tester to interact with the Subs service.',
        buttons: ['OK'],
      });
    } else {
      setIsOpen(status);
      setChangeButtonToUnsubscribe(false);
      setSelectedItem(item);
      getSubsList();
      
      if (status === true) {
        // console.log('open modal', status, item.id, id);

        userService.getSubsList().then(
          (subList) => {
            if (item && item.email) {
              const newSub: ItemSub = {
                avatar: '',
                channel: item.email.split('%40')[0]
              };
      
              // On recherche si le nom du channel est déjà dans la sub list
              const isInSubList = subList && subList.length > 0 ? subList.find(item => item.channel === newSub.channel) : null;
      
              // Si le sub est présent dans la liste on change le bouton en unsubscribe
              if (isInSubList) {
                setChangeButtonToUnsubscribe(true)
              }
            }
          }
        );

        // Récupération des commentaires associés à l'item choisi
        getCommentsOfItem(item);
      }
    }
  }

  /**
   * Envoie d'un nouveau commentaire
   * @param newComment Contenu du commentaire
   * @param item Item sélectionné
   */
  function sendComment(newComment: string, item: ItemTxt) {
    console.log('newComment', newComment, item);
    
    if (newComment && item) {
      console.log('length', newComment.length);
      if (newComment.length < 3 || newComment.length > 350) {
        presentAlert({
          header: 'Message',
          subHeader: '',
          message: 'Your comment length must be between 3 and 350.',
          buttons: ['OK'],
        });
      } else {
        setIsLoadingUserdata(true);
        
        const baseUrl = (Config.debugMode === false) ? Config.url : Config.urlDebug;
        const url = baseUrl + 'post_comment?email=' + userService.email + '&security=' + userService.security + '&v=' + Config.version;
  
        fetch(url, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(
            {
              'comment': newComment,
              'url_id': item.id
            }
          )
        }).then(
          async (res) => {
            const result = await res.json();
            console.log('result', result);
            
            //refreshCommentList();
          }
        ).catch((_e) => {
          if(_e.status === 403) {
            presentAlert({
              header: 'Message',
              subHeader: '',
              message: '403',
              buttons: ['OK'],
            });
          } else {
            console.log('Error', _e);
          }
        }).finally(() => {
          setComment('');
          getCommentsOfItem(item);
          setIsLoadingUserdata(false);
        });
      }

    } else {
      presentAlert({
        header: 'Message',
        subHeader: '',
        message: 'Please fill in the field !',
        buttons: ['OK'],
      });
      // Stop du loader
      setIsLoadingUserdata(false);
    }
  }

  const loadNextSubsPage = (ev: any) => {

    if (!endOfItems) {
      setTimeout(() => {

        userService.resetSubsCalled();

        getSubsData();
        
        if (ev) ev.target.complete();

        // Si 1000 contenus chargés on bloque l'infinite scroll
        // if (data.length === 1000) {
        //   setInfiniteDisabled(true);
        // }
      }, 500);
    }
  }

  /**
   * Rafraichir la liste des posts
   * @param event 
   */
  function handleRefresh(event: CustomEvent<any>) {
    
    setEndOfItems(false);
    
    setTimeout(() => {
      
      userService.resetIsSubsPage();
      
      // Reset des données chargées
      userService.clearSubsData();
      userService.resetSubsCalled();

      // Rechargement des données
      getSubsData();

      if (event && event.detail) {
        event.detail.complete();
      }
    }, 2000);
  }

  /**
   * Ouverture de l'url de l'item sélectionné
   * @param item 
   */
  function openUrlContent(url: string) {
    if (!isTester) {
      presentAlert({
        header: 'Message',
        subHeader: '',
        message: 'You need to be a Tester to interact with the Subs service.',
        buttons: ['OK'],
      });
    } else {
      window.open(url, '_blank')
    }
  }

  /**
   * Récupération des données du compte utilisateur
   * @param email
   * @param security 
   * @param alerte 
   */
  async function getBalance(email: any, security: any, alerte: boolean) {
    setWebBalance(true);

    if (userService) {
      setIsLoadingUserdata(true);

      const baseUrl = (Config.debugMode === false) ? Config.url : Config.urlDebug;
      const url = baseUrl + 'select?email=' + email + '&security=' + security + '&v=' + Config.version;

      fetch(url).then(
        async (res) => {
          const result = await res.json();
          const balance = (result && result.balance) ? result.balance : 0.00000;
          const access = (result && result.access) ? result.access : 0;
          const donator = (result && result.donator) ? result.donator : 0;
          const country = (result && result.country) ? result.country : null;
          const emailConfirmed = (result && result.email) ? result.email : 0;

          userService.setBalance(balance);
          userService.setAccess(access);
          userService.setDonator(donator);
          userService.setCountry(country);
          userService.setEmailConfirmed(emailConfirmed);
          userService.setTester(access);

          // setIncrementedBalance(balance);
        }
      ).catch((_e) => {
        if(_e.status === 403) {
          presentAlert({
            header: 'Message',
            subHeader: '',
            message: 'Because of more than 6 months of inactivity, your account was disabled. Please contact support on Telegram to get more informations.',
            buttons: ['OK'],
          });
        } else {
          setShowAlertConnexion(true);
        }
        setIsLoadingUserdata(false);
      }).finally(() =>
        setIsLoadingUserdata(false)
      );
    }
  }

  /**
   * Récupération de la liste des souscriptions
   */
  function getSubsList() {
    userService.getSubsList().then(
      (itemSubs: ItemSub[]) => {
        if (itemSubs === null) itemSubs = [];
        setSubsList(itemSubs);
      }
    )
  }

  /**
   * Récupérer les données de la plateforme
   */
  async function getSubsData() {
     
    if (userService && userService.email && userService.security && !userService.subsCalled && !endOfItems) {

      setIsLoadingUserdata(true);
      
      userService.setSubsCalled();

      // On récupère la liste des souscriptions pour les passer en paramètre
      userService.getSubsList().then((subs: ItemSub[]) => {

        let userSub: string = '';

        if (subs && subs.length > 0) {
          setNeedSubscribeMessage(false);

          subs.forEach((sub, index) => {
            userSub = userSub + ((index > 0) ? ',' : '') + sub.channel;
          })

          if (userSub.length > 0) {
            const baseUrl = (Config.debugMode === false) ? Config.url : Config.urlDebug;
            const url = baseUrl + 'platform?email=' + userService.email + '&security=' + userService.security + '&subs=' + userSub + '&page=' + userService.isSubsPage + '&v=' + Config.version;

            // TODO: Faire une requête post pour passer la liste des subs
            fetch(url).then(
              async (res) => {
                
                const result = await res.json();

                const newData: ItemTxt[] = [];
                // Mock incrément commentaires factices
                const max = subsData.length + 50;
                const min = max - 10;
                // Fin mock incrément commentaires factices

                if (result && result.msg && (result.msg.indexOf('Tester') > 0 || result.msg.indexOf('wallet') > 0)) {
                  // Blocage need more than 50 NSA

                  userService.setTester(false);
                  setIsTester(false);
                    
                  for (let i = min; i < max; i++) {
                    newData.push({
                      id: i,
                      url: '',
                      vote_dn: 0,
                      vote_up: 0,
                      tags: '',
                      creation: '',
                      texte: '',
                      ad_path: '/assets/ghost/ads1.jpg',
                      path: '/assets/ghost/ghost_thumb.png',
                      email: '',
                      out_url: '',
                      stars: 0,
                    });
                  }

                  // Ajout des données à l'infinite scroll
                  userService.setSubsData(
                    newData
                  );

                  setSubsData(userService.getSubsData());

                } else if (result?.total?.nb_id > 0 && result?.rows?.length > 0) {
                  // Actif + de 50 NSA et Tester

                  userService.setTester(true);
                  setIsTester(true);

                  // On vide la liste si c'est le 1er chargement
                  if (userService.isSubsPage === 0) {
                    userService.clearSubsData();
                    setSubsData(userService.subsData);
                  }
        
                  // TODO: Fetch commentaires

                  // On incrémente la page
                  userService.incrementSubsPage();
                  
                  const ads_path_prefix = 'https://nebula-webapp.spl-token.com/assets/ads/';
                  const thumb_path_prefix = 'https://nebula-webapp.spl-token.com/assets/thumb/';
                  
                  for (let i = 0; i < result.rows.length; i++) {
                    if (
                      result.rows[i] &&
                      (result.rows[i].url && result.rows[i].url !== "'null'" &&
                      result.rows[i].tags && result.rows[i].tags !== "'null'" &&
                      result.rows[i].email && result.rows[i].email !== "'null'" &&
                      result.rows[i].texte && result.rows[i].texte !== "'null'")
                    ) {
                      newData.push({
                        id: (result.rows[i].id) ? result.rows[i].id : null,
                        url: (result.rows[i].url) ? result.rows[i].url.slice(1, -1) : null,
                        vote_dn: (result.rows[i].vote_dn) ? result.rows[i].vote_dn : 0,
                        vote_up: (result.rows[i].vote_up) ? result.rows[i].vote_up : 0,
                        tags: (result.rows[i].tags) ? result.rows[i].tags.slice(1, -1) : null,
                        creation: (result.rows[i].creation) ? result.rows[i].creation : null,
                        texte: (result.rows[i].texte) ? result.rows[i].texte.slice(1, -1) : null,
                        ad_path: (result.rows[i].ad_path) ? ads_path_prefix + result.rows[i].ad_path : null,
                        path: (result.rows[i].path) ? ((Config.debugMode === false) ? thumb_path_prefix + result.rows[i].path : '/assets/ghost/ghost_thumb.jpg') : null,
                        email: (result.rows[i].email) ? result.rows[i].email : null,
                        out_url: (result.rows[i].out_url) ? result.rows[i].out_url : null,
                        stars: (result.rows[i].stars) ? result.rows[i].stars : 0,
                      });
                    }
                  }

                  // Ajout des données à l'infinite scroll
                  userService.setSubsData([
                    ...userService.getSubsData(),
                    ...newData
                  ]);
                  
                  setSubsData(userService.getSubsData());
                } else {
                  setEndOfItems(true);
                }
              }
            ).catch((_e) => {
              console.log('error', _e);
              presentAlert({
                header: 'Message',
                subHeader: '',
                message: 'Unexpected error, impossible to contact the server. Please contact support on Telegram.',
                buttons: ['OK'],
              });
            }).finally(() =>{
              setIsLoadingUserdata(false);
            });
          } else {
            setIsLoadingUserdata(false);
          }
        } else {
          setNeedSubscribeMessage(true);
          setIsLoadingUserdata(false);
        }
      });
    }
  }

  /**
   * Récupération des données de commentaire d'une nébuleuse
   * @param item
   */
  async function getCommentsOfItem(item: ItemTxt) {

    if (userService) {
      setIsLoadingUserdata(true);

      const baseUrl = (Config.debugMode === false) ? Config.url : Config.urlDebug;
      const url = baseUrl + 'comment?email=' + userService.email + '&security=' + userService.security + '&id=' + item.id + '&v=' + Config.version;

      fetch(url).then(
        async (res) => {
          const result = await res.json();
          const comments: CommentTxt[] = (result) ? result : [];

          for (let index = 0; index < comments.length; index++) {
            const comment: CommentTxt = comments[index];
            comment.email = comment.email.split('%')[0];
            comment.creation = comment.creation.split('T')[0];
            comment.comment = decodeURIComponent(comment.comment);
          }

          setCommentData(comments);
          updateCommentValueParent(item, comments.length);
        }
      ).catch((_e) => {
        console.log('debug: error comment loading', _e);
      }).finally(() =>
        setIsLoadingUserdata(false)
      );
    }
  }

  /**
   * Vérification de la version de l'application
   * @param email 
   */
   async function checkVersion(email: any) {

    if (email && !versionTested) {
      setVersionTested(true);

      return userService.checkVersion(email)
        .then((result: boolean) => {
          setShowOutdatedVersion(result);
          return result;
        }
      );
    }
  }

  /**
   * Fermeture de l'alerte de connexion
   */
  function closeConnexionAlert() {
    setShowAlertConnexion(false);
    userService.forceCloseApp();
  }

  /**
   * Ferme l'application dépréciée
   */
  function closeOutdatedVersion() {
    setShowOutdatedVersion(false);
    userService.forceCloseApp();
  }

  /**
   * Ouvre la page de mining
   */
  function gotoMining() {
    const page = 'mining';
    const miningTab: HTMLElement | null = document.querySelector('#tab-button-' + page);
    miningTab?.click();
  }

  /**
   * Ouvre la page de presale
   */
  function gotoWallet() {
    const page = 'wallet';
    const presaleTab: HTMLElement | null = document.querySelector('#tab-button-' + page);
    presaleTab?.click();
  }

  const Footer = () => {
    const subscriptionData = userService.getSubsData();

    return endOfItems || (subscriptionData && subscriptionData.length <= 10)
      ? <div
          style={{
            padding: '2rem',
            display: 'flex',
            justifyContent: 'center',
          }}
        ></div>
      : <div
          style={{
            padding: '2rem',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          Loading...
        </div>
  }
  
  const loadMoreSubs = useCallback(() => {
    const subscriptionData = userService.getSubsData();

    if (subscriptionData && subscriptionData.length >= 10 ) {
      return setTimeout(() => {
        loadNextSubsPage(null);
      }, 200)
    } else {
      return null;
    }
    // eslint-disable-next-line
  }, [setSubsData]);
  
  /**
   * Ajouter un sub de la list de subs
   * @param email 
   */
  function addSub(email: string): void {
    userService.getSubsList().then(
      (subList) => {
        const newSub: ItemSub = {
          avatar: '',
          channel: email.split('%40')[0]
        };

        // On recherche si le nom du channel est déjà dans la sub list
        const isInSubList = subList && subList.length > 0 ? subList.find(item => item.channel === newSub.channel) : null;

        // S'il ne l'est pas on l'ajoute
        if (!isInSubList) {
          userService.addSubToList(newSub);
        }
      }
    ).finally(
      () => setChangeButtonToUnsubscribe(true)
    );
  }

  /**
   * Supprimer un sub de la list de subs
   * @param email 
   */
  function removeSub(email: string): void {
    userService.getSubsList().then(
      (subList) => {

        const oldSub: ItemSub = {
          avatar: '',
          channel: email.split('%40')[0]
        };

        // On recherche si le nom du channel est déjà dans la sub list
        const isInSubList = subList && subList.length > 0 ? subList.find(item => item.channel === oldSub.channel) : null;

        // S'il est dans la liste on le supprime
        if (isInSubList) {
          userService.removeSubFromList(oldSub);
        }
      }
    ).finally(
      () => setChangeButtonToUnsubscribe(false)
    );
  }

  /**
   * Formatte la date pour un affichage correct
   * @param date Date en entrée
   * @returns date formattée
   */
  function formatDate(date: string) {
    return new Date(date).toLocaleDateString(
      undefined,
      {
        year: "numeric", 
        month: "long", 
        day: "numeric" 
      }
    );
  }

  /**
   * Met à jour le count des commentaire de la liste parent
   * @param item l'item sélectionné
   * @param length  la taille de la liste de commentaire
   */
  function updateCommentValueParent(item: ItemTxt, length: number) {
    console.log('debug: TODO update comment count parent');

  }

  /**
   * Répondre à un commentaire
   * @param comment lel'index du commentaire commentaire
   * @param index 
   */
  function replyComment(comment: CommentTxt, index: number) {
    const commentNum = index + 1;
    setComment('@' + commentNum + '-' + comment.email + ' ');
    let y = document.getElementById('comment-box').offsetTop;
    let content = document.querySelector('ion-content');
    content.scrollToPoint(0, y);
  }

  /**
   * Récupération du nom de domaine de l'url
   * @param url 
   * @returns 
   */
  function getShrinkedUrl(url: string) {
    const decodedUrl = decodeURIComponent(url);
    if (decodedUrl && decodedUrl !== null && decodedUrl !== 'null') {
      try {
        const domain = new URL(decodedUrl);
        const hostname = domain.hostname;
        return hostname;
      } catch (error) {
        return decodeURIComponent(url);
      }
    } else {
      return decodeURIComponent(url);
    }
  }

  return (
    <IonPage className="container-subscription">
      <IonContent>

        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        {/* Popin de chargement de données */}
        {
          isLoadingUserData &&
          <IonLoading
            id="isLoadingUserData"
            cssClass='my-custom-class'
            isOpen={isLoadingUserData}
            message={'Loading'}
            duration={0}
            spinner={'dots'}
          />
        }

        {/* Message d'alerte pour la connexion internet */}
        <IonAlert
          isOpen={showAlertConnection}
          onDidDismiss={() => closeConnexionAlert()}
          cssClass='my-custom-class'
          header={'Alert'}
          subHeader={'Server connexion error'}
          message={'Please check your internet connexion. If your connexion is activated and working, servers can be in maintenance so retry in few hours. Please take information on Telegram channel, thanks.'}
          buttons={['Exit']}
        />
  
        {/* Message d'alerte dans le cas d'une ancienne version */}
        <IonAlert
          isOpen={showOutdatedVersion}
          onDidDismiss={() => closeOutdatedVersion()}
          cssClass='my-custom-class'
          header={'Alert'}
          subHeader={'Your application version is OUTDATED'}
          message={'Please upgrade the application from PlayStore, thanks.'}
          buttons={['Exit']}
        />

        {/* Barre de recherche */}
        <IonGrid className="search-bar">
          <IonRow>
            <IonCol size="2">
              <IonImg className="search-bar-img" src="/assets/icon/new_icon.png" />
            </IonCol>
            {subsList && subsList.length > 0
              ? <IonCol size="10" className="subscription-list full-scroll">
                  <IonRow>
                    <IonCol className="subscription-list">
                      {subsList.map(
                        (item, index) => {
                          
                          return (
                            <IonChip key={'sub' + index}>
                              <IonLabel color="primary">{item.channel}</IonLabel>
                            </IonChip>
                          )
                        })
                      }
                    </IonCol>
                  </IonRow>
                </IonCol>
              : <IonCol size="10" color="secondary" className="subscription-list-empty">
                  <IonChip key="no-sub">
                    <IonLabel>no subscription</IonLabel>
                  </IonChip>
                </IonCol>
            }
          </IonRow>
        </IonGrid>
        
        {/* Message demande souscription à 1 canal minimum */}
        {needSubscribeMessage && 
          <IonGrid className="no-subs-message">
            <IonRow>
              <IonCol>
                Please subscribe to a minimum of <b>1 channel</b> in the platform page <b>and refresh this page</b>
              </IonCol>
            </IonRow>
          </IonGrid>
        }
        
        {/* Message indiquant qu'il n'y a aucun contenu */}
        {(!needSubscribeMessage && subsList?.length > 0 && userService.getSubsData().length === 0) && 
          <IonGrid className="no-subs-message">
            <IonRow>
              <IonCol>
                Your current subscriptions have no content
              </IonCol>
            </IonRow>
          </IonGrid>
        }

        {/* Liste des nébuleuses */}
        {isTester && userService.getSubsData().length > 0 &&
          <Virtuoso
            style={{ height: 'calc(100% - 78px)', marginTop: '78px' }}
            data={userService.getSubsData()}
            endReached={loadMoreSubs}
            overscan={0}
            itemContent={(index, item) => {
              return (
                <IonCard className="item-card" key={'data' + index}>
                  <IonRow className="header">
                    <IonCol className="picto" size="12" onClick={() => openOrCloseItemModal(true, item, item.id)}>
                      <IonImg className="item-img" src={item.path} />
                    </IonCol>
                  </IonRow>
                  <IonCardContent className="item-content">
                    <IonRow className="description" onClick={() => openOrCloseItemModal(true, item, item.id)}>
                      <IonText className="item-description">{decodeURIComponent(item.texte)}</IonText>
                    </IonRow>
                    <IonRow className="url" title="Open the url">
                      <IonText className="text" onClick={() => openUrlContent(decodeURIComponent(item.url))}>{getShrinkedUrl(item.url)}</IonText>
                    </IonRow>
                    <IonRow className="options">
                      <IonText className="item-comment" color="primary">
                        <span>
                          <IonChip onClick={() => openOrCloseItemModal(true, item, item.id)}>
                            <IonAvatar>
                              <img src={'https://gravatar.com/avatar/' + (Math.random() * 10000).toFixed(0) + '?d=identicon&f=y'} alt="avatar" />
                            </IonAvatar>
                            <IonLabel color="primary">{(item.email && item.email.length > 0) ? item.email.split('%40')[0] : ''}</IonLabel>
                          </IonChip>
                        </span>
                        <span>
                          <IonChip onClick={() => openOrCloseItemModal(true, item, item.id)}>
                            <IonAvatar className="avatar-number">
                              {item.stars}
                            </IonAvatar>
                            <IonIcon className="icon-dialog" size="large" color="primary" icon={chatboxEllipsesOutline} />
                          </IonChip>
                        </span>
                        <span>
                          <IonChip onClick={() => openOrCloseItemModal(true, item, item.id)}>
                            <IonLabel color="primary">{(item.creation && item.creation.length > 0) ? formatDate(item.creation) : ''}</IonLabel>
                          </IonChip>
                        </span>
                      </IonText>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              )
          }}
            components={{ Footer }}
          />
        }
        
        {/* Message d'information */}
        { !isTester &&
          <div className="container-subscription-common">
            <IonImg style={{ margin: "auto", height: "52px", width: "52px"}} src="/assets/icon/new_icon.png" />
            {/* <IonText>The subs service is available in BETA. You must be a Beta tester with more than 50 NSA to access it. <b>To check your mining, go to Mining page by clicking bottom button.</b><br /><IonButton color="secondary" onClick={() => gotoMining()}>Goto Mining</IonButton><br />To buy NSA before market start, get some SOL, go to the Wallet by clicking bottom button <b>and press BUY.</b><br /><IonButton color="secondary" onClick={() => gotoWallet()}>Goto Wallet</IonButton></IonText> */}
            <IonText>The subs service is available in BETA. You must be a Beta tester with more than 50 NSA to access it. <b>To check your mining, go to Mining page by clicking bottom button.</b><br /><IonButton color="secondary" onClick={() => gotoMining()}>Goto Mining</IonButton></IonText>
          </div>
        }

        {/* Modale de la nébuleuse sélectionnée */}
        <IonModal id="modale" isOpen={isOpen}>
          <IonHeader className="modale-header">
            <IonToolbar>
              <IonTitle>Selected Nebula</IonTitle>
              <IonButtons color="primary" slot="start">
                <IonButton onClick={() => openOrCloseItemModal(false, null, 0)}>
                  <IonIcon color="primary" icon={backspace} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonItem className="modale-content modale-nebula">

            { selectedItem !== null &&
              <IonCard className="subs-modale-card" key={'data selected-item'}>
                <IonRow className="header">
                  <IonCol className="picto" size="12">
                    <IonImg className="item-img" src={selectedItem.path}></IonImg>
                  </IonCol>
                  <IonCol className="picto-text">
                    <IonRow>
                      <IonCol size="12">
                        <IonText className="item-description">{decodeURIComponent(selectedItem.texte)}</IonText>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonButton onClick={() => changeButtonToUnsubscribe ? removeSub(selectedItem.email) : addSub(selectedItem.email)}>{changeButtonToUnsubscribe ? 'Unsubscribe' : 'Subscribe'}</IonButton>
                      <IonText className="item-comment align-comment-number" color="primary">
                        <h3 className="nospace-comments">
                          {commentData && commentData.length > 0 ? commentData.length : 0} <IonIcon icon={chatboxEllipsesOutline} />
                        </h3>
                      </IonText>
                      <h3 className="nospace-comments">
                        <CopyToClipboard text={decodeURIComponent(selectedItem.url)}>
                          <IonButton color="secondary" onClick={
                            () => presentAlert({
                                header: 'Message',
                                subHeader: '',
                                message: 'URL saved in clipboard.',
                                buttons: ['OK'],
                              })
                            }><IonIcon icon={shareSocialSharp} /></IonButton>
                        </CopyToClipboard>
                      </h3>
                      <h3 className="nospace-comments">
                        <IonButton color="secondary" onClick={
                          () => presentAlert({
                              header: 'Message',
                              subHeader: '',
                              message: 'Reporting is coming soon',
                              buttons: ['OK'],
                            })
                          }><IonIcon icon={alertCircle} /></IonButton>
                      </h3>
                      <h3 className="nospace-comments"><IonButton color="secondary" onClick={
                        () => presentAlert({
                            header: 'Message',
                            subHeader: '',
                            message: 'Vote Up is coming soon',
                            buttons: ['OK'],
                          })
                        }>{selectedItem.vote_up} <IonIcon icon={thumbsUp} /></IonButton></h3>
                      <h3 className="nospace-comments"><IonButton color="secondary" onClick={
                        () => presentAlert({
                            header: 'Message',
                            subHeader: '',
                            message: 'Vote Down is coming soon',
                            buttons: ['OK'],
                          })
                        }>{selectedItem.vote_dn} <IonIcon icon={thumbsDown} /></IonButton></h3>
                    </IonRow>
                    <IonRow onClick={() => openUrlContent(decodeURIComponent(selectedItem.url))}>
                      <IonCol size="12">
                        <IonText className="item-comment" color="primary">{decodeURIComponent(selectedItem.url)}</IonText>
                      </IonCol>
                    </IonRow>
                    <IonRow >
                      <IonCol size="12">
                        <IonText className="item-comment" color="primary">Date: {selectedItem.creation.replace('T', ' at ').split('.')[0]}</IonText>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>

                <IonCardContent id="comment-box">
                  <IonRow>
                    <IonCol>
                      <IonImg className="ad-img blurred" src={selectedItem.ad_path}></IonImg>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="12">
                      <IonText>Adding stars to the content stack the token</IonText>
                    </IonCol>
                  </IonRow>
                  <IonRow className="comment-block">
                    <IonRow className="comment-block-input">
                      <IonTextarea value={comment} placeholder="Enter your comment" onIonChange={e => setComment(e.detail.value!)}></IonTextarea>
                    </IonRow>
                    <IonRow className="comment-block-button">
                      <IonCol size="12" class="ion-text-center">
                        <IonButton onClick={() => sendComment(comment, selectedItem) }><IonIcon icon={checkmarkDone} slot="start" />Stack a star</IonButton>
                      </IonCol>
                    </IonRow>
                  </IonRow>

                  {/* <IonRow>
                    <IonCol>
                      <IonTitle className="modale-comment-title">
                        {selectedItem.email.split('%40')[0]} ad selected
                      </IonTitle>
                    </IonCol>
                  </IonRow> */}
                  <IonRow>
                    <IonCol>
                      {/* <IonTitle className="modale-comment-title">
                        {selectedItem.email.split('%40')[0]} Nebula comments
                      </IonTitle> */}
                      <IonTitle className="modale-comment-title">
                        Comments
                      </IonTitle>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      {
                        commentData &&
                        commentData.length === 0 &&
                        <IonText>There is no stars on this topic.</IonText>
                      }
                      {commentData.map((comment, index) => {
                        return (
                          <div key={'comment-star' + index}>
                            <IonGrid className="star-comment">
                              <IonRow>
                                <IonCol size="auto">
                                  <IonAvatar className="avatar-comment">
                                    <img src={'https://gravatar.com/avatar/' + (Math.random() * 10000).toFixed(0) + '?d=identicon&f=y'} alt="avatar" />
                                  </IonAvatar>
                                </IonCol>                          
                                <IonCol size="10">
                                  <IonRow className="header-comment">
                                    <IonCol size="auto">
                                      <IonText>{comment.creation}</IonText>
                                    </IonCol>
                                    <IonCol>
                                      <IonText>{comment.email}</IonText>
                                    </IonCol>
                                  </IonRow>
                                  <IonRow className="body-comment">
                                    <IonCol>
                                      <IonText className="item-text-stars">{comment.comment}</IonText>
                                    </IonCol>
                                  </IonRow>
                                </IonCol>
                              </IonRow>
                              <IonRow>
                                <IonCol size="12">
                                  <IonButton onClick={() => replyComment(comment, index) }>Reply</IonButton>
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          </div>
                        )
                      })}
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
            }
          </IonItem>
        </IonModal>
      </IonContent>

    </IonPage>
  );
};

export default Subscriptions;
