import { useState } from 'react';
import { IonContent, IonPage, IonIcon, IonCard, IonCardHeader, IonCardSubtitle, IonList, IonItem, IonLabel, IonButton, IonInput, IonToggle, IonAlert, IonLoading, useIonViewDidEnter, useIonViewWillLeave, useIonAlert, IonCol, IonGrid, IonImg, IonRow } from '@ionic/react';
import { eye, copy, arrowUpCircle, add, arrowDownCircle, refresh, wallet, expand } from 'ionicons/icons';
import {
  Token,
  TOKEN_PROGRAM_ID
} from '@solana/spl-token';
import {
  ComputeBudgetProgram,
  Connection,
  Keypair,
  LAMPORTS_PER_SOL,
  PublicKey,
  sendAndConfirmTransaction,
  SystemProgram,
  Transaction
} from '@solana/web3.js';
import UserService from '../../services/user';
import './Wallet.css';
import Config from '../../config/settings';
import CopyToClipboard from 'react-copy-to-clipboard';
import QRCode from 'react-qr-code';
import Accounts from '../../config/wallets';
import { WalletService } from './Wallet.svc';
import * as bip39 from "bip39";
import { Subscription } from 'rxjs';
import { Shared } from '../../shared/shared';

const Wallet: React.FC<{  shared?: Shared, userService?: UserService, isAndroidDevice: boolean }> = ({ shared, userService, isAndroidDevice }) => {

  const [email, setEmail] = useState<string>();
  const [security, setSecurity] = useState<string>();
  // const [connectedWalletPubkey, setConnectedWalletPubkey] = useState<string>();
  const [pubkey, setPubkey] = useState<string>();
  const [seedkey, setSeedkey] = useState<string>();
  const [seedkeyString, setSeedkeyString] = useState<string>('');
  const [remainingJoinDays, setRemainingJoinDays] = useState<string>('');
  const [walletBalanceSOL, setWalletBalanceSOL] = useState<string>('0.000');
  const [walletBalanceNSA, setWalletBalanceNSA] = useState<string>('0.000');
  const [walletBalanceNSAN, setWalletBalanceNSAN] = useState<string>('0.000');
  const [webTransacions = false, setWebTransactions] = useState<boolean>(false);
  const [needNSAaccount, setNeedNSAaccount] = useState<boolean>(true);
  const [needNSANaccount, setNeedNSANaccount] = useState<boolean>(true);
  const [showSendForm = false, setShowSendForm] = useState<boolean>(false);
  const [showDepositForm = false, setShowDepositForm] = useState<boolean>(false);
  const [showBuyForm = false, setShowBuyForm] = useState<boolean>(false);
  const [showLabelForm = false, setShowLabelForm] = useState<boolean>(true);
  const [showAlertSeedKey = false, setShowAlertSeedKey] = useState<boolean>(false);
  const [isLoadingWallet = false, setIsLoadingWallet] = useState<boolean>(false);
  const [platformChecked, setPlatformChecked] = useState(false);
  // const [nsanModal = false, showNSANModal] = useState<boolean>(false);
  const [reduction, setReduction] = useState<string>('0');
  const [remaining, setRemaining] = useState<string>('0');
  const [totalNSASent, setTotalNSASent] = useState<string>('0');
  const [totalNSANSent, setTotalNSANSent] = useState<string>('0');
  const [walletContent, setWalletContent] = useState<WalletContent[]>([]);
  const [amount, setAmount] = useState<string>('0');
  const [transactionAddress, setTransactionAddress] = useState<string>('');
  const [transactionAmount, setTransactionAmount] = useState<string>('0');
  const [preSaleStatus, setPreSaleStatus] = useState<string>('Closed');
  const [transactionFee, setTransactionFee] =  useState<number>(0);
  const [sendTransactionBtnDisabled = false, setSendTransactionBtnDisabled] = useState<boolean>(false);
  const [minSolToBuy, setSolLimit] = useState<number>(0);
  const [presentAlert] = useIonAlert();

  const decimals = 1000000000;
  const startPrice = 0.015727272;
  const accountPubkey = Config.accountPubkey;

  const connectionMainnet = new Connection(
    Config.mainnetQuicknode,
    'confirmed',
  );
  const walletService: WalletService = new WalletService();

  let userServiceSubscribe: Subscription;

  useIonViewDidEnter(async () => {
    userServiceSubscribe = userService.storageInitSubject.subscribe((result) => {
      if (result === true) {
        // recupération des données
        setPubkey(userService.pubkey);
        setSeedkey(userService.seedkey);
        setEmail(userService.email);
        setSecurity(userService.security);

        if (webTransacions === false) {  
          setWebTransactions(true);
          refreshWallet();
        }
      }
    });
    // setPreSaleStatus('Wait opening');
    setPreSaleStatus('Open');
  })

  useIonViewWillLeave(() => {
    if (userServiceSubscribe) {
      userServiceSubscribe.unsubscribe();
    }
  })

  /**
   * Explorer une adresse
   */
  function exploreAddress(address?: string) {
    if (pubkey) {
      window.open(`https://explorer.solana.com/address/${(address) ? address : userService.pubkey}?cluster=mainnet`);
    } else {
      presentAlert({
        header: 'Message',
        subHeader: '',
        message: 'You have to create a wallet on Solana Mainnet.',
        buttons: ['OK'],
      });
    }
  }

  /**
   * Afficher la box send
   */
  function showSend() {
    if (pubkey) {
      setShowBuyForm(false);
      setShowDepositForm(false);
      setShowLabelForm(false);
      if (showSendForm === false) {
        setShowSendForm(true);
      } else {
        setShowSendForm(false);
      }
    } else {
      presentAlert({
        header: 'Message',
        subHeader: '',
        message: 'You have to create a wallet on Solana Mainnet.',
        buttons: ['OK'],
      });
    }
  }

  /**
   * Afficher la box deposit
   */
  function showDeposit() {
    if (pubkey) {
      setShowBuyForm(false);
      setShowSendForm(false);
      setShowLabelForm(false);
      if (showDepositForm === false) {
        setShowDepositForm(true);
      } else {
        setShowDepositForm(false);
      }
    } else {
      presentAlert({
        header: 'Message',
        subHeader: '',
        message: 'You have to create a wallet on Solana Mainnet.',
        buttons: ['OK'],
      });
    }
  }

  /**
   * Afficher la box Join pre-sale
   */
  function showBuy() {
    if (pubkey) {
      if (showBuyForm === false) {
        setShowBuyForm(true);
      } else {
        setShowBuyForm(false);
      }
      setShowSendForm(false);
      setShowLabelForm(false);
      setShowDepositForm(false);

      updatePresaleData();
    } else {
      presentAlert({
        header: 'Message',
        subHeader: '',
        message: 'You have to create a wallet on Solana Mainnet by pressing the "Create" button.',
        buttons: ['OK'],
      });
    }
  }

  /**
   * Flouter les données du wallet
   */
  function hideValue() {
    const nsaValue = document.getElementById('compteur-value-nsa');
    const nsanValue = document.getElementById('compteur-value-nsan');
    const commonHistory = document.getElementById('common-history');

    if (nsaValue.classList.contains('compteur-hidden')) {
      commonHistory.classList.remove('compteur-hidden')
      nsaValue.classList.remove('compteur-hidden')
      nsanValue.classList.remove('compteur-hidden')
    } else {
      commonHistory.classList.add('compteur-hidden');
      nsaValue.classList.add('compteur-hidden');
      nsanValue.classList.add('compteur-hidden');
    }
  }

  /**
   * Récupération de la balance du wallet Solana
   */
  async function getSolanaBalance() {

    if (userService && userService.pubkey) {

      return walletService
        .getSolanaBalance(
          userService.pubkey
        ).then(
          async (res) => {
  
            const resultat = await res.json();
            
            let balance = (resultat?.result?.value) ? resultat?.result?.value : 0;
            
            // Parser la balance en valeur réelle
            traitementBalance(balance);
          }
        ).catch((_e) => {
          setIsLoadingWallet(false);
          presentAlert({
            header: 'Message',
            subHeader: '',
            message: 'error ' + _e,
            buttons: ['OK'],
          });
        });
    }
  }

  /**
   * Récupération des comptes du wallet Solana
   */
  async function getNsaTokenAccountsByOwner() {

    if (userService && userService.pubkey) {

      return walletService
        .getSolanaTokenAccountsByOwner(
          userService.pubkey,
          Config.tokenAddress
        ).then(
          async (res) => {
            const resultat = await res.json();
  
            const results = resultat?.result?.value;
          
            if (results) {

              results.forEach((item: any) => {
    
                const info = item.account.data.parsed.info;
                const ownerPubkey = item.account.pubkey;
  
                if (info.mint === Config.tokenAddress && !walletContent.find((predicate) => predicate.devise === info.mint)) {
                  let itemNsaParsed: WalletContent = {
                    devise: info.mint,
                    value: (info.tokenAmount.amount / decimals).toString(),
                    link: `https://explorer.solana.com/address/${ownerPubkey}?cluster=mainnet`,
                    icon: arrowUpCircle
                  };
                  const balanceNSA: number = info.tokenAmount.amount / decimals;
                  itemNsaParsed.devise = 'NSA';
                  itemNsaParsed.value = balanceNSA.toString();
                  itemNsaParsed.link = `https://explorer.solana.com/address/${ownerPubkey}?cluster=mainnet`;
                  itemNsaParsed.icon = arrowUpCircle
                  setWalletBalanceNSA(balanceNSA.toString());
                  setNeedNSAaccount(false);
    
                  setWalletContent(walletContent => [...walletContent, itemNsaParsed])
                }
              });
            } else {
              setWalletBalanceNSA('0.00');
            }
          }
        ).catch((_e) => {
          setIsLoadingWallet(false);
          presentAlert({
            header: 'Message',
            subHeader: '',
            message: 'error ' + _e,
            buttons: ['OK'],
          });
        });
    }
  }

  /**
   * Récupération des comptes du wallet Solana
   */
  async function getNsanTokenAccountsByOwner() {

    if (userService && userService.pubkey) {

      return walletService
        .getSolanaTokenAccountsByOwner(
          userService.pubkey,
          Config.tokenNSANAddress
        ).then(
          async (res) => {
            const resultat = await res.json();
  
            const results = resultat?.result?.value;

            if (results) {
          
              results.forEach((item: any) => {
    
                const info = item.account.data.parsed.info;
                const ownerPubkey = item.account.pubkey;
    
                if (info.mint === Config.tokenNSANAddress) {
                  let itemNsanParsed: WalletContent = {
                    devise: info.mint,
                    value: (info.tokenAmount.amount / decimals).toString(),
                    link: `https://explorer.solana.com/address/${ownerPubkey}?cluster=mainnet`,
                    icon: arrowUpCircle
                  };
                  const balanceNSA: number = info.tokenAmount.amount / decimals;
                  itemNsanParsed.devise = 'NSAN';
                  itemNsanParsed.value = balanceNSA.toString();
                  itemNsanParsed.link = `https://explorer.solana.com/address/${ownerPubkey}?cluster=mainnet`;
                  itemNsanParsed.icon = arrowUpCircle
                  setWalletBalanceNSAN(balanceNSA.toString());
                  setNeedNSANaccount(false);
    
                  setWalletContent(walletContent => [...walletContent, itemNsanParsed])
                }
              });
            } else {
              setWalletBalanceNSAN('0.00');
            }
          }
        ).catch(() => setIsLoadingWallet(false))
        .finally(() => setIsLoadingWallet(false));
    }
  }

  /**
   * Mise à jour des données de balance du wallet
   * @param balance 
   */
  function traitementBalance(balance: number) {
    const balanceString: string = (balance / decimals).toString();

    setWalletBalanceSOL(balanceString);

    // Ajout à la liste des crypto dispo dans le wallet
    const solWallet: WalletContent = {
      devise: 'SOL',
      value: balanceString.toString(),
      link: `https://explorer.solana.com/address/${pubkey}?cluster=mainnet`,
      icon: arrowUpCircle
    };

    setWalletContent(walletContent => [...walletContent, solWallet])
  }

  /**
   * Traitement des données de pre-sale
   * @param total 
   */
  function traitementBuyData(total: any) {

    let tokenNSAInDevWallet: any = null;
    let tokenNSANInDevWallet: any = null;
    let totalNSAInDevWallet: any = 0;
    let totalNSANInDevWallet: any = 0;

    if (total && total.result && total.value) {
      tokenNSAInDevWallet = total.result.value?.find((token: any) => token.account.data.parsed.info.mint === Config.tokenAddress);
      tokenNSANInDevWallet = total.result.value?.find((token: any) => token.account.data.parsed.info.mint === Config.tokenNSANAddress);
    } else if (total && total.value) {
      tokenNSAInDevWallet = total?.value?.find((token: any) => token.account.data.parsed.info.mint === Config.tokenAddress);
      tokenNSANInDevWallet = total?.value?.find((token: any) => token.account.data.parsed.info.mint === Config.tokenNSANAddress);
    }

    if (userService && tokenNSAInDevWallet && tokenNSANInDevWallet) {
      
      totalNSAInDevWallet = tokenNSAInDevWallet.account?.data?.parsed?.info?.tokenAmount?.amount;
      totalNSANInDevWallet = tokenNSANInDevWallet.account?.data?.parsed?.info?.tokenAmount?.amount;
      
      const baseUrl = (Config.debugMode === false) ? Config.url : Config.urlDebug;
      const url = baseUrl + 'gettoken?email=' + userService.email + '&security=' + userService.security + '&v=' + Config.version;

      fetch(url).then(
        async (res) => {
          const result = await res.json();

          const totalInDevDatabase = result['total'] === null ? 0 : result['total'];
          
          const totalNSANSentFromAccount = 220000 - (totalNSANInDevWallet / 1000000000);
          const totalSentFromAccount = 1100000000 - (totalNSAInDevWallet / 1000000000);
          const totalSentFromAll = totalSentFromAccount + totalInDevDatabase;
          const remaining = 99000000 - totalSentFromAll;
          const onePercent = 99000000 / 50;
          // const reduction = 50 - Math.round(totalSentFromAll / onePercent);
          const reduction = 20;
          
          console.log('totalNSANSentFromAccount', totalNSANSentFromAccount);

          setReduction(reduction.toString());
          setRemaining(remaining.toString());
          setTotalNSASent(totalSentFromAll.toString());
          setTotalNSANSent(totalNSANSentFromAccount.toString());
          tokenNSAInDevWallet = total?.value?.find((token: any) => token.account.data.parsed.info.mint === Config.tokenAddress);

          return remaining;
        }
      ).catch((_e) => {
        console.log('debug: _e', _e);
        setIsLoadingWallet(false);
      }).finally(() => {
        setIsLoadingWallet(false);
      }
      );
    } else {
      setIsLoadingWallet(true);
    }
  }

  /**
   * Création du wallet in-app
   * @param email 
   * @param security 
   */
  function createWallet(email: any, security: any) {
    const baseUrl = (Config.debugMode === false) ? Config.url : Config.urlDebug;
    const url = baseUrl + 'create-wallet?email=' + userService.email + '&security=' + userService.security + '&v=' + Config.version;
  
    fetch(url).then(
      async (res) => {
        const result: any = await res.json();

        if (result && result.msg && (result.msg.indexOf('Nothing') > -1)) {
          presentAlert({
            header: 'Message',
            subHeader: '',
            message: 'Account not valid.',
            buttons: ['OK'],
          });
        } else if (result && result.msg && (result.msg.indexOf('already') > -1)) {
          // TODO: récup la pubkey côté server
          setPubkey(await userService?.getPubkey());
          presentAlert({
            header: 'Message',
            subHeader: '',
            message: `In-App Wallet already created. Your pubkey is: ${userService.pubkey}`,
            buttons: ['OK'],
          });
        } else if (result && result.pubkey && result.pubkey !== null) {

          setSeedkeyString(result.seedkey);
          setSeedkey(result.seedkey);
          setPubkey(result.pubkey);

          userService?.setSeedkey(result.seedkey);
          userService?.setPubkey(result.pubkey);

          // afficher la popin de seedkey
          setTimeout(() => {
            setShowAlertSeedKeyValue(true);
          }, 1000);

          // rafraichir le wallet
          setTimeout(() =>{
            refreshWallet();
          }, 2500);
        }
      })
      .catch((_e) => {
        presentAlert({
          header: 'Message',
          subHeader: '',
          message: `Please connect your device to Internet. If you are already connected, servers may be temporarily down or application need to be updated. ${_e}`,
          buttons: ['OK'],
        });
      });
  }

  /**
   * Modification du bouton de sauvegrade de Seed Key
   * @param value 
   */
  function setShowAlertSeedKeyValue(value: boolean) {
    setShowAlertSeedKey(value);
    if (value) {
      const interval = setInterval(() => {
        const seedKeyAlert = document.getElementById('seedKeyAlert');

        if (seedKeyAlert !== null && seedKeyAlert.children[2]) {
          clearInterval(interval);

          const childNode = seedKeyAlert.children[2];
          const childChildNode = childNode.children[2];
          const childButtonNode = childChildNode.children[0];

          childButtonNode.setAttribute('disabled', 'disabled');
          const childButtonText = childButtonNode.children[0];
          childButtonText.innerHTML = 'Please wait 10 seconds !';

          setTimeout(() => {
            childButtonNode.removeAttribute('disabled');
            childButtonText.innerHTML = 'Yes, i have saved my Seed Key';
          }, 10000);
        }

      }, 150);
    }
  }

  /**
   * Bouton validation de sauvegarde de Seed Key
   * @returns 
   */
  function getAlertButton() {
    return [
      {
        text: 'Yes, i have saved my Seed Key',
        handler: () => {
          console.log('Seed key alert done');
        }
      }
    ];
  }

  /**
   * Affichage du messge de confirmation de création du wallet
   * @returns 
   */
  function getAlertMessage() {
    // const seedkeyObject = seedkeyString.split(' ');
    return 'In-App Wallet created with success on Solana blockchain ! Please backup your wallet seed key, write it down, THIS KEY IS SAVED TO YOUR APP DATA BUT CANNOT BE RECOVERED SO WRITE IT AND KEEP IT IN SAFE PLACE, NEVER TRANSMIT THIS DATA TO ANYBODY. Your Seed Key is: ' + seedkeyString;
  }

  /**
   * Calcul du montanr "pre-sale" suivant le bouton choisi
   * @param amount 
   */
  function getAmount(amount: String) {
    // TODO: à fusionner avec laméthode en dessous
    let total = Number(walletBalanceSOL);

    switch (amount) {
      case 'max':
        setAmount(total.toString());
        break;
        
      case 'half':
        const half: string = (Math.round(((total / 2) + Number.EPSILON) * 100) / 100).toString();
        setAmount(half);
        break;
        
      case 'quarter':
        const quarter: string = (Math.round(((total / 4) + Number.EPSILON) * 100) / 100).toString();
        setAmount(quarter);
        break;
    
      default:
        setAmount('0.00');
        break;
    }
  }

  /**
   * Calcul du montant "send" suivant le bouton choisi
   * Max
   * Half
   * Quarter
   * @param amount 
   */
  function getTransactionAmount(amount: String) {
    
    let total = Number(walletBalanceSOL);

    switch (amount) {
      case 'max':
        setTransactionAmount(total.toString());
        break;
        
      case 'half':
        const half: string = (Math.round(((total / 2) + Number.EPSILON) * 100) / 100).toString();
        setTransactionAmount(half);
        break;
        
      case 'quarter':
        const quarter: string = (Math.round(((total / 4) + Number.EPSILON) * 100) / 100).toString();
        setTransactionAmount(quarter);
        break;
    
      default:
        setTransactionAmount('0.00');
        break;
    }
  }

  /**
   * Méthode pour acheter des NSA en pre-sale
   */
  async function buyTokens() {
    const solValues = await totalPresale();
    const solLimit = solValues['solLimit'];
    const solUsd = solValues['solUsd'];

    // const solUsd: number = await walletService.getSolUsdPrice();
    // const limit = 50; // Limite minimum 50$
    // let solLimit: number = limit / solUsd;
    const amountToSend = Number(amount);

    if (amountToSend > Number(walletBalanceSOL)) {
      presentAlert({
        header: 'Message',
        subHeader: '',
        message: `You need a minimum of ${amount}  SOL in your wallet`,
        buttons: ['OK'],
      });
    } else if (amount === '0') {
      presentAlert({
        header: 'Message',
        subHeader: '',
        message: 'You need more than 0 SOL to get NSA tokens',
        buttons: ['OK'],
      });
    } else {
      if (amountToSend >= solLimit) {
        const receiver = new PublicKey(Accounts.sol);
        const seedBip39 = bip39.mnemonicToSeedSync(seedkey, ""); // (mnemonic, password)
        const keypair = Keypair.fromSeed(seedBip39.slice(0, 32));

        // const mnemonic: string = walletService.generateMnemonic();
        // const wallet: any = walletService.generateWallet(mnemonic);
        // const keypair = wallet.keypair;

        const fee = await shared.calculateFeeTransfer(keypair);
        
        const priceWithReduction = startPrice - (startPrice * Number(reduction) / 100);
        const solAmountInUsd = (amountToSend - fee) * solUsd;
  
        const totalBought = solAmountInUsd / priceWithReduction;
  
        presentAlert({
          header: 'Message',
          subHeader: '',
          message: `Current Solana USD price is: $ ${solUsd}`,
          buttons: ['OK'],
        });

        presentAlert({
          header: 'Message',
          subHeader: '',
          message: `Your Solana amount represent $ ${solAmountInUsd}`,
          buttons: ['OK'],
        });

        presentAlert({
          header: 'Message',
          subHeader: '',
          message: `Current NSA price with reduction of ${reduction}% is: $ ${priceWithReduction}`,
          buttons: ['OK'],
        });
  
        alert('You want to send ' + amountToSend + ' SOL with ' + fee + ' SOL fees, to get NSA tokens with a reduction of ' + reduction
              + '% with the account ' + userService.email + ' with publicKey ' + userService.pubkey + '. This represent a total of ' + totalBought + ' NSA');

        await transferSolOrPreSale(keypair, receiver, fee, amountToSend, totalBought, Number(reduction), true);

      } else {
        presentAlert({
          header: 'Message',
          subHeader: '',
          message: `Minimum amount to get NSA tokens is: ${solLimit} SOL`,
          buttons: ['OK'],
        });
      }
    }
  }

  /**
   * Méthode "send" pour transférer des fonds Sol
   */
  async function sendTransaction() {
    // TODO: gérer l'interrupteur plateforme
    if (platformChecked) {
      presentAlert({
        header: 'Message',
        subHeader: '',
        message: 'You need to be a Tester to interact with the Platform service.',
        buttons: ['OK'],
      });
    } else {
      const solUsd: number = await walletService.getSolUsdPrice();
      const amountToSend = Number(transactionAmount);
    
      totalPresale();
  
      if (amountToSend > Number(walletBalanceSOL)) {
        presentAlert({
          header: 'Message',
          subHeader: '',
          message: `You need to have ${amount} SOL in your wallet`,
          buttons: ['OK'],
        });
      } else if (transactionAmount === '0') {
        presentAlert({
          header: 'Message',
          subHeader: '',
          message: 'You need to send more than 0 SOL',
          buttons: ['OK'],
        });
      } else {
        if (transactionAddress && transactionAddress.length > 0) {
          const receiver = new PublicKey(transactionAddress);
          const seedBip39 = bip39.mnemonicToSeedSync(seedkey, ""); // (mnemonic, password)
          const keypair = Keypair.fromSeed(seedBip39.slice(0, 32));
  
          // const mnemonic: string = walletService.generateMnemonic();
          // const wallet: any = walletService.generateWallet(mnemonic);
          // const keypair = wallet.keypair;
          
          const fee = await shared.calculateFeeTransfer(keypair);
          
          const priceWithReduction = startPrice - (startPrice * Number(reduction) / 100);
          const solAmountInUsd = (amountToSend - fee) * solUsd;
    
          const totalBought = solAmountInUsd / priceWithReduction;
    
          presentAlert({
            header: 'Message',
            subHeader: '',
            message: `You want to send ${amountToSend} SOL with fee of ${fee} SOL to ${receiver} with the account ${userService.email} with publicKey ${userService.pubkey} .`,
            buttons: ['OK'],
          });
    
          await transferSolOrPreSale(keypair, receiver, fee, amountToSend, totalBought, Number(reduction), false);

        } else {
          presentAlert({
            header: 'Message',
            subHeader: '',
            message: 'Please enter a destination address.',
            buttons: ['OK'],
          });
        }
      }
    }
  }

  /**
   * Create NSA asset dans le wallet
   */
  async function createNSAaccount() {
    
    const mintPublicKey = new PublicKey(Config.tokenAddress);
    const seedBip39 = bip39.mnemonicToSeedSync(seedkey, ""); // (mnemonic, password)
    const keypair = Keypair.fromSeed(seedBip39.slice(0, 32));
    
    const fee = await shared.calculateFeeTransfer(keypair);

    presentAlert({
      header: 'Message',
      subHeader: '',
      message: `This feature create a NSA asset in your wallet. To create this asset you need some SOL for the transaction and for the fee. The current fee is: ${fee}. If you get any error please contact support on Telegram channel.`,
      buttons: ['OK'],
    });

    const mintToken = new Token(
      connectionMainnet,
      mintPublicKey,
      TOKEN_PROGRAM_ID,
      keypair
    );

    let fromTokenAccount = null;

    if (needNSAaccount && Number(walletBalanceSOL) > 0.02) {

      fromTokenAccount = await mintToken.getOrCreateAssociatedAccountInfo(
        keypair.publicKey
      );

      const toTokenAccount = await mintToken.getOrCreateAssociatedAccountInfo(
        keypair.publicKey
      );
      
      const modifyComputeUnits = ComputeBudgetProgram.setComputeUnitLimit({
        units: 2200000
      });

      const addPriorityFee = ComputeBudgetProgram.setComputeUnitPrice({
        microLamports: 130000
      });

      const recentBlockhash = await connectionMainnet.getLatestBlockhash();

      // Add token transfer instructions to transaction
      var transaction = new Transaction({
        recentBlockhash: recentBlockhash.blockhash,
      })
      .add(modifyComputeUnits)
      .add(addPriorityFee)
      .add(
        Token.createTransferInstruction(
          TOKEN_PROGRAM_ID,
          fromTokenAccount.address,
          toTokenAccount.address,
          keypair.publicKey,
          [],
          0
        )
      );

      // Sign transaction, broadcast, and confirm
      setIsLoadingWallet(true);
      await sendAndConfirmTransaction(
        connectionMainnet,
        transaction,
        [keypair],
        {
          skipPreflight: true,
          maxRetries: 0
        }
      ).then((result) => {
        setIsLoadingWallet(false);

        presentAlert({
          header: 'Message',
          subHeader: '',
          message: 'NSA asset created in your wallet. Wait 5s and refresh your wallet. Don\'t press the button a second time.',
          buttons: ['OK'],
        });
        
        setNeedNSAaccount(false);
        setTimeout(() =>{
          refreshWallet();
        }, 5000);
      }).catch((error) => {
        console.log('debug:error', error.message);
        presentAlert({
          header: 'Message',
          subHeader: '',
          message: `Cannot create NSA asset in the wallet: ${error.message}`,
          buttons: ['OK'],
        });
      });
      setIsLoadingWallet(false);
    } else if (needNSAaccount && Number(walletBalanceSOL) < 0.02) {
      presentAlert({
        header: 'Message',
        subHeader: '',
        message: 'You need more than 0.02 SOL in your wallet',
        buttons: ['OK'],
      });
    } else {
      presentAlert({
        header: 'Message',
        subHeader: '',
        message: 'NSA asset already available in your wallet',
        buttons: ['OK'],
      });
    }
  }

  /**
   * Create NSA asset dans le wallet
   */
  async function createNSANaccount() {
    
    const mintPublicKey = new PublicKey(Config.tokenNSANAddress);
    const seedBip39 = bip39.mnemonicToSeedSync(seedkey, ""); // (mnemonic, password)
    const keypair = Keypair.fromSeed(seedBip39.slice(0, 32));
    
    const fee = await shared.calculateFeeTransfer(keypair);

    presentAlert({
      header: 'Message',
      subHeader: '',
      message: `This feature create a NSAN asset in your wallet. To create this asset you need some SOL for the transaction and for the fee. The current fee is: ${fee} If you get any error please contact support on Telegram channel.`,
      buttons: ['OK'],
    });

    const mintToken = new Token(
      connectionMainnet,
      mintPublicKey,
      TOKEN_PROGRAM_ID,
      keypair
    );

    let fromTokenAccount = null;

    if (needNSANaccount && Number(walletBalanceSOL) > 0.02) {

      fromTokenAccount = await mintToken.getOrCreateAssociatedAccountInfo(
        keypair.publicKey
      );

      const toTokenAccount = await mintToken.getOrCreateAssociatedAccountInfo(
        keypair.publicKey
      );

      // Add token transfer instructions to transaction
      var transaction = new Transaction()
      .add(
        Token.createTransferInstruction(
          TOKEN_PROGRAM_ID,
          fromTokenAccount.address,
          toTokenAccount.address,
          keypair.publicKey,
          [],
          0
        )
      );
      
      const modifyComputeUnits = ComputeBudgetProgram.setComputeUnitLimit({
        units: 1000000
      });

      const addPriorityFee = ComputeBudgetProgram.setComputeUnitPrice({
        microLamports: 50000
      });
      
      transaction.add(modifyComputeUnits);
      transaction.add(addPriorityFee);

      // Sign transaction, broadcast, and confirm
      setIsLoadingWallet(true);
      await sendAndConfirmTransaction(
        connectionMainnet,
        transaction,
        [keypair]
      ).then((result) => {
        setIsLoadingWallet(false);

        presentAlert({
          header: 'Message',
          subHeader: '',
          message: 'NSAN asset created in your wallet. Wait 5s and refresh your wallet. Don\'t press the button a second time.',
          buttons: ['OK'],
        });
        
        setNeedNSANaccount(false);
        setTimeout(() =>{
          refreshWallet();
        }, 5000);
      }).catch((error) => {
        console.log('debug:error', error.message);
        presentAlert({
          header: 'Message',
          subHeader: '',
          message: `Cannot create NSAN asset in the wallet: ${error.message}`,
          buttons: ['OK'],
        });
      });
      setIsLoadingWallet(false);
    } else if (needNSANaccount && Number(walletBalanceSOL) < 0.02) {
      presentAlert({
        header: 'Message',
        subHeader: '',
        message: 'You need more than 0.02 SOL in your wallet',
        buttons: ['OK'],
      });
    } else {
      presentAlert({
        header: 'Message',
        subHeader: '',
        message: 'NSA asset already available in your wallet',
        buttons: ['OK'],
      });
    }
  }


  /**
   * Transfert de SOL ou Transfert pour presale
   * @param keypair 
   * @param to 
   * @param fee 
   * @param amount 
   * @param totalBought 
   * @param reduction 
   * @param isPreSale 
   * @param isPlatform
   */
  async function transferSolOrPreSale(keypair: Keypair, to: PublicKey, fee: number, amount: number, totalBought: number, reduction: number, isPreSale: boolean = true, isPlatform: boolean = false) {
    // TODO: Gérer la partie transfert de fond vers la plateforme
    const lamportsAvecFee = Math.round(LAMPORTS_PER_SOL * (amount - fee));

    console.log("debug: transferSolAndPreSale", keypair, to, amount, fee, lamportsAvecFee, totalBought, reduction, isPreSale);

    setSendTransactionBtnDisabled(true);
    let transaction: any = null;

    if (keypair && to && fee && amount) {
      //if (!isAndroidDevice) {
      transaction = new Transaction().add(
        SystemProgram.transfer({
            fromPubkey: keypair.publicKey,
            toPubkey: to,
            lamports: lamportsAvecFee,
        })
      );
  
      setIsLoadingWallet(true);
  
      // Sign transaction, broadcast, and confirm
      await sendAndConfirmTransaction(
          connectionMainnet,
          transaction,
          [keypair]
      ).then((signature) => {
        console.log('signature', signature);
        setIsLoadingWallet(false);
  
        if (isPreSale) {
          if (signature) {
            presentAlert({
              header: 'Message',
              subHeader: '',
              message: 'Transaction was send.',
              buttons: ['OK'],
            });
            window.open('https://explorer.solana.com/tx/' + signature + '?cluster=mainnet');
            presentAlert({
              header: 'Message',
              subHeader: '',
              message: 'Your transaction data are going to be saved to the server queue. You will receive your NSA in the next 24/48h. The tokens are not transferred automatically, the procedure is manual and takes some time, thank you for your comprehension. For any problem please contact support on Telegram.',
              buttons: ['OK'],
            });
      
            const baseUrl = (Config.debugMode === false) ? Config.url : Config.urlDebug;
            const url = baseUrl + 'buytoken?email=' + userService.email + '&security=' + userService.security + '&signature=' + signature + '&totalbought=' + totalBought + '&reduction=' + reduction + '&v=' + Config.version;
      
            fetch(url).then(async (res) => {
              const result: any = await res.json();
      
              if (result && result.msg && (result.msg.indexOf('Invalid') > -1)) {
                presentAlert({
                  header: 'Message',
                  subHeader: '',
                  message: 'Account not valid.',
                  buttons: ['OK'],
                });
              } else if (result && result.msg && (result.msg.indexOf('activation') > -1)) {
                presentAlert({
                  header: 'Message',
                  subHeader: '',
                  message: `Your transaction data are saved to the server queue. Your transaction signature is "${signature}", email: ${email} for a total of ${totalBought} NSA.`,
                  buttons: ['OK'],
                });
              }
            })
            .catch((_e) => {
              presentAlert({
                header: 'Message',
                subHeader: '',
                message: `Please connect your device to Internet. If you are already connected, servers may be temporarily down or application need to be updated. ${_e}`,
                buttons: ['OK'],
              });
            })
            .finally(() => {
            });
      
          } else {
            presentAlert({
              header: 'Message',
              subHeader: '',
              message: 'Transaction error.',
              buttons: ['OK'],
            });
            window.open('https://explorer.solana.com/tx/' + signature + '?cluster=mainnet');
          }
        } else {
          if (signature) {
            console.log('signature', signature);
            presentAlert({
              header: 'Message',
              subHeader: '',
              message: 'Transaction was send.',
              buttons: ['OK'],
            });
            window.open('https://explorer.solana.com/tx/' + signature + '?cluster=mainnet');
          }
        }
      }).catch(() => {
        presentAlert({
          header: 'Message',
          subHeader: '',
          message: 'Transaction error, please contact support.',
          buttons: ['OK'],
        });
      }).finally(() => {
        setIsLoadingWallet(false);
        setSendTransactionBtnDisabled(false);
        setTimeout(() => {
          refreshWallet();
        }, 4000)
      });
    } else {
      if (!to) {
        presentAlert({
          header: 'Message',
          subHeader: '',
          message: 'Please enter a destination address.',
          buttons: ['OK'],
        });
      } else if (!amount) {
        presentAlert({
          header: 'Message',
          subHeader: '',
          message: 'Please enter an amount.',
          buttons: ['OK'],
        });
      }
    }

    console.log("debug: Transaction", transaction);
  }

  /**
   * Calcul du total pre-sale pour afficher les données
   */
  async function totalPresale() {

    const solUsd: number = await walletService.getSolUsdPrice();
    const limit = 13; // Limite minimum 25$
    let solLimit: number = limit / solUsd;
    
    setIsLoadingWallet(true);

    connectionMainnet
      .getParsedTokenAccountsByOwner(
        new PublicKey(accountPubkey),
        { programId: TOKEN_PROGRAM_ID }
      )
      .then((allAccountsInWallet) => {
        traitementBuyData(allAccountsInWallet);
      }
    );

    setSolLimit(solLimit);
    
    return {
      solLimit: solLimit,
      solUsd: solUsd
    };
  }

  /**
   * Mise à jour des données affichées pour le pre-sale
   */
  function updatePresaleData() {
    setIsLoadingWallet(true);
    totalPresale();
  }

  /**
   * Mise à jour des données du portefeuille
   */
  async function refreshWallet() {
    setWalletContent([]);

    setIsLoadingWallet(true);
    console.log('start loading wallet');

    await getSolanaBalance();
    await getNsaTokenAccountsByOwner();
    await getNsanTokenAccountsByOwner();

    setIsLoadingWallet(false);
    console.log('wallet loading done');

  }

  function manualRefreshWallet() {

    const refreshButton = document.getElementById('refresh-button');
    const refreshButtonIsDisabled = refreshButton.attributes.getNamedItem('disabled');
    const refreshButtonDefaultInner = refreshButton.innerHTML;

    if (!refreshButtonIsDisabled || refreshButtonIsDisabled?.nodeValue === 'false') {
      refreshButton.setAttribute('disabled', 'true');
      refreshWallet();
    }

    // Décompte du temps de disabled
    let temps = 5;
    const timer = setInterval(() => {
      if (temps === 0) {
        refreshButton.innerHTML = refreshButtonDefaultInner;
        refreshButton.setAttribute('disabled', 'false');
        clearInterval(timer);
      } else {
        refreshButton.innerHTML = temps.toString();
        temps--;
      }
    }, 1000);

  }

  /**
   * Récupérer le nombre de jours restant avant fermeture
   */
  function getRemainingJoinDays() {
    let remainingDays = ((Date.parse('2023-07-01') - Date.now()) / (1000 * 60 * 60 * 24)).toFixed(0);
    console.log('remaining', remainingDays);
    if (Number(remainingDays) < 0) {
      setPreSaleStatus('Closed');
      remainingDays = '0';
    }
    
    setRemainingJoinDays(remainingDays);
  }

  return (
    <IonPage>
      <IonContent fullscreen>

        {/* Barre d'entête */}
        <IonGrid className="header-bar">
          <IonRow className="header-bar-block">
            <IonCol size="2" className="header-bar-img-block">
              <IonImg className="header-bar-img" src="/assets/icon/new_icon.png" />
            </IonCol>
            <IonCol size="12" className="header-bar-label-block">
              <IonLabel className="header-bar-label">
                <h1 className="big-title">WALLET</h1>
              </IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>

        {
          isLoadingWallet &&
          <IonLoading
            cssClass='my-custom-class'
            isOpen={isLoadingWallet}
            message={'Loading'}
            duration={0}
            spinner={'dots'}
          />
        }

        <div className="container-wallet">

          <div className="container-wallet-common">

            <IonAlert
              id="seedKeyAlert"
              isOpen={showAlertSeedKey}
              header={'Wallet'}
              subHeader={'Backup your Seed Key'}
              message={getAlertMessage()}
              buttons={getAlertButton()}
              backdropDismiss={false}
            />

            <IonButton color="primary" className="position-icon-eye" onClick={() => hideValue()}>
              <IonIcon icon={eye} />
            </IonButton>
            <IonButton color="primary" id="refresh-button" className="position-icon-refresh" onClick={() => manualRefreshWallet()}>
              <IonIcon icon={refresh} />
            </IonButton>

            <p className="compteur-text">Wallet balance:</p>
            <p className="compteur-valeur"><span id="compteur-value-nsa">{walletBalanceNSA} </span>NSA </p>
            <p className="compteur-valeur"><span id="compteur-value-nsan">{walletBalanceNSAN} </span>NSAN </p>

            <p className="compteur-hash">Public Key: <span id="wallet-address" onClick={
              () => { exploreAddress(userService.pubkey) }
            }>{userService.pubkey ?? 'NO WALLET CREATED, click on Create button'}</span> <CopyToClipboard text={userService.pubkey}>
              <IonIcon className="icon-icon-list" icon={copy} onClick={
                () => { presentAlert({
                  header: 'Message',
                  subHeader: '',
                  message: `Wallet address "${userService.pubkey}" was saved to clipboard`,
                  buttons: ['OK'],
                }) }
                } /></CopyToClipboard>
            </p>

            <div className="common-buttons">
              <IonCard onClick={() => showSend()}>
                <IonIcon color="primary" className="icon-icon-list" icon={arrowUpCircle} />
                <IonCardHeader>
                  <IonCardSubtitle>Send</IonCardSubtitle>
                </IonCardHeader>
              </IonCard>

              <IonCard onClick={() => showDeposit()}>
                <IonIcon color="primary" className="icon-icon-list" icon={arrowDownCircle} />
                <IonCardHeader>
                  <IonCardSubtitle>Deposit</IonCardSubtitle>
                </IonCardHeader>
              </IonCard>

              { (userService.email && userService.email.indexOf('ogle.bot') === -1) &&
                <IonCard onClick={() => showBuy()}>
                  <IonIcon color="primary" className="icon-icon-list" icon={add} />
                  <IonCardHeader>
                    <IonCardSubtitle><b>BUY</b></IonCardSubtitle>
                  </IonCardHeader>
                </IonCard>
              }

              <IonCard onClick={() => createWallet(email, security)}>
                <IonIcon color="primary" className="icon-icon-list" icon={wallet} />
                <IonCardHeader>
                  <IonCardSubtitle>Create</IonCardSubtitle>
                </IonCardHeader>
              </IonCard>

              <IonCard onClick={() => exploreAddress()}>
                <IonIcon color="primary" className="icon-icon-list" icon={expand} />
                <IonCardHeader>
                  <IonCardSubtitle>Info</IonCardSubtitle>
                </IonCardHeader>
              </IonCard>

            </div>

            {
              needNSAaccount && userService.pubkey &&
                <div className="compteur-adresse">
                  <IonButton color="secondary" onClick={() => createNSAaccount()}>Create NSA asset in the wallet</IonButton>
                </div>
            }

            {
              needNSANaccount && userService.pubkey &&
                <div className="compteur-adresse color-black">
                  <IonButton color="primary" onClick={() => createNSANaccount()}>Create NSAN asset in the wallet</IonButton>
                </div>
            }

            {/* { !showSendForm && !showDepositForm && (userService.email && userService.email.indexOf('ogle.bot') === -1) &&
              <p className="presale-advert">To buy NSA token before market start is available, <br />click <u>BUY button</u>. An in-app wallet with some SOL in it is required. {needNSAaccount ? 'To create the NSA asset in your wallet press the "Create NSA asset in the wallet" button. If you need help, contact support on Telegram thanks.'  : ''}</p>
            } */}

            { showSendForm &&
              <div className='send'>

                <IonItem>
                  <IonLabel>{platformChecked ? 'Transfer to Platform' : 'Transfer SOL to Address'}</IonLabel>
                  <IonToggle color="secondary" disabled={true} checked={platformChecked} onIonChange={e => setPlatformChecked(e.detail.checked)} name={'platform'}/>
                </IonItem>
                
                { !platformChecked &&
                  <IonItem>
                    <IonLabel position="floating">Enter Address:</IonLabel>
                    <IonInput placeholder="Enter receiver address..." value={transactionAddress}  onIonChange={e => setTransactionAddress(e.detail.value!)} type={'text'}></IonInput>
                  </IonItem>
                }

                <IonItem>
                  <IonLabel position="floating">Enter Amount:</IonLabel>
                  <IonInput placeholder="Enter the amount..." value={transactionAmount}  onIonChange={e => setTransactionAmount(e.detail.value!)} type="number"></IonInput>
                </IonItem>

                <IonButton className="amount-button" color="secondary" onClick={() => getTransactionAmount('max')}>Maximum</IonButton>
                <IonButton className="amount-button" color="secondary" onClick={() => getTransactionAmount('half')}>Half</IonButton>
                <IonButton className="amount-button" color="secondary" onClick={() => getTransactionAmount('quarter')}>Quarter</IonButton>

                <IonButton className="transaction" color="secondary" disabled={sendTransactionBtnDisabled} onClick={() => sendTransaction()}><IonIcon icon={wallet} /> Send transaction</IonButton>
              </div>
            }

            { showDepositForm &&
              <div className="deposit">
                <p className="title">Your SOL and NSA address:</p>
                <p className="pubkey-hash">{userService.pubkey} <CopyToClipboard text={userService.pubkey}>
                  <IonIcon className="icon-icon-list" icon={copy} onClick={
                    () => { presentAlert({
                      header: 'Message',
                      subHeader: '',
                      message: `Wallet address "${userService.pubkey}" was saved to clipboard`,
                      buttons: ['OK'],
                    }) }
                    } /></CopyToClipboard>
                </p>
                <div className="qrcode-background">
                  <QRCode
                    className="qrcode"
                    value={userService.pubkey}
                  />
                </div>
              </div>
            }

            { showBuyForm &&
              <div className='buy'>

                <IonButton className="transaction" color="secondary" onClick={() => updatePresaleData()}>Update service data</IonButton>
                <p className="sub-label-title first">Service status is: <u>{preSaleStatus}</u>.</p>
                {/* <p className="sub-label-title middle">Remaining: <b>{remainingJoinDays}</b> days.</p> */}
                <p className="sub-label-title middle">Min. SOL to buy NSA:<br/><b>SOL {minSolToBuy.toFixed(4)}</b></p>
                <p className="sub-label-title middle">Current discount:<br/><b>{reduction}%</b></p>
                <p className="sub-label-title middle">Discount price for 1 NSA:<br/><b>USDT {(startPrice / 100 * (100 - Number(reduction))).toFixed(9)}</b></p>
                {/* <p className="sub-label-title middle">NSA remaining in pre-sale:<br/><b>{remaining}</b></p> */}
                <p className="sub-label-title last">NSA currently distributed is:<br/><b>{totalNSASent}</b></p>
                {/* <p className="sub-label-title last">NSAN currently distributed is:<br/><b>{totalNSANSent}</b></p> */}
                
                <IonItem>
                  <IonLabel position="floating">SOL Amount:</IonLabel>
                  <IonInput type="number" placeholder="Enter the amount..." value={amount} onIonChange={e => setAmount(e.detail.value!)}></IonInput>
                </IonItem>

                <IonButton className="amount-button" color="secondary" onClick={() => getAmount('max')}>Maximum</IonButton>
                <IonButton className="amount-button" color="secondary" onClick={() => getAmount('half')}>Half</IonButton>
                <IonButton className="amount-button" color="secondary" onClick={() => getAmount('quarter')}>Quarter</IonButton>

                <IonButton className="transaction" color="secondary" onClick={() => buyTokens()}><IonIcon icon={wallet} style={{marginRight: '5px'}}/> Get tokens</IonButton>
              </div>
            }

            { showLabelForm &&
              <div className='buy'>

                <p className="label-title">Please select an action.</p>
              </div>
            }

            { userService.pubkey &&
              <div id="common-history" className="common-history">
                <h1 className="title">Wallet content</h1>
                <IonList>

                  {walletContent.map((item, i) => (
                    <IonItem key={'history' + i}>
                      <IonLabel>
                        <IonGrid>
                          <IonRow class="ion-justify-content-between">
                            <IonCol>
                              <IonIcon color={'primary'} className="icon-icon-list" icon={item.icon} /><span className="tiny-link" onClick={() => { window.open(item.link); }}>{item.devise}</span>
                            </IonCol>
                            <IonCol class="align-right">
                              <span>{item.value}</span>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonLabel>
                    </IonItem>
                  ))}

                </IonList>
              </div>
              }

          </div>
        </div>

      </IonContent>
    </IonPage>
  );
};

export default Wallet;

export type WalletContent = {
  devise: string;
  value: string;
  link: string;
  icon: string;
};